import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from 'components/DesignSystem/InputField';
import DateInput from 'components/DateInput';
import FileUpload from 'components/fileUpload';
import { ContainedButton1, TextedButton1 } from '../../../components/DesignSystem/Button';

const ExistingWill = ({ data }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const initialValues = {
        signing_date: null,
        registrar_place: '',
        registrar_number: '',
        serial_number: '',
        file: null
    };

    const validationSchema = Yup.object().shape({
        signing_date: Yup.date()
            .required('Signing Date is required')
            .nullable()
            .typeError('Invalid date format'),
        registrar_place: Yup.string()
            .required('Sub-Registrar\'s Office Place is required')
            .max(100, 'Maximum 100 characters allowed'),
        registrar_number: Yup.string()
            .required('Sub-Registrar\'s Office Number is required')
            .max(100, 'Maximum 100 characters allowed'),
        serial_number: Yup.string()
            .required('Serial Number is required')
            .max(100, 'Maximum 100 characters allowed'),
        file: Yup.mixed()
            .required('A file is required')
            .test('fileSize', 'File Size is too large', value => value && value.size <= 10485760) // 10MB
            .test('fileType', 'Unsupported File Format', value => value && ['application/pdf'].includes(value.type))
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            console.log("Form Values", values);
        }
    });

    return (
        <>
            <Box
                sx={{
                    p: 2, // Padding inside the Box
                    backgroundColor: '#F5F5F5',
                    borderRadius: '15px',
                    marginTop: '96px',
                    marginRight: '94px'
                }}
            >
                <Box mt='8px'>
                    <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom >
                        Existing Will
                    </Typography>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <Box
                        container
                        sx={{
                            display: 'flex',
                            mt: '21px',
                            mr: '58px',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Grid container spacing={3.5}>
                            <Grid item xs={12} md={7.8} sm={12}>
                                <DateInput
                                    name="signing_date"
                                    value={formik.values.signing_date}
                                    label=" Signing Date"
                                    onChange={value => formik.setFieldValue('signing_date', value)}
                                    error={formik.errors.signing_date}
                                    touched={formik.touched.signing_date}
                                    sx={{
                                        height: '2.5rem',
                                        backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                    }}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <InputField
                                    fullWidth
                                    name="registrar_place"
                                    placeholder="Sub-Registrar's Office Place*"
                                    variant="outlined"
                                    value={formik.values.registrar_place}
                                    onChange={formik.handleChange}
                                    error={formik.errors.registrar_place}
                                    helperText={formik.errors.registrar_place && formik.touched.registrar_place ? formik.errors.registrar_place : null}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <InputField
                                    fullWidth
                                    name="registrar_number"
                                    placeholder="Sub-Registrar's Office Number*"
                                    variant="outlined"
                                    value={formik.values.registrar_number}
                                    onChange={formik.handleChange}
                                    error={formik.errors.registrar_number}
                                    helperText={formik.errors.registrar_number && formik.touched.registrar_number ? formik.errors.registrar_number : null}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputField
                                    fullWidth
                                    name="serial_number"
                                    placeholder="Serial Number*"
                                    variant="outlined"
                                    value={formik.values.serial_number}
                                    onChange={formik.handleChange}
                                    error={formik.errors.serial_number}
                                    helperText={formik.errors.serial_number && formik.touched.serial_number ? formik.errors.serial_number : null}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FileUpload
                                    name="file"
                                    label="Upload File"
                                    accept="application/pdf"
                                    maxFileSize={10485760} // 10MB
                                    value={formik.values.file}
                                    onChange={(file) => formik.setFieldValue("file", file)}
                                    error={formik.errors.file && formik.touched.file ? formik.errors.file : null}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Box>
            <Box sx={{
                display: 'flex', justifyContent: 'flex-end', gap: '10px', mt: '16px', mr: '94px'
            }}>
                <TextedButton1
                    // type="submit"
                    disabled={formik.isSubmitting}
                    sx={{ height: '40px', width: '109px' }}
                >
                    <Typography variant='bodyText3'>
                        Cancel
                    </Typography>
                </TextedButton1>

                <ContainedButton1
                    // type="submit"
                    disabled={formik.isSubmitting}
                    sx={{ height: '40px', width: '109px' }}
                >
                    <Typography variant='bodyText3'>
                        Save
                    </Typography>
                </ContainedButton1>
            </Box>
        </>
    );
};

export default ExistingWill;
