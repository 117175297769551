import ReCAPTCHA from 'react-google-recaptcha';
import './reCaptcha.scss';

const ReCaptcha = ({ onChange, sitekey = '6LfYHrYoAAAAABadQVuKliDO4491RrZ7ThRkEAL6', ...containerProps }) => {
    return (
        <div className="loginFlow-recaptcha-container" {...containerProps}>
            <ReCAPTCHA sitekey={sitekey} onChange={onChange} className='g-recaptcha' />
        </div>
    );
};

export default ReCaptcha;
