import { Box, Grid } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';

const LogoContainer = ({ children }) => {
    return (
        <Grid container style={{ height: '100vh' }}>
            {/* Left Section with Logo */}
            <Grid
                item
                // xs={12}
                md={2}
            >
                <Box sx={{ marginTop: '60px', marginLeft: '75px', padding: 0}}>
                    <Icon name='will-logo.png' width={169} height={90}/>
                </Box>
            </Grid>

            {/* Right Section with Content */}

                    {children}
        </Grid>
    );
};

export default LogoContainer;