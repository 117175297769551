import { Box, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import { ReactComponent as HelpIcon } from 'assets/help-button.svg';
import DateInput from 'components/DateInput';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import { Dropdown } from 'components/DesignSystem/DropDown';
import InputField from 'components/DesignSystem/InputField';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import HelpSection from 'components/featureComponent/helpResourceSection/HelpResourceSection';
import Logo from 'components/logo';
import PhoneField from 'components/PhoneField';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateProfileApi } from 'services/profileService';
import formValidation from 'utils/helper/formValidations';
import * as Yup from 'yup';

const PersonalDetailsPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userProfile, setUserProfile] = useState(null);
    const { fetchUser } = useContext(ContextProvider);

    const handleClose = () => setOpen(false);
    const showSwalAlert = useSwalAlert();

    const initialValues = {
        first_name: '',
        middle_name: '',
        last_name: '',
        dob: '',
        marital_status: '',
        phone: '',
        pan: ''
    }

    const validationSchema = Yup.object().shape({
        first_name: formValidation.firstNameValidation,
        middle_name: formValidation.middleNameValidation,
        last_name: formValidation.lastNameValidation,
        dob: formValidation.testatorDob,
        marital_status: Yup.string()
            .required('required field'),
        phone: formValidation.phoneValidation,
        pan: formValidation.panValidation
    });


    useEffect(() => {
        const getUserData = async () => {
            try {
                const { data } = await fetchUser();
                setUserProfile(data.user.profile); // Assuming data has a `profile` field
                const profile = data.user?.profile;
                formik.setValues({
                    first_name: profile.first_name || '',
                    middle_name: profile.middle_name || '',
                    last_name: profile.last_name || '',
                    dob: profile.dob || '',
                    marital_status: profile.marital_status || '',
                    phone: profile.phone || '',
                    pan: profile.pan || ''
                });

                setLoading(false);
            } catch (error) {
                console.error('Error fetching user:', error);
                setLoading(false);
            }
        };
        getUserData();
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const profileData = { ...userProfile, ...values };
            try {
                // Call the API to update the profile
                await updateProfileApi({ profile: profileData });

                // Show SweetAlert2 notification and wait for it to close
                await showSwalAlert({
                    title: "Personal Details updated successfully",
                    icon: "success",
                    timer: 1500
                });

                // Navigate to the next page
                navigate('/addressDetails');
            } catch (error) {
                // Show SweetAlert2 notification and wait for it to close
                showSwalAlert({
                    title: "Failed to update Personal Details",
                    icon: "error",
                    timer: 1500
                });
                console.error('Error updating profile:', error);
            }
        }
    });


    const menuItems = [
        {
            value: "single",
            label: "Single"
        },
        {
            value: "married",
            label: "Married"
        },
        {
            value: "divorced",
            label: "Divorced"
        },
        {
            value: "widowed",
            label: "Widowed"
        }
    ]
    return (
        <>
            <Box sx={{
                position: 'absolute', top: "2.8rem", left: "2rem", width: '16rem', height: '8rem',
                '@media (max-width: 600px)': {
                    top: '1rem',
                }, '@media (max-width: 1270px)': {
                    top: '1rem',
                    left: '5rem'
                },
            }}>
                <Logo />
            </Box>
            <form noValidate onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    sx={{
                        // maxWidth: 800,
                        height: '100%',
                        margin: 'auto',
                        padding: 16,
                        paddingTop: 0,
                        paddingLeft: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Grid item sx={{ maxWidth: 800, marginTop: '8.438rem' }}>
                        <Typography variant="title1" gutterBottom>
                            Personal Details
                        </Typography>
                        <Stack spacing={0.5} mt={2}>
                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[400] }}>
                                First, let's create your profile.
                            </Typography>
                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[400] }}>
                                Please fill them in accurately, as these details cannot be changed once they are saved.
                            </Typography>
                        </Stack>
                        <Grid item sx={{ maxWidth: 715 }}>
                            <Grid container spacing={2.5} mt={1}>
                                <Grid item xs={12} sm={4}>
                                    <InputField
                                        fullWidth
                                        name="first_name"
                                        placeholder="First Name*"
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                        helperText={formik.touched.first_name && formik.errors.first_name}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <InputField
                                        fullWidth
                                        name="middle_name"
                                        placeholder="Middle Name"
                                        variant="outlined"
                                        value={formik.values.middle_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.middle_name && Boolean(formik.errors.middle_name)}
                                        helperText={formik.touched.middle_name && formik.errors.middle_name}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <div style={{ position: 'relative' }}>
                                        <InputField
                                            fullWidth
                                            name="last_name"
                                            placeholder="Last Name*"
                                            variant="outlined"
                                            value={formik.values.last_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                            helperText={formik.touched.last_name && formik.errors.last_name}
                                        />
                                        <span
                                            style={{
                                                content: '"as per PAN"',
                                                position: 'absolute',
                                                top: '100%',
                                                right: 0,
                                                fontSize: '12px',
                                                color: '#707070',
                                                marginTop: '2px',
                                                display: 'block',
                                                fontStyle: 'italic', // Making the text italic
                                                fontFamily: "'DM Sans', sans-serif", // Applying DM Sans font
                                            }}
                                        >
                                            as per PAN
                                        </span>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6.5} sm={12}>
                                    <DateInput
                                        name="dob"
                                        value={formik.values.dob}
                                        label='Date of Birth'
                                        error={formik.touched.dob && Boolean(formik.errors.dob)}
                                        helperText={formik.touched.dob && formik.errors.dob}
                                        onChange={(date) => formik.setFieldValue('dob', date)}
                                        onBlur={formik.handleBlur}
                                    />
                                </Grid>


                                <Grid item xs={12} md={12}>
                                    <Grid item xs={12} sm={6.37}>
                                        <Dropdown
                                            name="marital_status"
                                            value={formik.values.marital_status}
                                            options={menuItems}
                                            placeholder="Marital Status*"
                                            handleChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.marital_status && Boolean(formik.errors.marital_status)}
                                            helperText={formik.touched.marital_status && formik.errors.marital_status}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6.5}>
                                    <Grid container>
                                        <Grid item md={12} xs={12}>
                                            <PhoneField
                                                name="phone"
                                                value={formik.values.phone}
                                                handleChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                                helperText={formik.touched.phone && formik.errors.phone}
                                                placeholder="Mobile Number*"
                                                disabled={false}
                                            />

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={5.5}>
                                    <InputField
                                        fullWidth
                                        name="pan"
                                        placeholder="PAN no.*"
                                        variant="outlined"
                                        value={formik.values.pan}
                                        onChange={(e) => {
                                            // Convert PAN value to uppercase
                                            formik.setFieldValue('pan', e.target.value.toUpperCase());
                                        }}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.pan && Boolean(formik.errors.pan)}
                                        helperText={formik.touched.pan && formik.errors.pan}
                                    />
                                </Grid>
                            </Grid>
                            <ContainedButton1
                                type="submit"
                                onClick={(e) => console.log("Values", formik.values)}
                                disabled={formik.isSubmitting || loading}
                                sx={{ mt: '50px', minWidth: '125px' }}
                            >
                                <Typography variant='bodyText3'>
                                    Next
                                </Typography>
                            </ContainedButton1>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: '3.125rem',
                    right: '3.125rem',
                    bgcolor: '#FFF3E0',
                    color: '#FF8C42',
                    '&:hover': {
                        bgcolor: '#FFE0B2',
                    },
                }}
                onClick={() => setOpen(true)}
            >
                <HelpIcon />
            </IconButton>
            <HelpSection
                open={open}
                handleClose={handleClose}
            />
        </>
    )
}
export default PersonalDetailsPage;