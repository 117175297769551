
export const assetTypes = {
    real_estate: [
        { value: 'residential', label: 'Residential' },
        { value: 'commercial', label: 'Commercial' },
        { value: 'plot', label: 'Plot' }
    ],
    financial: [
        { value: 'bank_account', label: 'Bank Accounts' },
        { value: 'listed_securities', label: 'Listed Securities' },
        { value: 'government_investment', label: 'Government Investment' },
        { value: 'unlisted_securities', label: 'Unlisted Securities' }
    ],
    personal_possessions: [
        { value: 'ornaments', label: 'Ornaments' },
        { value: 'vehicles', label: 'Vehicles' },
        { value: 'pets', label: 'Pets' },
        { value: 'art', label: 'Art' }
    ],
    other_assets: [
        { value: 'post_retiral', label: 'Post Retiral' },
        { value: 'insurances', label: 'Insurances' },
        { value: 'digital_assets', label: 'Digital Assets' },
        { value: 'intellectual_property_rights', label: 'Intellectual Property Rights' },
        { value: 'other_assets', label: 'Other Assets' }
    ]
};
