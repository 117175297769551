// ResourcesSection.js
import React from 'react';
import { Typography, Grid, useTheme, Paper } from '@mui/material';
import { ContainedButton2 } from 'components/DesignSystem/Button';

const ResourcesSection = () => {
    const theme = useTheme();

    // Video data as an object
    const videos = {
        "app": {
            url: "https://www.youtube.com/embed/RhXl2fNhkjk?rel=0",
            title: "GetWillDone Introduction Video"
        },
        "assets": {
            url: "https://www.youtube.com/embed/bX-NIh3XW-A?si=blvUAHOn7N1AKUCM",
            title: "Who can be a beneficiary?"
        },
        "beneficiaries": {
            url: "https://www.youtube.com/embed/s4OMayI0H_4?si=w2AzRxrFpBVw9uw7",
            title: "What is a Beneficiary?"
        },
        "executors": {
            url: "https://www.youtube.com/embed/_n9984AxCZ0?rel=0",
            title: "Who is an Executor of a Will?"
        },
        "witnesses": {
            url: "https://www.youtube.com/embed/SUE-XG-NBtg?rel=0",
            title: "Who can be a witness to your Will?"
        },
        "distribution": {
            url: "https://www.youtube.com/embed/T_mauuyt5A0?si=u3N_6WkTIQcWWVvk",
            title: "What happens in absence of a Will?"
        },
        "preview": {
            url: "https://www.youtube.com/embed/XZlLsQsYDuA?rel=0",
            title: "What is a Will?"
        }
    };

    return (
        <>
            <Grid item xs={6} md={12} xl={12} sx={{ mr: '294px', mt: '15px', overflowX: 'auto', '::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none' }}>

                <Grid container spacing={3} sx={{ display: 'flex', flexDirection: 'column', minWidth: 'max-content', borderRadius: '15px', overflowX: 'auto' }}>
                    {Object.values(videos).map((video, index) => (
                        <Grid item key={index}>
                            <Paper
                                sx={{
                                    p: '11px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent',
                                }}
                            >

                                <iframe
                                    width="100%"
                                    height="239px"
                                    src={video.url}
                                    title={video.title}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{ borderRadius: '10px' }} // Apply border radius to iframe
                                />

                                <Typography mt='8px' variant="bodyText4" sx={{ textAlign: 'left', width: '100%' }} color={theme.palette.grey[600]}>
                                    {video.title}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

export default ResourcesSection;



