
import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import useResponsive from "../hooks/useResponsive";
import LogoContainer from "../components/commonComponents/LogoContainer";
import PasswordSuccessForm  from "../components/featureComponent/loginFlow/PasswordSuccessForm";


// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    width:"100%"
  }
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function PasswordSuccessPage() {
  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    sessionStorage.removeItem('video_close');
  }, [])

  return (
    <>
      <Helmet>
        <title> Sign In | Get Will Done </title>
      </Helmet>

      <StyledRoot>
              <PasswordSuccessForm />

      </StyledRoot>
    </>
  );
}
