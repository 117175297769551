import { Box, Dialog, DialogContent, Typography, useTheme } from '@mui/material';
import { noop } from 'lodash';
import { ContainedButton1, TextedButton1 } from '../Button';

const AlertDialog = ({
    open,
    onClose,
    title = 'Are you sure you want to delete this Person?',
    primaryButtonLabel = 'Keep',
    secondaryButtonLabel = 'Delete',
    onPrimaryButtonClick = noop,
    onSecondaryButtonClick = noop,
}) => {
    const theme = useTheme();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: '35px',
                },
            }}
        >
            <DialogContent sx={{ border: `1px solid red`, borderRadius: '35px', padding: '32px 40px', textAlign: 'center' }}>
                <Box sx={{ marginBottom: '24px' }}>
                    <Typography variant="heading2" >
                        {title}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
                    <ContainedButton1
                        sx={{
                            background: theme.palette.error.main,
                            width: '160px',
                            height: '45px',
                            '&:hover': {
                                backgroundColor: `${theme.palette.error.main}`, 
                                boxShadow: '0px 6px 6px -4px rgba(244, 123, 32, 0.3)',
                            },
                        }}
                        onClick={onPrimaryButtonClick}
                    >
                        <Typography variant='bodyText3' color={theme.palette.common.white}>
                            {primaryButtonLabel}
                        </Typography>
                    </ContainedButton1>
                    <TextedButton1 onClick={onSecondaryButtonClick} sx={{ width: '160px', height: '45px' }}>
                        <Typography variant='bodyText3' color={theme.palette.error.main}>
                            {secondaryButtonLabel}
                        </Typography>
                    </TextedButton1>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AlertDialog;
