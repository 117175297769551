import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import { ReactComponent as HelpIcon } from 'assets/help-button.svg';
import { CustomAutocomplete, CustomAutocompleteFreeSolo } from 'components/DesignSystem/AutoComplete/CustomAutocomplete';
import RadioField from 'components/DesignSystem/RadioGroup/RadioField';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import HelpSection from 'components/featureComponent/helpResourceSection/HelpResourceSection';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDataFromZip, updateProfileApi } from 'services/profileService';
import { CountryList } from 'utils/helper/CountryList';
import { handleCountryCodeChange } from 'utils/helper/FormHelperFunctions';
import formValidation from 'utils/helper/formValidations';
import * as Yup from 'yup';
import { ContainedButton1 } from '../../components/DesignSystem/Button';
import InputField from '../../components/DesignSystem/InputField';
import Logo from '../../components/logo/Logo';

let timeoutId;

const AddressDetailsPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userProfile, setUserProfile] = useState(null);
    const [areaOptions, setAreaOptions] = useState([]);
    const [areaOptionsPermanent, setAreaOptionsPermanent] = useState([]);
    const handleClose = () => setOpen(false);
    const showSwalAlert = useSwalAlert();
    const { fetchUser } = useContext(ContextProvider);

    const initialValues = {
        current: {
            house_no: '',
            house_name: '',
            area: '',
            country: '',
            countryCode: '',
            state: '',
            city: '',
            zip: ''
        },
        permanent: {
            house_no: '',
            house_name: '',
            area: '',
            country: '',
            countryCode: '',
            state: '',
            city: '',
            zip: ''
        },
        same_as_current: ''
    };

    const validationSchema = Yup.object().shape({
        current: Yup.object().shape({
            house_no: formValidation.houseNoValidation,
            house_name: formValidation.houseNameValidation,
            area: formValidation.areaValidation,
            countryCode: formValidation.countryValidation,
            state: formValidation.stateValidation,
            city: formValidation.cityValidation,
            zip: formValidation.zipValidation,
        }),
        permanent: Yup.object().shape({
            house_no: formValidation.houseNoValidation,
            house_name: formValidation.houseNameValidation,
            area: formValidation.areaValidation,
            countryCode: formValidation.countryValidation,
            state: formValidation.stateValidation,
            city: formValidation.cityValidation,
            zip: formValidation.zipValidation,
        }),
        same_as_current: formValidation.same_as_currentValidation
    });

    useEffect(() => {
        const getUserData = async () => {
            try {
                const { data } = await fetchUser();
                setUserProfile(data.user.profile);
                const currentAddress = data.user.profile?.current
                const permanentAddress = data.user.profile?.permanent
                formik.setValues({
                    current: {
                        house_no: currentAddress?.house_no || '',
                        house_name: currentAddress?.house_name || '',
                        area: currentAddress?.area || '',
                        country: currentAddress?.country || '',
                        countryCode: currentAddress?.countryCode || '',
                        state: currentAddress?.state || '',
                        city: currentAddress?.city || '',
                        zip: currentAddress?.zip || ''
                    },
                    permanent: {
                        house_no: permanentAddress?.house_no || '',
                        house_name: permanentAddress?.house_name || '',
                        area: permanentAddress?.area || '',
                        countryCode: permanentAddress?.countryCode || '',
                        state: permanentAddress?.state || '',
                        city: permanentAddress?.city || '',
                        zip: permanentAddress?.zip || ''
                    },
                    same_as_current: data.user.profile.same_as_current
                });

                setLoading(false);
            } catch (error) {
                console.error('Error fetching user:', error);
                setLoading(false);
            }
        };
        getUserData();
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            console.log("Values", values)
            // Use userProfile to build the complete profile object
            const profileData = { ...userProfile, ...values };
            try {
                await updateProfileApi({ profile: profileData })

                // Show SweetAlert2 notification and wait for it to close
                await showSwalAlert({
                    title: "Address Details updated successfully",
                    icon: "success",
                    timer: 1500
                });

                navigate('/existingDetails')

            } catch (error) {
                showSwalAlert({
                    title: "Failed to update Address Details",
                    icon: "error",
                    timer: 1500
                });
                console.error('Error updating profile:', error);
            };
        }
    });

    const radioOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];

    useEffect(() => {
        if (formik.values.same_as_current) {
            formik.setFieldValue('permanent', { ...formik.values.current });
        }
    }, [formik.values.current, formik.values.same_as_current]);



    const handleSameAsCurrentChange = (event) => {
        const isChecked = event.target.value === 'true';
        formik.setFieldValue('same_as_current', isChecked);

        if (isChecked) {
            formik.setFieldValue('permanent', { ...formik.values.current });
        } else {
            formik.setFieldValue('permanent', {
                house_no: '',
                house_name: '',
                area: '',
                country: '',
                countryCode: '',
                state: '',
                city: '',
                zip: ''
            });
        }
    };

    const handleZipChange = (fieldPrefix, zip, countryCode, isPermanent = false) => {
        if (zip?.length >= 4 && zip?.length <= 11) {
            if (timeoutId) clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                fetchDataFromZip({ zip, countryCode })
                    .then(({ city, state, placeNames }) => {
                        formik.setFieldValue(`${fieldPrefix}.city`, city);
                        formik.setFieldValue(`${fieldPrefix}.state`, state);

                        // Set area options based on whether it's current or permanent
                        if (isPermanent) {
                            setAreaOptionsPermanent(placeNames);
                        } else {
                            setAreaOptions(placeNames);
                        }
                    })
                    .catch((error) => {
                        formik.setFieldValue(`${fieldPrefix}.city`, '');
                        formik.setFieldValue(`${fieldPrefix}.state`, '');

                        // Clear area options based on whether it's current or permanent
                        if (isPermanent) {
                            setAreaOptionsPermanent([]);
                        } else {
                            setAreaOptions([]);
                        }
                    });
            }, 1000);
        }
    };

    return (
        <>
            <Box sx={{
                position: 'absolute', top: "2.8rem", left: "2rem", width: '16rem', height: '8rem',
                '@media (max-width: 600px)': {
                    top: '1rem',
                }, '@media (max-width: 1270px)': {
                    top: '1rem',
                    left: '5rem'
                },
            }}>
                <Logo />
            </Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    sx={{
                        maxWidth: 800,
                        height: '100%',
                        margin: 'auto',
                        // marginLeft:14,
                        padding: 8,
                        paddingTop: 0,
                        paddingLeft: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Box sx={{ maxWidth: 700, marginTop: '8.438rem' }}>
                        <Typography variant="title1" gutterBottom>
                            Where do you live?
                        </Typography>
                        <Typography variant="bodyText3" paragraph mt={1.5}>
                            Now, let's add your address, Aditya.
                        </Typography>

                        {/* Current Address Section */}
                        <Typography variant="heading2" paragraph mt={4} sx={{ color: theme.palette.secondary.main }}>
                            Current Address
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <InputField
                                    fullWidth
                                    name="current.house_no"
                                    placeholder="House/Flat No."
                                    value={formik.values.current.house_no}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.house_no && Boolean(formik.errors.current?.house_no)}
                                    helperText={formik.touched.current?.house_no && formik.errors.current?.house_no}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputField
                                    fullWidth
                                    name="current.house_name"
                                    placeholder="Building, Company, Apartment*"
                                    value={formik.values.current.house_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.house_name && Boolean(formik.errors.current?.house_name)}
                                    helperText={formik.touched.current?.house_name && formik.errors.current?.house_name}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <CustomAutocompleteFreeSolo
                                    fullWidth
                                    name="current.area"
                                    placeholder="Area, Street, Sector*"
                                    value={formik.values.current.area}
                                    handleChange={formik.handleChange}
                                    onInputChange={formik.handleChange}
                                    options={areaOptions}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.area && Boolean(formik.errors.current?.area)}
                                    helperText={formik.touched.current?.area && formik.errors.current?.area}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomAutocomplete
                                    name="current.countryCode"
                                    value={formik.values.current.countryCode}
                                    options={CountryList}
                                    placeholder="Country*"
                                    handleChange={(event) => handleCountryCodeChange(formik, event, 'current.countryCode')}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.countryCode && Boolean(formik.errors.current?.countryCode)}
                                    helperText={formik.touched.current?.countryCode && formik.errors.current?.countryCode}
                                />

                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputField
                                    fullWidth
                                    name="current.zip"
                                    placeholder="Pincode*"
                                    value={formik.values.current.zip}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        handleZipChange('current', e.target.value, formik.values.current.countryCode);
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.zip && Boolean(formik.errors.current?.zip)}
                                    helperText={formik.touched.current?.zip && formik.errors.current?.zip}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputField
                                    fullWidth
                                    name="current.state"
                                    placeholder="State*"
                                    value={formik.values.current.state}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.state && Boolean(formik.errors.current?.state)}
                                    helperText={formik.touched.current?.state && formik.errors.current?.state}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputField
                                    fullWidth
                                    name="current.city"
                                    placeholder="City*"
                                    value={formik.values.current.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.city && Boolean(formik.errors.current?.city)}
                                    helperText={formik.touched.current?.city && formik.errors.current?.city}
                                />
                            </Grid>
                        </Grid>

                        <Grid item md={12} mt={2}>
                            <RadioField
                                label="This is my permanent address :"
                                name="same_as_current"
                                value={formik.values.same_as_current}
                                onChange={handleSameAsCurrentChange}
                                options={radioOptions}
                                onBlur={formik.handleBlur}
                                error={formik.touched.same_as_current && Boolean(formik.errors.same_as_current)}
                                helperText={formik.touched.same_as_current && formik.errors.same_as_current}
                            />

                        </Grid>
                        {/* Permanent Address Section */}
                        {formik.values.same_as_current === false && (
                            <>
                                <Grid mt={2}>
                                    <Typography variant="heading2" sx={{ color: theme.palette.secondary.main }} >
                                        Permanent Address
                                    </Typography>
                                </Grid>
                                <Grid container spacing={2} mt={0.5} >
                                    <Grid item xs={12} md={6}>
                                        <InputField
                                            fullWidth
                                            name="permanent.house_no"
                                            placeholder="House/Flat No."
                                            value={formik.values.permanent.house_no}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.house_no && Boolean(formik.errors.permanent?.house_no)}
                                            helperText={formik.touched.permanent?.house_no && formik.errors.permanent?.house_no}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputField
                                            fullWidth
                                            name="permanent.house_name"
                                            placeholder="Building, Company, Apartment*"
                                            value={formik.values.permanent.house_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.house_name && Boolean(formik.errors.permanent?.house_name)}
                                            helperText={formik.touched.permanent?.house_name && formik.errors.permanent?.house_name}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <CustomAutocompleteFreeSolo
                                            fullWidth
                                            name="permanent.area"
                                            placeholder="Area, Street, Sector*"
                                            value={formik.values.permanent.area}
                                            handleChange={formik.handleChange}
                                            onInputChange={formik.handleChange}
                                            options={areaOptionsPermanent}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.area && Boolean(formik.errors.permanent?.area)}
                                            helperText={formik.touched.permanent?.area && formik.errors.permanent?.area}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomAutocomplete
                                            name="permanent.countryCode"
                                            value={formik.values.permanent.countryCode}
                                            options={CountryList}
                                            placeholder="Country*"
                                            handleChange={(event) => handleCountryCodeChange(formik, event, 'permanent.countryCode')}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.countryCode && Boolean(formik.errors.permanent?.countryCode)}
                                            helperText={formik.touched.permanent?.countryCode && formik.errors.permanent?.countryCode}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputField
                                            fullWidth
                                            name="permanent.zip"
                                            placeholder="Pincode*"
                                            value={formik.values.permanent.zip}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleZipChange('permanent', e.target.value, formik.values.permanent.countryCode, true);
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.zip && Boolean(formik.errors.permanent?.zip)}
                                            helperText={formik.touched.permanent?.zip && formik.errors.permanent?.zip}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputField
                                            fullWidth
                                            name="permanent.state"
                                            placeholder="State*"
                                            value={formik.values.permanent.state}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.state && Boolean(formik.errors.permanent?.state)}
                                            helperText={formik.touched.permanent?.state && formik.errors.permanent?.state}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputField
                                            fullWidth
                                            name="permanent.city"
                                            placeholder="City*"
                                            value={formik.values.permanent.city}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.city && Boolean(formik.errors.permanent?.city)}
                                            helperText={formik.touched.permanent?.city && formik.errors.permanent?.city}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        <ContainedButton1
                            type="submit"
                            disabled={formik.isSubmitting || loading}
                            onClick={(e) => console.log("Values", formik.values)}
                            sx={{
                                marginTop: 4,
                                width: '125px',
                                height: '45px',
                            }}
                        >
                            <Typography variant='bodyText3'>
                                Next
                            </Typography>
                        </ContainedButton1>
                    </Box>
                </Grid>
            </form>
            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: '3.125rem',
                    right: '3.125rem',
                    bgcolor: '#FFF3E0',
                    color: '#FF8C42',
                    '&:hover': {
                        bgcolor: '#FFE0B2',
                    },
                }}
                onClick={() => setOpen(true)}
            >
                <HelpIcon />
            </IconButton>
            <HelpSection
                open={open}
                handleClose={handleClose}
            />
        </>
    );
}

export default AddressDetailsPage;
