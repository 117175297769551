import ReactDOM from 'react-dom/client';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import axios from "axios";
// third party
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/en'; // Import the desired locale
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import reducer from './store/reducer';
import 'assets/scss/style.scss';

// ----------------------------------------------------------------------
// dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
// dayjs.tz.setDefault(dayjs.tz.guess());
dayjs.tz.setDefault('Asia/Kolkata');

axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureStore({ reducer });

root.render(
    <Provider store={store}>
        <HelmetProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
        </HelmetProvider>
    </Provider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
