import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, FormControlLabel, Radio, Typography, useTheme, Button, FormControl, FormLabel, RadioGroup, Divider } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RadioField from 'components/DesignSystem/RadioGroup/RadioField';
import InputField from 'components/DesignSystem/InputField';
import { Dropdown } from 'components/DesignSystem/DropDown';
import Icon from 'components/DesignSystem/Icon';
import { ContainedButton1, TextedButton1 } from '../../../components/DesignSystem/Button';

const AddressDetails = ({ data }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const initialValues = {
        current: {
            house_no: '',
            house_name: '',
            area: '',
            country: '',
            state: '',
            city: '',
            zip: ''
        },
        permanent: {
            house_no: '',
            house_name: '',
            area: '',
            country: '',
            state: '',
            city: '',
            zip: ''
        },
        same_as_current: false
    };

    const validationSchema = Yup.object().shape({
        current: Yup.object().shape({
            house_no: Yup.string().required('House No. is required'),
            house_name: Yup.string().required('House Name is required'),
            area: Yup.string().required('Area is required'),
            country: Yup.string().required('Country is required'),
            state: Yup.string().required('State is required'),
            city: Yup.string().required('City is required'),
            zip: Yup.string().required('Zip is required'),
        }),
        permanent: Yup.object().shape({
            house_no: Yup.string().required('House No. is required'),
            house_name: Yup.string().required('House Name is required'),
            area: Yup.string().required('Area is required'),
            country: Yup.string().required('Country is required'),
            state: Yup.string().required('State is required'),
            city: Yup.string().required('City is required'),
            zip: Yup.string().required('Zip is required'),
        }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            console.log("Address form values", values);
        }
    });

    const menuItems = [
        {
            value: "india",
            label: "India"
        }
    ]

    const radioOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];

    return (
        <>
            <Box
                sx={{
                    p: 2, // Padding inside the Box
                    backgroundColor: '#F5F5F5',
                    borderRadius: '15px',
                    marginTop: '96px',
                    marginRight: '94px'
                }}
            >
                <Box display='flex' alignItems='center' justifyContent='space-between' mt='8px'>
                    <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom>
                        Current Address
                    </Typography>
                    <Button
                        variant="outlined"
                        endIcon={<Icon name='gwd-icons-edit.svg' />}
                        sx={{
                            // width: '67px',
                            // height: '28px',
                            borderRadius: '15px',
                            padding: '3px 15px 5px 15px',
                            border: '0.7px solid #78BEC4',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '6px 6px 8px -8px #00838F4D',
                            color: '#000000',
                            textTransform: 'none',
                            '&:hover': {
                                borderColor: '#78BEC4',
                            },
                        }}
                    >
                        <Typography variant='suggestiveText' sx={{ color: '#00838F', pt: '4px' }}>Edit</Typography>
                    </Button>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <Box
                        container
                        sx={{
                            display: 'flex',
                            mt: '38px',
                            mr: '58px',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <InputField
                                    fullWidth
                                    name="current.house_no"
                                    placeholder="House/Flat No." z
                                    value={formik.values.current.house_no}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputField
                                    fullWidth
                                    name="current.house_name"
                                    placeholder="Building, Company, Apartment*" z
                                    value={formik.values.current.house_name}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <InputField
                                    fullWidth
                                    name="current.area"
                                    placeholder="Area, Street, Sector*" z
                                    value={formik.values.current.area}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Dropdown
                                    name="current.country"
                                    value={formik.values.current.country}
                                    options={menuItems}
                                    placeholder="Country*"
                                    handleChange={formik.handleChange}
                                    dropdownColor={theme.palette.common.white}
                                    dropdownHeight="40px"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputField
                                    fullWidth
                                    name="current.zip"
                                    placeholder="Pincode*" z
                                    value={formik.values.current.zip}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputField
                                    fullWidth
                                    name="current.state"
                                    placeholder="State*" z
                                    value={formik.values.current.state}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputField
                                    fullWidth
                                    name="current.city"
                                    placeholder="City*" z
                                    value={formik.values.current.city}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container display='flex' justifyContent='left' md={12} mt={2}>
                            <RadioField
                                label="This is my permanent address :"
                                name="same_as_current"
                                value={formik.values.same_as_current}
                                onChange={(e) => formik.setFieldValue('same_as_current', JSON.parse(e.target.value))}
                                options={radioOptions}
                            />
                        </Grid>

                        {/* Permanent Address Section */}
                        {!formik.values.same_as_current && (
                            <>
                                <Divider sx={{ borderStyle: 'dashed', width: '100%', height: '1px', border: '0.0625rem dashed #C5C5C5', mt: '25px' }} />

                                <Grid container display='flex' justifyContent='left' mt='20px'>
                                    <Typography variant="heading2" sx={{ color: theme.palette.secondary.main }} >
                                        Permanent Address
                                    </Typography>
                                </Grid>
                                <Grid container spacing={2} mt={0.5} >
                                    <Grid item xs={12} md={6}>
                                        <InputField
                                            fullWidth
                                            name="permanent.house_no"
                                            placeholder="House/Flat No."
                                            value={formik.values.permanent.house_no}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputField
                                            fullWidth
                                            name="permanent.house_name"
                                            placeholder="Building, Company, Apartment*"
                                            value={formik.values.permanent.house_name}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <InputField
                                            fullWidth
                                            name="permanent.area"
                                            placeholder="Area, Street, Sector*"
                                            value={formik.values.permanent.area}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Dropdown
                                            name="permanent.country"
                                            value={formik.values.permanent.country}
                                            options={menuItems}
                                            placeholder="Country*"
                                            handleChange={formik.handleChange}
                                            dropdownColor={theme.palette.common.white}
                                            dropdownHeight="40px"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputField
                                            fullWidth
                                            name="permanent.zip"
                                            placeholder="Pincode"
                                            value={formik.values.permanent.zip}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputField
                                            fullWidth
                                            name="permanent.state"
                                            placeholder="State*"
                                            value={formik.values.permanent.state}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputField
                                            fullWidth
                                            name="permanent.city"
                                            placeholder="City*"
                                            value={formik.values.permanent.city}
                                            onChange={formik.handleChange}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Box>
                </form>
            </Box>
            <Box sx={{
                display: 'flex', justifyContent: 'flex-end', gap: '10px', mt: '16px', mr: '94px'
            }}>
                <TextedButton1
                    // type="submit"
                    disabled={formik.isSubmitting}
                    sx={{ height: '40px', width: '109px' }}
                >
                    <Typography variant='bodyText3'>
                        Cancel
                    </Typography>
                </TextedButton1>

                <ContainedButton1
                    // type="submit"
                    disabled={formik.isSubmitting}
                    sx={{ height: '40px', width: '109px' }}
                >
                    <Typography variant='bodyText3'>
                        Save
                    </Typography>
                </ContainedButton1>
            </Box>
        </>
    );
};

export default AddressDetails;
