import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

const CustomDialog = ({ open, onClose, children }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: '15px',
                    backgroundColor: '#FFFFFF',
                    position: 'absolute',
                    top: '9rem',
                    left: '25rem',
                    right: '6.5625rem',
                    bottom: '3.125rem',
                    boxShadow: 24,
                    margin: '0', // Ensure no default margins
                },
            }}
            maxWidth={false} // Disable default maxWidth
        >
            {children}
        </Dialog>
    );
};

const CustomDialogTitle = ({ children, onClose }) => {
    return (
        <>
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', gap: '4px', paddingBottom: 0 }}>
                {children}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </>
    );
};

const CustomDialogContent = ({ children }) => {
    return (
        <DialogContent sx={{ paddingTop: 0}}>
            {children}
        </DialogContent>
    );
};

const CustomDialogActions = ({ children }) => {
    return (
        <DialogActions sx={{ paddingRight: '25px', paddingBottom: '25px' }}>
            {children}
        </DialogActions>
    );
};

export { CustomDialog, CustomDialogActions, CustomDialogContent, CustomDialogTitle };

