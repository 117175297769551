import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Link, Divider, useTheme } from '@mui/material';
import axios from "axios";
import Swal from "sweetalert2";
import InputField from 'components/DesignSystem/InputField';
import { ContainedButton1 } from "../../DesignSystem/Button";
import { OTPField } from "../../OTPField";
import Iconify from "../../iconify";

const VerifyRegistrationOtpForm = () => {
    const theme = useTheme();
    const { state } = useLocation();
    const navigate = useNavigate();
    const interval = useRef(null);
    const [eotp, setEOTP] = useState("");
    const [partnerCode, setPartnerCode] = useState(null);
    const [seconds, setSeconds] = useState(900);
    const [otpError, setOtpError] = useState("");


    useEffect(() => {
        // Start the timer when the component mounts
        interval.current = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds <= 1) {
                    clearInterval(interval.current);
                    Swal.fire({
                        position: "top-center",
                        icon: "error",
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: `OTPs have expired. Please click resend to generate new OTPs`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);

        // Cleanup on unmount
        return () => clearInterval(interval.current);
    }, []);

    const handleVerifyClick = useCallback(() => {
        console.log({ userType: 'customer', email: state?.email, password: state?.password, eotp, partnerCode });

        axios.post("/auth/register", {
            userType: 'customer', email: state?.email, password: state?.password, eotp, partnerCode
        }).then(() => {
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Registration Complete! Please proceed to create your Will.`,
                showConfirmButton: false,
                timer: 3000
            });
            setTimeout(() => {
                navigate('/welcome', { replace: true });
            }, 3000);
        }).catch((e) => {
            console.error('Registration Error:', e);
            if (e?.response?.status === 400) {


                setOtpError("Invalid OTP");


            } else {
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Registration Failed! Please check the fields and try again`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    }, [eotp, partnerCode, navigate, state]);

    const handleResendOtp = useCallback(() => {
        if (!state?.email) {
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Email address is missing. Please try again.`,
                showConfirmButton: false,
                timer: 3000
            });
            return;
        }

        axios.post("/auth/pre-register", { email: state?.email })
            .then(() => {
                Swal.fire({
                    position: "top-center",
                    icon: "success",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `OTP has been resent to ${state?.email}.`,
                    showConfirmButton: false,
                    timer: 3000
                });
                // Reset OTP input field or any other necessary state here
                setEOTP("");
                // Reset the timer if needed
                setSeconds(900); // Reset the timer to 15 minutes
            })
            .catch((error) => {
                console.error('Error resending OTP:', error);
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Failed to resend OTP. Please try again later.`,
                    showConfirmButton: false,
                    timer: 3000
                });
            });
    }, [state?.email]);


    const timer = useMemo(() => {
        const date = new Date(seconds * 1000);
        const isoDate = date.toISOString();
        return isoDate.substring(14, 19);
    }, [seconds]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'top',
                justifyContent: 'flex-start',
                height: '60vh',
                bgcolor: 'background.paper',
                marginLeft: "104px",
                width: '402px',
            }}
        >
            <Box sx={{ mb: "5px" }}>
                <Typography variant="title1" sx={{ mb: "5px" }} gutterBottom>
                    OTP Verification
                </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
                <Typography variant="bodyText3" color={theme.palette.grey[400]} sx={{ mb: "30px" }}>
                    An OTP has been sent to {state?.email.replace(/(.{2})(.*)(@.*)/, "$1****$3")}
                </Typography>


            </Box>

            <Box sx={{ mb: 0 }}>
                <Box sx={{ mb: "11px" }}>
                    <OTPField
                        inputStyle={{
                            fontSize: theme.typography.fontSize,
                            width: theme.spacing(3.5),
                            padding: theme.spacing(1),
                        }} value={eotp} onChange={setEOTP} numInputs={6} isInputNum isInputSecure />
                    {otpError && (
                        <Box sx={{ textAlign: 'end' }}>
                            <Typography variant="errorText" sx={{ mt: 0.5 }}>
                                {otpError}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    color: seconds <= 0 ? "error.main" : seconds <= 60 ? "warning.main" : theme.palette.secondary.main,
                    mb: '20px'
                }}
            >
                <Box
                    component="img"
                    src="/assets/icons/Stopwatch.svg"
                    alt="Clock Icon"
                    sx={{
                        width: '14.12px',
                        height: '16px',
                        marginRight: theme.spacing(0.5),
                    }}
                />
                {seconds > 0 ? (
                    <Typography
                        variant="suggestiveText"
                        sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            width: '30px',
                            height: '18px',
                        }}
                    >
                        {timer}
                    </Typography>
                ) : (
                    <Typography variant="suggestiveText">
                        OTP Expired! Please click resend to generate new ones
                    </Typography>
                )}
            </Box>

            <Box sx={{ mb: 1 }}>
                <Typography
                    variant="suggestiveText"
                    gutterBottom
                    sx={{
                        color: theme.palette.secondary.main,
                        textDecoration: 'underline',
                        mb: "30px",
                        cursor: 'pointer'
                    }}
                    onClick={handleResendOtp}
                >
                    Resend OTP
                </Typography>
            </Box>

            <ContainedButton1
                sx={{
                    mb: "50px",
                    width: '402px',
                }}
                onClick={handleVerifyClick}
                disabled={!eotp}
            >
                <Typography
                    variant='bodyText3'
                >
                    Verify
                </Typography>
            </ContainedButton1>
            <Divider
                sx={{
                    mb: "15px",
                    width: "402px",
                    borderColor: theme.palette.grey[100]
                }}
            />

            <Typography variant="bodyText4">
                Back to <Link
                    color={theme.palette.secondary.main}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = '/login';
                    }}
                >
                    Log in
                </Link>
            </Typography>
        </Box>
    );
};

export default VerifyRegistrationOtpForm;
