import { Box, Divider, Link, Typography, useTheme } from '@mui/material';
import { useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { ContainedButton1 } from 'components/DesignSystem/Button';
import InputField from 'components/DesignSystem/InputField';
import { useStateWithCallback } from "../../../utils/useStateWithCallback";

const ForgotPasswordForm = () => {
    const interval = useRef(null);
    const [seconds, setSeconds] = useStateWithCallback(900, (sec) => {
        if (sec <= 0) {
            if (interval.ref !== null) clearInterval(interval.ref);
            //   toastr.error("OTPs have expired. Please click resend to generate new OTPs");
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `OTPs have expired. Please click resend to generate new OTPs`,
                showConfirmButton: false,
                timer: 3000
            });
        }
    });

    const theme = useTheme();
    const navigate = useNavigate();
    const [pan, setPan] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [maskedEmail, setMaskedEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [dob, setDob] = useState(null);
    const [step, setStep] = useState(0);
    const [otp, setOTP] = useState("");
    const [isPhone, setIsPhone] = useState(true);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };



    const [emailError, setEmailError] = useState('');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


    const handleRequestOtp = useCallback(() => {
        // const formattedDOB = format(dob, "yyyy-MM-dd");
        axios.post("/auth/forgot-password", {
            //   email, pan, dob: formattedDOB
            email
        }).then((res) => {
            navigate('/verify-otp', { state: { email } });
            setStep(1);
            if (res.data.sendTo === "email") {
                setMaskedEmail(res.data.email);
                setIsPhone(false);
            }
            else {
                setPhone(res.data.phone);
                setIsPhone(true);
            }
            if (interval.ref !== null) clearInterval(interval.ref);
            setSeconds(900);
            interval.ref = setInterval(() => {
                setSeconds(s => s - 1);
            }, 1000);
        }).catch((e) => {
            if (e.response.data.message) {
                // toastr.error(e.response.data.message);
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
            else {
                // toastr.error("Unable to generate OTP! Please check the entered details");
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Unable to generate OTP! Please check the entered details`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    }, [email, pan, dob]);


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'top',
                justifyContent: 'center',
                width: '100%',
                marginLeft: "104px"
            }}
        >

            <Box sx={{ mb: 2 }}>
                <Typography variant="title1" sx={{ mb: "5px" }}>
                    Forgot your password?
                </Typography>
            </Box>



            <Typography
                variant="bodyText3" color={theme.palette.grey[400]}
                gutterBottom
                sx={{ mb: '30px' }}
            >
                Don’t worry, we can reset it easily.
                <Box component="span" sx={{ display: 'block' }}>
                    Please enter your registered email ID below.
                </Box>
            </Typography>

            <InputField
                placeholder="Email"
                sx={{
                    mb: "40px",
                    color: 'black',
                    width: '402px',
                    height: '45px',
                }}
                value={email}
                onChange={(e) => {
                    const value = e.target.value;
                    setEmail(value);

                    // Inline email validation
                    setEmailError(emailRegex.test(value) ? '' : 'Invalid email ID');
                }}
                InputLabelProps={{ shrink: false }} 
                InputProps={{ style: { fontSize: 16 } }} // Optional: Adjust font size
                error={!!emailError} // Set error state
                helperText={emailError} // Show validation message
            />

            <ContainedButton1
                color="primary"
                sx={{
                    mb: "50px",
                    width: '402px',

                }}
                onClick={handleRequestOtp}
                disabled={!email} // Disable button if email is empty
            >
                <Typography
                    variant='bodyText2'
                >
                    Request OTP
                </Typography>
            </ContainedButton1>
            <Divider
                sx={{
                    mt: "15px", // Margin-top
                    mb: 2,     // Margin-bottom
                    width: "402px", // Set the width as a percentage or any specific value
                    borderColor: theme.palette.grey[100] // Use theme.palette.grey[100] correctly
                }}
            />


            <Typography variant="bodyText4">
                Back to <Link
                    color={theme.palette.secondary.main}
                    href="#"
                    onClick={() => {
                        window.location.href = '/login'; // Redirect to the login page
                    }}
                >
                    Log in
                </Link>
            </Typography>

        </Box>
    );
};
export default ForgotPasswordForm;

