import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
// hooks
import { useEffect } from "react";
import useResponsive from "../hooks/useResponsive";
// components
// sections
import LogoContainer from "../components/commonComponents/LogoContainer";
import VerifyOtpForm from "../components/featureComponent/loginFlow/VerifyOtpForm"
import VerifyRegistrationOtpForm from "../components/featureComponent/loginFlow/VerifyRegistrationOtpForm";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    width:"100%"
  }
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyOtpPage() {
  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    sessionStorage.removeItem('video_close');
  }, [])

  return (
    <>
      <Helmet>
        <title> Sign In | Get Will Done </title>
      </Helmet>

      <StyledRoot>
        <LogoContainer>
          <Grid
            item
            // xs={12}
            md={5}
            style={{
              display: 'flex',
              // flexDirection: 'column',
              justifyContent: 'flex-start',
              // alignItems: 'center',
              paddingTop: '135px',
              backgroundColor: '#fff',
            }}
          >
            <Box style={{ width: '100%', maxWidth: '85%' }}>
              <VerifyRegistrationOtpForm />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f9f9f9',
            }}
          >
            <Box
              component="div" // Use a 'div' to apply styles and pseudo-elements
              style={{
                width: '100%',
                height: '100vh',
                position: 'relative', // Make the box relative for positioning the shadow
                overflow: 'hidden', // Hide overflow to make the shadow effect confined within the box
              }}
            >
              <img
                src={'/assets/Forgot_Password.svg'}
                alt="Decorative"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              <Box
                component="div"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  boxShadow: 'inset 12px 0 15px -10px rgba(0, 0, 0, 0.2)', // Adjusted inner shadow for left side
                  pointerEvents: 'none', // Allows interactions with elements behind the shadow layer
                }}
              />
            </Box>
          </Grid>
        </LogoContainer>
      </StyledRoot>
    </>
  );
}

