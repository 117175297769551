import React from 'react';
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { TextedButton1 } from '../../../components/DesignSystem/Button';

const VersionHistory = ({ data }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: 2, // Padding inside the Box
        backgroundColor: '#F5F5F5',
        borderRadius: '15px',
        marginTop: '96px',
        marginRight: '94px',
      }}
    >
      <Box
        sx={{
          mb: 0, // Margin bottom
        }}
      >
        <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom>
          Version History
        </Typography>
      </Box>
      <Table sx={{ width: '100%', borderCollapse: 'collapse', borderSpacing: 0 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="bodyText3">Version</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyText3">Date</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyText3">Status</Typography>
            </TableCell>
            <TableCell>
              {/* <Typography variant="bodyText3">Invoice</Typography> */}
            </TableCell>
          </TableRow>
          {/* Divider Row */}
          <TableRow>
            <TableCell colSpan={4}>
              <Divider
                sx={{
                  my: 0, // Remove vertical margins
                  borderColor: 'white', // Color of the divider
                  borderWidth: '1px', // Thickness of the divider
                  borderStyle: 'solid', // Style of the border
                  margin: 0, // Ensure there's no margin applied
                  padding: 0, // Ensure there's no padding applied
                  height: '1px', // Ensure the height of the divider is just a line
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((version, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>
                  <Typography variant="bodyText3">{version.version}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyText3">{version.date}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyText3">{version.status}</Typography>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      border: (theme) =>
                        index === 0
                          ? 'none'
                          : `1px solid ${theme.palette.primary.light}`, // Conditionally apply border
                      borderRadius: '10px',
                      display: 'inline-block',
                    }}
                  >
                    <TextedButton1
                      variant="contained"
                      // disabled
                      disabled={index === 0} // Disable the first button
                      border='none'
                      sx={{
                        width: '71px',
                        height: '29px',
                        border: 'none',
                        '&.Mui-disabled': {
                          backgroundColor: theme.palette.grey[100], // Disabled background color
                          color: theme.palette.grey[0], // Disabled text color
                        },
                      }}
                    >
                      <Typography variant="bodyText3">{version.invoice}</Typography>
                    </TextedButton1>

                  </Box>
                </TableCell>
              </TableRow>
              {index < data.length - 1 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Divider
                      sx={{
                        my: 0, // Remove vertical margins
                        borderColor: 'white', // Color of the divider
                        borderWidth: '1px', // Thickness of the divider
                        borderStyle: 'solid', // Style of the border
                        margin: 0, // Ensure there's no margin applied
                        padding: 0, // Ensure there's no padding applied
                        height: '1px', // Ensure the height of the divider is just a line
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default VersionHistory;
