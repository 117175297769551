import { CloseRounded } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import NavigationScroll from "layout/NavigationScroll";
import { createContext, useEffect, useMemo, useState } from "react";
import ReactGA from 'react-ga4';
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import toastr from "toastr";
// routes
// import Router from "./routes";
import Routes from "./routes";
// theme
import ThemeProvider from "./theme";
// components
// import "toastr/build/toastr.min.css";
// import { StyledChart } from "./components/chart";
// import ScrollToTop from "./components/scroll-to-top";
import usePreventScreenshot from "./hooks/usePreventScreenshot";
import "./styles.css";

const GA4_TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID ?? "G-HTKPF0G88W";
ReactGA.initialize(GA4_TRACKING_ID);

// toastr.options.timeOut = 5000;
// toastr.options.progressBar = true;
// toastr.options.positionClass = "toast-bottom-left";
// toastr.options.preventDuplicates = true;
// // ----------------------------------------------------------------------
// const AxiosInterceptor = ({ calls, children }) => {
//     const navigate = useNavigate();
//     useEffect(() => {
//         const reqInt = async config => {
//             calls.current += 1;
//             return config;
//         };
//         const resInt = res => {
//             calls.current -= 1;
//             return res;
//         };
//         const errInt = err => {
//             calls.current -= 1;
//             if ((err?.response?.status === 401 || err?.response?.status === 403) && !["/login", "/register", "/forgot-password"].includes(window.location.pathname)) {
//                 Swal.fire({
//                     position: "top-center",
//                     icon: "warning",
//                     color: '#fff',
//                     background: '#00838F',
//                     toast: true,
//                     title: "You have been logged out due to inactivity! Please login again to use the application.",
//                     showConfirmButton: false,
//                     timer: 3000
//                 });
//                 navigate("/login", { replace: true });
//             }
//             return Promise.reject(err);
//         };
//         const rint = axios.interceptors.request.use(reqInt);
//         const reint = axios.interceptors.response.use(resInt, errInt);
//         return true;
//     }, []);
//     return children;
// };

// export const LoadingContext = createContext({
//     isLoading: false, showHelp: false, setShowHelp: () => {
//     }, editProfile: false, video: null
// });

export const ContextProvider = createContext({});

export default function App() {
    const [calls, setCalls] = useState(0);
    // const calls = useRef(0);
    const isLoading = useMemo(() => calls > 0, [calls]);
    const [showHelp, setShowHelp] = useState(true);
    const [editProfile, setEditProfile] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isIframLoading, setIsIframLoading] = useState(true);
    const [user, setUser] = useState({});

    const fetchUser = async () => {
        const response = await axios.get('/auth/user');
        setUser(response.data);
        return response;
    };

    // const loadScript = (src) => {
    //     return new Promise((resolve) => {
    //         const script = document.createElement("script");
    //         script.src = src;
    //         script.onload = () => {
    //             resolve(true);
    //         };
    //         script.onerror = () => {
    //             resolve(false);
    //         };
    //         document.body.appendChild(script);
    //     });
    // }

    // useEffect(() => {
    //     loadScript("https://checkout.razorpay.com/v1/checkout.js").then(res => {
    //         if (!res) toastr.error('Unable to load Razorpay SDK');
    //     });
    // }, []);



    // const temp = useMemo(() => {
    //     const reqInt = async config => {
    //         if (!config.url.includes("/testator/check/payment") && !config.url.includes('/generics') && !config.url.includes('/properties'))
    //             setCalls(c => c + 1);
    //         return config;
    //     };
    //     const resInt = res => {
    //         setCalls(c => c > 0 ? c - 1 : 0);
    //         return res;
    //     };
    //     const errInt = err => {
    //         setCalls(c => c > 0 ? c - 1 : 0);
    //         if ((err?.response?.status === 401 || err?.response?.status === 403) && !["/login", "/register", "/forgot-password", "/"].includes(location.pathname)) {
    //             // toastr.warning("You have been logged out due to inactivity! Please login again to use the application.");
    //             Swal.fire({
    //                 position: "top-center",
    //                 icon: "warning",
    //                 color: '#fff',
    //                 background: '#00838F',
    //                 toast: true,
    //                 title: "You have been logged out due to inactivity! Please login again to use the application.",
    //                 showConfirmButton: false,
    //                 timer: 3000
    //             });
    //             navigate("/login", { replace: true });
    //         }
    //         return Promise.reject(err);
    //     };
    //     axios.interceptors.request.clear();
    //     axios.interceptors.response.clear();
    //     axios.interceptors.request.use(reqInt);
    //     axios.interceptors.response.use(resInt, errInt);
    //     return calls > 0;
    // }, [location]);

    // const [isLoading, setLoading] = useState(false);
    // useEffect(() => {
    //   setLoading(calls > 0);
    // }, [calls])

    // useEffect(() => {
    //     if (location.pathname === "/dashboard/app") setEditProfile(false);
    // }, [location]);

    // useEffect(() => {
    //     // Send the pageview hit to GA4
    //     ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    // }, [location.pathname]); // Triggered on path change

    // const video = useMemo(() => videos[location.pathname.split('/').pop()], [location]);
    // console.log('video', video);
    usePreventScreenshot();
    return (
        <ThemeProvider>
            {/* <LoadingContext.Provider value={{ isLoading, showHelp, setShowHelp, editProfile }}> */}
            <ContextProvider.Provider value={{ user, fetchUser }}>
                <NavigationScroll>
                    {/* <Router /> */}
                    <Routes />
                </NavigationScroll>
            </ContextProvider.Provider>
        </ThemeProvider>
    );
}
