import * as Yup from 'yup';
import regex from 'regex';

// Custom function to check if a date is valid
const isValidDate = (day, month, year) => {
    if (!day || !month || !year || year.length !== 4) return false;

    const dateStr = `${year}-${month}-${day}`;
    const date = new Date(dateStr);

    return (
        date.getFullYear() === parseInt(year, 10) &&
        date.getMonth() + 1 === parseInt(month, 10) &&
        date.getDate() === parseInt(day, 10)
    );
};

// Custom function to check if the user is older than 18
const isOlderThan18 = (year, month, day) => {
    if (!isValidDate(day, month, year)) return false;

    const today = new Date();
    const birthDate = new Date(`${year}-${month}-${day}`);

    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        return age - 1 >= 18;  // Adjust for the case where the birthday hasn't happened yet this year
    }

    return age >= 18;
};

// Custom function to check if a date is in the past (not a future date)
const isPastDate = (day, month, year) => {
    if (!isValidDate(day, month, year)) return false;

    const today = new Date();
    const inputDate = new Date(`${year}-${month}-${day}`);

    return inputDate <= today;
};


const formValidation = {
    firstNameValidation: Yup.string()
        .required("required field")
        .matches(regex.nameRegex, "Only alphabets are allowed"),

    middleNameValidation: Yup.string().nullable()
        .matches(regex.nameRegex, "Only alphabets are allowed"),

    lastNameValidation: Yup.string()
        .required("required field")
        .matches(regex.nameRegex, "Only alphabets are allowed"),

    emailValidation: Yup.string()
        .required("required field")
        .matches(regex.emailRegex, "Invalid email format"),

    phoneValidation: Yup.string()
        .required("required field")
        .test('phone-number', 'Invalid phone number', value => {
            if (!value) return false;
            const [code, dial, phone] = value.split(':');
            if (['in', 'us'].includes(code)) {
                return phone.length === 10;
            } if (code === 'gb') {
                return phone.length === 11;
            }
            return phone.length >= 7 && phone.length <= 12;
        }),

    panValidation: Yup.string()
        .required("required field")
        .matches(regex.panRegex, "Please enter a valid PAN"),

    testatorDob: Yup.string()
        .required('required field')
        .test('is-valid-date', 'Please enter a valid date', value => {
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isValidDate(day, month, year);
        }).test('is-not-future-date', 'Date cannot be in the future', value => {
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isPastDate(day, month, year);
        }).test('is-older-than-18', 'You must be older than 18', value => {
            const [year, month, day] = value ? value.split('-') : [];
            return isOlderThan18(year, month, day);
        }),

    dateValidation: Yup.string()
        .required('required field')
        .test('is-valid-date', 'Please enter a valid date', value => {
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isValidDate(day, month, year);
        }).test('is-not-future-date', 'Date cannot be in the future', value => {
            const [year, month, day] = value ? value.split('-') : [];
            return isPastDate(day, month, year);
        }),


    // Individual field validation schemas for address
    houseNoValidation: Yup.string()
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    houseNameValidation: Yup.string().
        required('required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    areaValidation: Yup.string().
        required('required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    countryValidation: Yup.string().
        required('required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    stateValidation: Yup.string().
        required('required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    cityValidation: Yup.string().
        required('required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    zipValidation: Yup.string().
        required('required field')
        .matches(regex.zipRegex, "Please enter a valid PIN code"),

    same_as_currentValidation: Yup.boolean()
        .required('Required field'),

    // Existing Will Upload Validation
    registrarPlaceValidation: Yup.string().
        required('required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),

    fileValidation: Yup.mixed()
        .required('A file is required')
        .test('fileSize', 'File Size is too large', value => value && value.size <= 10485760) // 10MB
        .test('fileType', 'Unsupported File Format', value => value && ['application/pdf'].includes(value.type)),
};

export default formValidation;
