import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as HelpIcon } from 'assets/help-button.svg';
import { ContextProvider } from 'App';
import Lottie from 'components/DesignSystem/Lottie';
import HelpSection from 'components/featureComponent/helpResourceSection/HelpResourceSection';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContainedButton1 } from '../../components/DesignSystem/Button';
import Logo from '../../components/logo';


const ProfileCompletionPage = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [userProfile, setUserProfile] = useState({});
    const { fetchUser } = useContext(ContextProvider);

    const handleClose = () => setOpen(false);

    useEffect(() => {
        const getUserData = async () => {
            try {
                const { data } = await fetchUser();
                setUserProfile(data.user.profile); // Assuming data has a `profile` field
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };
        getUserData();
    }, []);

    return (
        <>
            <Box sx={{ position: 'absolute', top: "2.8rem", left: "2rem", width: '16rem', height: '8rem' }}>
                <Logo />
            </Box>
            <Grid
                container
                sx={{
                    height: '100%',
                    margin: 'auto',
                    padding: 10,
                    paddingTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {/* Image */}
                <Box
                    sx={{
                        width: '6.977rem', height: '6.976rem', mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                >
                    <Lottie name={'Loader'} size={111} />
                </Box>
                <Typography
                    variant="title2"
                    gutterBottom
                    align="center" // Center align text
                >
                    You are all set, {userProfile?.first_name}.
                </Typography>
                <Typography
                    variant="bodyText3"
                    gutterBottom
                    align="center" // Center align text
                    mt={2.5}
                >
                    Now that you have finished making your profile, you can go ahead and create your Will.
                </Typography>
                <Grid item>
                    <Box sx={{ marginTop: '5rem' }} onClick={() => navigate('/dashboard/default')}
                    >
                        <Lottie name='GwdButton' />
                    </Box>
                </Grid>
            </Grid>
            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: '3.125rem',
                    right: '3.125rem',
                    bgcolor: '#FFF3E0',
                    color: '#FF8C42',
                    '&:hover': {
                        bgcolor: '#FFE0B2',
                    },
                }}
                onClick={() => setOpen(true)}
            >
                <HelpIcon />
            </IconButton>
            <HelpSection
                open={open}
                handleClose={handleClose}
            />
        </>
    );
};

export default ProfileCompletionPage;
