export const assetSubTypes = {

    // ========================================
    // Asset SubTypes commercial
    // ========================================
    commercial: [
        {
            value: 'shop',
            label: 'Shop',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*' },
                { type: 'text', name: 'address', placeholder: 'Address*' },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)' },
                { type: 'text', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'text', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        },
        {
            value: 'office',
            label: 'Office',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*' },
                { type: 'text', name: 'address', placeholder: 'Address*' },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)' },
                { type: 'text', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'text', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        },
        {
            value: 'factory',
            label: 'Factory',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*' },
                { type: 'text', name: 'address', placeholder: 'Address*' },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)' },
                { type: 'text', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'text', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        },
        {
            value: 'godown',
            label: 'Godown',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*' },
                { type: 'text', name: 'address', placeholder: 'Address*' },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)' },
                { type: 'text', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'text', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes residential
    // ========================================
    residential: [
        {
            value: 'bungalow',
            label: 'Bungalow',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*' },
                { type: 'text', name: 'address', placeholder: 'Address*' },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)' },
                { type: 'text', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'text', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        },
        {
            value: 'flat_apartment',
            label: 'Flat/Apartment',
            inputFields: [
                { type: 'text', name: 'description_or_name', placeholder: 'Description/Name*' },
                { type: 'text', name: 'address', placeholder: 'Address*' },
                { type: 'number', name: 'area_sq_ft', placeholder: 'Area (Sq. Ft)' },
                { type: 'text', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'text', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes plot
    // ========================================
    plot: [
        {
            value: 'land',
            label: 'land',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "text", name: "address", placeholder: "Address line 1*" },
                { type: "text", name: "address_2", placeholder: "Address line 2" },
                { type: "number", name: "area_sq_ft", placeholder: "Area (Sq. Ft)*" },
                { type: "text", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes bankAccounts
    // ========================================
    bank_account: [
        {
            value: 'fixed_deposit',
            label: 'Fixed Deposit',
            inputFields: [
                { type: "text", name: "bank_name", label: "Bank Name", placeholder: "Bank Name*" },
                { type: "text", name: "branch_name", label: "Branch Name", placeholder: "Branch Name*" },
                { type: "text", name: "account_no", label: "Account Number/FD Number", placeholder: "Account Number/FD Number*" },
                { type: "text", name: "address", label: "Address", placeholder: "Address*" },
                { type: "number", name: "deposited_amount", label: "Deposited Amount", placeholder: "Deposited Amount*" },
                { type: "text", name: "joint_holder_name", label: "Joint Holder Name", placeholder: "Joint Holder Name*" },
                { type: "text", name: "nominee", label: "Nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", label: "Additional Details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'savings_account',
            label: 'Savings Account',
            inputFields: [
                { type: "text", name: "bank_name", placeholder: "Bank Name*" },
                { type: "text", name: "branch_name", placeholder: "Branch Name*" },
                { type: "text", name: "account_no", placeholder: "Account Number*" },
                { type: "text", name: "address", placeholder: "Address*" },
                { type: "text", name: "account_type", placeholder: "Account Type*" },
                { type: "text", name: "joint_holder_name", placeholder: "Joint Holder Name*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "text", name: "nominee", placeholder: "Nominee" }
            ]
        },
        {
            value: 'recurring_deposit',
            label: 'Recurring Deposit',
            inputFields: [
                { type: "text", name: "bank_name", placeholder: "Bank Name*" },
                { type: "text", name: "branch_name", placeholder: "Branch Name*" },
                { type: "text", name: "address", placeholder: "Address*" },
                { type: "text", name: "account_no", placeholder: "Account Number*" },
                { type: "text", name: "joint_holder_name", placeholder: "Joint Holder Name*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'current_account',
            label: 'Current Account',
            inputFields: [
                { type: "text", name: "bank_name", placeholder: "Bank Name*" },
                { type: "text", name: "branch_name", placeholder: "Branch Name*" },
                { type: "text", name: "account_no", placeholder: "Account Number*" },
                { type: "text", name: "address", placeholder: "Address*" },
                { type: "text", name: "account_type", placeholder: "Account Type*" },
                { type: "text", name: "joint_holder_name", placeholder: "Joint Holder Name*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "text", name: "nominee", placeholder: "Nominee" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes listedSecurities
    // ========================================
    listed_Securities: [
        {
            value: 'demat_account',
            label: 'Demat Account',
            inputFields: [
                { type: "text", name: "depository_participant_name", placeholder: "Depository Participant Name*" },
                { type: "text", name: "dp_id", placeholder: "DP ID*" },
                { type: "text", name: "client_id", placeholder: "Client ID*" },
                { type: "text", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "text", name: "nsdl_id", placeholder: "NSDL ID" },
                { type: "text", name: "nsdl_email", placeholder: "Email ID" },
                { type: "text", name: "csdl_id", placeholder: "CSDL ID" },
                { type: "text", name: "csdl_email", placeholder: "Email ID" }
            ]
        },
        {
            value: 'physical_shares',
            label: 'Physical Shares',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Company Name*" },
                { type: "number", name: "no_of_shares", placeholder: "Number of Shares*" },
                { type: "text", name: "share_certificate_no", placeholder: "Share Certificate Number*" },
                { type: "text", name: "folio_no", placeholder: "Folio Number" },
                { type: "text", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'mutual_funds',
            label: 'Mutual Funds',
            inputFields: [
                { type: "text", name: "fund_name", placeholder: "Fund Name*" },
                { type: "text", name: "folio_no", placeholder: "Folio Number*" },
                { type: "text", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'portfolio_management_services',
            label: 'Portfolio Management Services',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Company Name*" },
                { type: "text", name: "account_no_or_client_id", placeholder: "Account Number/Client ID*" },
                { type: "text", name: "joint_owner_name", placeholder: "Joint Owner Name*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "contact_person_name", placeholder: "Contact Person Name" },
                { type: "text", name: "contactPersonPhoneNumber", placeholder: "Contact Person Phone Number" },
                { type: "text", name: "address", placeholder: "Address" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'bonds',
            label: 'Bonds',
            inputFields: [
                { type: "text", name: "bond_name", placeholder: "Bond Name*" },
                { type: "text", name: "certificate_no", placeholder: "Certificate Number*" },
                { type: "number", name: "number_of_units", placeholder: "Number of Units*" },
                { type: "text", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'invit_funds',
            label: 'INVITs',
            inputFields: [
                { type: "text", name: "fund_name", placeholder: "Fund Name*" },
                { type: "text", name: "folio_no", placeholder: "Folio Number*" },
                { type: "number", name: "number_of_units", placeholder: "Number of Units*" },
                { type: "text", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'reits',
            label: 'REITs',
            inputFields: [
                { type: "text", name: "fund_name", placeholder: "Fund Name*" },
                { type: "text", name: "folio_no", placeholder: "Folio Number*" },
                { type: "number", name: "number_of_units", placeholder: "Number of Units*" },
                { type: "text", name: "joint_owner_name", placeholder: "Joint Owner Name" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'other_securities',
            label: 'Other Securities',
            inputFields: [
                { type: "text", name: "description_of_investment", placeholder: "Description of Investment*" },
                { type: "text", name: "unique_identification_no", placeholder: "Unique Identification No.*" },
                { type: "text", name: "join_account_holder", placeholder: "Joint Account Holder" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes listedSecurities
    // ========================================
    government_investment: [
        {
            value: 'national_savings_certificate',
            label: 'National Savings Certificate (NSC)',
            inputFields: [
                { type: "text", name: "certificate_no", placeholder: "Certificate Number*" },
                { type: "number", name: "investment_amount", placeholder: "Investment Amount*" },
                { type: "date", name: "maturity_date", placeholder: "Maturity Date" },
                { type: "text", name: "joint_holder_name", placeholder: "Joint Holder Name*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'kisan_vikas_patra',
            label: 'Kisan Vikas Patra (KVP)',
            inputFields: [
                { type: "text", name: "certificate_no", placeholder: "Certificate Number*" },
                { type: "number", name: "investment_amount", placeholder: "Investment Amount*" },
                { type: "date", name: "maturity_date", placeholder: "Maturity Date" },
                { type: "text", name: "joint_holder_name", placeholder: "Joint Holder Name*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }, {
            value: 'national_pension_scheme',
            label: 'National Pension Scheme (NPS)',
            inputFields: [
                { type: "number", name: "prn_no", placeholder: "PRN Number*" },
                { type: "number", name: "investment_amount", placeholder: "Investment Amount*" },
                { type: "date", name: "maturity_date", placeholder: "Maturity Date" },
                { type: "text", name: "joint_holder_name", placeholder: "Joint Holder Name*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }, {
            value: 'ppf',
            label: 'PPF',
            inputFields: [
                { type: "text", name: "bank_name", placeholder: "Bank Name*" },
                { type: "text", name: "branch_name", placeholder: "Branch Name*" },
                { type: "text", name: "account_no", placeholder: "Account Number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Unlisted Securities
    // ========================================
    unlisted_securities: [
        {
            value: 'demat_account',
            label: 'Demat Account',
            inputFields: [
                { type: "text", name: "depository_participant_name", placeholder: "Depository Participant Name*" },
                { type: "number", name: "dp_id", placeholder: "DP ID*" },
                { type: "number", name: "client_id", placeholder: "Client ID*" },
                { type: 'text', name: 'joint_owner_name', placeholder: 'Joint Owner Name' },
                { type: 'text', name: 'nominee', placeholder: 'Nominee' },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' },
                { type: "text", name: "nsdl_id", placeholder: "NSDL ID" },
                { type: "text", name: "nsdl_email", placeholder: "Email ID" },
                { type: "text", name: "csdl_id", placeholder: "CSDL ID" },
                { type: "text", name: "csdl_email", placeholder: "Email ID" }
            ]
        },
        {
            value: 'interest_in_llp',
            label: 'Interest in LLP',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Company Name*" },
                { type: "number", name: "interest", placeholder: "Interest*" },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' },
            ]
        },
        {
            value: 'interest_in_partnership_firm',
            label: 'Interest in Partnership Firm',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Company Name*" },
                { type: "number", name: "interest", placeholder: "Interest*" },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' },
            ]
        },
        {
            value: 'shares_in_private_limited_company',
            label: 'Shares in Private Limited Company',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Company Name*" },
                { type: "number", name: "no_of_shares", placeholder: "Number of shares*" },
                { type: 'text', name: 'additional_details', placeholder: 'Additional Details' },
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Ornaments
    // ========================================
    ornaments: [
        {
            value: 'gold',
            label: 'Gold',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "number", name: "quantity", placeholder: "Quantity*" },
                { type: "number", name: "weight", placeholder: "Weight (grams)*" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'silver',
            label: 'Silver',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "number", name: "quantity", placeholder: "Quantity*" },
                { type: "number", name: "weight", placeholder: "Weight (grams)*" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'jewellery',
            label: 'Jewellery',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "number", name: "quantity", placeholder: "Quantity*" },
                { type: "number", name: "weight", placeholder: "Weight (grams)*" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'silver_utensils',
            label: 'Silver Utensils',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "number", name: "quantity", placeholder: "Quantity*" },
                { type: "number", name: "weight", placeholder: "Weight (grams)*" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'precious_stones_or_collectables',
            label: 'Precious Stones/Collectibles',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "number", name: "quantity", placeholder: "Quantity*" },
                { type: "number", name: "weight", placeholder: "Weight (grams)*" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes vehicles
    // ========================================
    vehicles: [
        {
            value: 'car',
            label: 'Car',
            inputFields: [
                { type: "text", name: "car_make", placeholder: "Make of Vehicle*" },
                { type: "text", name: "license_plate_no", placeholder: "License Plate number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "description_of_vehicle", placeholder: "Description of Vehicle" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                // { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'two_wheeler',
            label: 'Two wheeler',
            inputFields: [
                { type: "text", name: "two_wheeler_make", placeholder: "Make of Vehicle*" },
                { type: "text", name: "license_plate_no", placeholder: "License Plate number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "description_of_vehicle", placeholder: "Description of Vehicle" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                // { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'large_vehicles',
            label: 'Large vehicles',
            inputFields: [
                { type: "text", name: "vehicle_make", placeholder: "Make of Vehicle*" },
                { type: "text", name: "license_plate_no", placeholder: "License Plate number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "description_of_vehicle", placeholder: "Description of Vehicle" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                // { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'other_vehicles',
            label: 'Other Vehicles',
            inputFields: [
                { type: "text", name: "make", placeholder: "Make of Vehicle*" },
                { type: "text", name: "type_of_vehicle", placeholder: "Type of Vehicle*" },
                { type: "text", name: "license_plate_no", placeholder: "License Plate number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "description_of_vehicle", placeholder: "Description of Vehicle" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                // { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Pets
    // ========================================
    pets: [
        {
            value: 'pets',
            label: 'Pets',
            inputFields: [
                { type: "text", name: "type_of_pet", placeholder: "Type of Pet*" },
                { type: "text", name: "breed", placeholder: "Breed" },
                { type: "text", name: "name_of_pet", placeholder: "Name of Pet*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'livestocks',
            label: 'Livestocks',
            inputFields: [
                { type: "text", name: "type_of_pet", placeholder: "Type of Livestock*" },
                { type: "number", name: "number", placeholder: "Number*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Art
    // ========================================
    art: [
        {
            value: 'art',
            label: 'Art',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "number", name: "quantity", placeholder: "Quantity*" },
                { type: "number", name: "weight", placeholder: "Weight (grams)*" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'sculptures',
            label: 'Sculptures',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "number", name: "quantity", placeholder: "Quantity*" },
                { type: "number", name: "weight", placeholder: "Weight (grams)*" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'paintings',
            label: 'Paintings',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "number", name: "quantity", placeholder: "Quantity*" },
                { type: "number", name: "weight", placeholder: "Weight (grams)*" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        },
        {
            value: 'miscellaneous',
            label: 'Miscellaneous',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "number", name: "quantity", placeholder: "Quantity*" },
                { type: "number", name: "weight", placeholder: "Weight (grams)*" },
                { type: "text", name: "location_where_kept", placeholder: "Location Where Kept*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" },
                { type: "file", name: "picture", placeholder: "Add Picture (File Upload Limit: 2 MB)" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Post Retiral
    // ========================================
    post_retiral: [
        {
            value: 'gratuity',
            label: 'Gratuity',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Name of the Company Where Employed*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'epf',
            label: 'EPF',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Name of the Company Where Employed*" },
                { type: "text", name: "uan_no", placeholder: "UAN Number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'superannuation',
            label: 'Superannuation',
            inputFields: [
                { type: "text", name: "company_name", placeholder: "Name of the Company Where Employed*" },
                { type: "text", name: "superannuation_no", placeholder: "Superannuation Number*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'senior_citizen_saving_scheme',
            label: 'Senior Citizen Saving Scheme',
            inputFields: [
                { type: "text", name: "scheme_name", placeholder: "Name of the Scheme*" },
                { type: "text", name: "uan_no", placeholder: "UAN Number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'monthly_pension_schemes',
            label: 'Monthly Pension Schemes',
            inputFields: [
                { type: "text", name: "name_of_insurance_company", placeholder: "Name of Insurance Company*" },
                { type: "text", name: "insurance_account_no", placeholder: "Insurance Account Number*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Insurances
    // ========================================
    insurances: [
        {
            value: 'term_insurance',
            label: 'Term Insurance',
            inputFields: [
                { type: "text", name: "insurance_company_name", placeholder: "Insurance Company Name*" },
                { type: "text", name: "policy_no", placeholder: "Policy Number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'accident_insurance',
            label: 'Accident Insurance',
            inputFields: [
                { type: "text", name: "insurance_company_name", placeholder: "Insurance Company Name*" },
                { type: "text", name: "policy_no", placeholder: "Policy Number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'money_back_policies',
            label: 'Money Back Policies',
            inputFields: [
                { type: "text", name: "insurance_company_name", placeholder: "Insurance Company Name*" },
                { type: "text", name: "policy_no", placeholder: "Policy Number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'other_insurance_policies',
            label: 'Other Insurance Policies',
            inputFields: [
                { type: "text", name: "insurance_company_name", placeholder: "Insurance Company Name*" },
                { type: "text", name: "policy_no", placeholder: "Policy Number*" },
                { type: "text", name: "nominee", placeholder: "Nominee" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Digital Assets
    // ========================================
    digital_assets: [
        {
            value: 'crypto_currency',
            label: 'Cryptocurrency',
            inputFields: [
                { type: "text", name: "type_of_asset", placeholder: "Type of Asset*" },
                { type: "text", name: "broker_or_platform", placeholder: "Broker/Platform*" },
                { type: "number", name: "units", placeholder: "Units*" },
                { type: "text", name: "username", placeholder: "Username*" },
                { type: "text", name: "password", placeholder: "Password*" }
            ]
        },
        {
            value: 'nfts',
            label: 'NFTs',
            inputFields: [
                { type: "text", name: "type_of_asset", placeholder: "Type of Asset*" },
                { type: "text", name: "broker_or_platform", placeholder: "Broker/Platform*" },
                { type: "number", name: "units", placeholder: "Units*" },
                { type: "text", name: "username", placeholder: "Username*" },
                { type: "text", name: "password", placeholder: "Password*" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Intellectual Property Rights
    // ========================================
    intellectual_property_rights: [
        {
            value: 'patents',
            label: 'Patents',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "text", name: "registration_no", placeholder: "Patent Registration Number/ Application Number*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'trademark',
            label: 'Trademark',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "text", name: "egistration_no", placeholder: "Trademark Registration Number/ Application Number*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'copyright',
            label: 'Copyright',
            inputFields: [
                { type: "text", name: "description_or_name", placeholder: "Description/Name*" },
                { type: "text", name: "registration_no", placeholder: "Copyright Registration Number/Application Number*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        },
        {
            value: 'domain_name',
            label: 'Domain Name',
            inputFields: [
                { type: "text", name: "name", placeholder: "Domain Name*" },
                { type: "text", name: "registrar_name", placeholder: "Registrar Name*" },
                { type: "text", name: "additional_details", placeholder: "Additional Details" }
            ]
        }
    ],

    // ========================================
    // Asset SubTypes Other Assets
    // ========================================
    other_assets: [
        {
            value: 'other_assets',
            label: 'Other Assets',
            inputFields: [
                { type: "text", name: "name", placeholder: "Name/Type of Asset*" },
                { type: "text", name: "details", placeholder: "Details of Asset" }
            ]
        }
    ]
};  