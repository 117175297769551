import React from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';


const StyledFormControl = styled(FormControl)(({ theme }) => ({
    '& .MuiInputBase-root': {
        borderRadius: '10px',
        backgroundColor: theme.palette.grey[200],
        padding: '10px 16px',
        '&.MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            border: `1px solid ${theme.palette.grey[100]}`,
        },
        '&.Mui-focused fieldset': {
            border: `1px solid ${theme.palette.grey[100]}`,
        },
        '&.Mui-error fieldset': {
            border: `1px solid ${theme.palette.error.main}`,
        },
    },
    '& .MuiInputBase-input': {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '27px',
        color: theme.palette.text.primary,
        padding: 0,
        caretColor: theme.palette.grey[300],
    },
    '& .MuiInputBase-input::placeholder': {
        fontStyle: 'italic',
        // color: theme.palette.grey[300],
        color: theme.palette.common.black,
        opacity:0.6
    },
    '& .MuiFormLabel-root': {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 400,
        fontSize: '18px',
        fontStyle: 'italic',
        lineHeight: '27px',
        color: theme.palette.text.secondary,
        top: '-2px',
        left: '16px',
        transformOrigin: 'top left',
        transform: 'translate(0, 12px) scale(1)',
    },
    '& .MuiFormHelperText-root': {
        alignSelf: 'end',
        fontFamily: 'DM Sans, sans-serif',
        fontStyle: 'italic',
    },
    '& .MuiFormLabel-root.Mui-focused': {
        color: theme.palette.grey[300],
    },
    '& .MuiFormLabel-root.Mui-error': {
        color: theme.palette.error.main,
    },
    '& input:-webkit-autofill': {
        boxShadow: `0 0 0 100px ${theme.palette.grey[200]} inset`,
    },
}));

const PhoneInput = ({ value, onChange, onBlur, touched, error, name, handleChange, helperText, placeholder = 'Mobile Number*', ...props }) => {
    const theme = useTheme();

    return (
        <StyledFormControl fullWidth error={Boolean(touched && error)}>
            <MuiTelInput
                // defaultCountry="IN"
                placeholder={placeholder}
                value={value}
                onChange={(value) => {
                    const formattedValue = value.replace(/\s+/g, '');
                    handleChange({ target: { name, value: formattedValue } });
                }}
                onBlur={onBlur}
                touched={touched}
                error={error}
                helperText={helperText}
                {...props}
            />
        </StyledFormControl>
    );
};

export default PhoneInput;
