import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
// hooks
import { useEffect } from "react";
import useResponsive from "../hooks/useResponsive";
// components
// sections
import LogoContainer from "../components/commonComponents/LogoContainer";
import { LoginForm } from "../sections/auth/login";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  }
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    sessionStorage.removeItem('video_close');
  }, [])

  return (
    <>
      <Helmet>
        <title> Sign In | Get Will Done </title>
      </Helmet>

      <StyledRoot>

        <LogoContainer>
          <Grid
            item
            // xs={12}
            md={5}
            style={{
              display: 'flex',
              // flexDirection: 'column',
              justifyContent: 'center',
              // alignItems: 'center',
              paddingTop: '135px',
              backgroundColor: '#fff',
            }}
          >
            <Box style={{ width: '100%', maxWidth: '402px', paddingLeft:0, paddingRight: 0  }}>
              <LoginForm />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f9f9f9',
            }}
          >
            <Box
              component="img"
              src={'/assets/gwd-login-img.svg'}
              alt="Decorative"
              style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
            />
          </Grid>
        </LogoContainer>
      </StyledRoot>
    </>
  );
}
