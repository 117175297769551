import MuiIconButton from '@mui/material/IconButton'; // Aliased import
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledIconButton = styled(MuiIconButton)(({ theme, variant, size, bgColor, hoverColor }) => ({
    backgroundColor: bgColor || 'transparent',
    color: theme.palette.getContrastText(bgColor || theme.palette.background.paper),
    borderRadius: variant === 'rounded' ? '50%' : theme.shape.borderRadius,
    border: variant === 'outlined' ? `1px solid ${theme.palette.divider}` : 'none',
    padding: size === 'small' ? '4px' : size === 'large' ? '12px' : '8px',
    transition: 'background-color 0.3s, color 0.3s', // Smooth transition
    '&:hover': {
        backgroundColor: hoverColor || theme.palette.action.hover,
    },
}));

const IconButton = ({
    icon,
    tooltip,
    onClick,
    color,
    size,
    bgColor,
    hoverColor,
    variant,
    disabled,
    ...props
}) => {
    const button = (
        <StyledIconButton
            onClick={onClick}
            size={size}
            bgColor={bgColor}
            hoverColor={hoverColor}
            variant={variant}
            disabled={disabled}
            {...props}
        >
            {icon}
        </StyledIconButton>
    );

    return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
};

IconButton.propTypes = {
    icon: PropTypes.element.isRequired,
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    bgColor: PropTypes.string,
    hoverColor: PropTypes.string,
    variant: PropTypes.oneOf(['text', 'outlined', 'filled', 'rounded']),
    disabled: PropTypes.bool,
};

IconButton.defaultProps = {
    tooltip: '',
    onClick: () => { },
    color: 'default',
    size: 'medium',
    bgColor: '',
    hoverColor: '',
    variant: 'text',
    disabled: false,
};

export default IconButton;
