// const _getIconFromAssets = (iconName) => {
// 	let iconSource = '';
// 	try {
// 		if (iconName.match(/http/i)) {
// 			iconSource = iconName;
// 		} else {
// 			iconSource = require(`assets/images/${iconName}`);
// 		}
// 	} catch (err) {
// 		console.error(err);
// 	}
// 	return iconSource;
// };

// export const getIconSource = ({ src, iconName, fallBackImageSrc }) => {
// 	let newSource = '';
// 	if (src) {
// 		newSource = src;
// 	} else if (iconName) {
// 		newSource = _getIconFromAssets(iconName);
// 	} else {
// 		newSource = fallBackImageSrc;
// 	}
// 	return newSource;
// };

// This will include .svg, .png, .jpg, and .jpeg files from the assets/images directory
const iconsContext = require.context('assets/', true, /\.(svg|png|jpg|jpeg)$/);

const _getIconFromAssets = (iconName) => {
  let iconSource = '';
  try {
    if (iconName.match(/http/i)) {
      iconSource = iconName;
    } else {
      iconSource = iconsContext(`./${iconName}`);
    }
  } catch (err) {
    console.error(err);
  }
  return iconSource;
};

export const getIconSource = ({ src, iconName, fallBackImageSrc }) => {
  let newSource = '';
  if (src) {
    newSource = src;
  } else if (iconName) {
    newSource = _getIconFromAssets(iconName);
  } else {
    newSource = fallBackImageSrc;
  }
  return newSource;
};
