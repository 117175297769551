import { useState } from "react";
import { Box, IconButton, Stack, TextField, useTheme } from "@mui/material";
import { LineWeight, Visibility, VisibilityOff } from "@mui/icons-material";
import OtpInput from "react-otp-input";

export const OTPField = ({ value, onChange }) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  return <Stack direction={'row'} spacing={1}>
    <OtpInput
      inputStyle={{
        fontSize: theme.typography.fontSize,
        width: '45.22px',
        height: '45px',
        padding: theme.spacing(1.5),
        marginRight: theme.spacing(3),
        backgroundColor: theme.palette.grey[200], // Light gray background
        borderRadius: '10px',
        border: `1px  ${theme.palette.grey[200]}`, // Gray border
        // color: theme.palette.text.primary, // Text color (default to theme text color)

      }}
      value={value}
      onChange={onChange}
      numInputs={6}
      isInputNum
      isInputSecure={showPassword}
      placeholder="______"
    sx={{
        '::placeholder': {
            color: theme.palette.grey[300], // Placeholder text color
            fontWeight: "400px", // Typography font weight
        },
    }}
    />
    {/* <IconButton onClick={() => setShowPassword((show) => !show)}>
  {showPassword ? <VisibilityOff /> : <Visibility />}
</IconButton> */}


  </Stack>
};