import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, useTheme } from '@mui/material';
import Icon from '../Icon';

const CustomSelectBox = styled(Box)(({ theme, isOpen, dropdownheight, dropdownColor, error }) => ({
  border: isOpen
    ? `1px solid ${theme.palette.grey[100]}`
    : `1px solid ${error ? 'red' : 'transparent'}`,
  borderRadius: isOpen ? '10px 10px 0 0' : '10px',
  height: dropdownheight ?? '45px',
  width: '100%',
  padding: '8px 12px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: dropdownColor ?? theme.palette.grey[200],
  '&:hover': {
    border: error ? '' : `1px solid ${theme.palette.grey[100]}`,
  },
}));

const OptionsList = styled(Box)(({ theme, dropdownColor }) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '0 0 10px 10px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: dropdownColor ?? theme.palette.grey[200],
  width: '100%',
  position: 'absolute',
  zIndex: 1,
  marginTop: '-1px',
  maxHeight: '200px',
  overflowX: 'auto'
}));

const OptionItem = styled(Box)(({ theme, isSelected, isLastItem }) => ({
  padding: '12px 16px',
  cursor: 'pointer',
  color: isSelected ? theme.palette.grey[600] : theme.palette.grey[400],
  borderBottom: isLastItem ? 'none' : '1px dashed #E0E0E0',
  '&:hover': {
    color: theme.palette.grey[600],
  },
}));

const Dropdown = ({ name, options = [], placeholder = 'Select', onBlur, error, helperText, handleChange, value, dropdownColor, dropdownheight='45px' }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Update selectedOption when the value changes
    const option = options.find(opt => opt.value === value);
    setSelectedOption(option ? option.label : '');
  }, [value, options]);

  const handleToggle = () => {
    setIsOpen(prev => !prev);
  };

  const handleSelect = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    handleChange({ target: { name, value: option.value } });
  };

  return (
    <Box sx={{ position: 'relative' }} ref={dropdownRef} >
      <CustomSelectBox onClick={handleToggle} isOpen={isOpen} error={error} onBlur={onBlur} dropdownColor={dropdownColor} dropdownheight={dropdownheight}>
        <Typography
          variant="bodyText3"
          sx={{
            fontStyle: selectedOption ? 'normal' : 'italic',
            color: selectedOption ? 'inherit' : theme.palette.grey[400],
          }}
        >
          {selectedOption || placeholder}
        </Typography>
        <Icon name='down-arrow-icon.png' height={6} width={10} />
      </CustomSelectBox>

      {isOpen && (
        <OptionsList dropdownColor={dropdownColor}>
          {options.map((option, index) => (
            <OptionItem
              key={option.value}
              onClick={() => handleSelect(option)}
              isSelected={selectedOption === option.label}
              isLastItem={index === options.length - 1}
            >
              <Typography variant="bodyText3">
                {option.label}
              </Typography>
            </OptionItem>
          ))}
        </OptionsList>
      )}
      {(error) && (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', mr: 2 }}>
          <Typography variant="errorText" sx={{ mt: 0.5 }}>
            {helperText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Dropdown;
