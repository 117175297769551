// HelpSection.js
import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, Grid, Divider, ToggleButtonGroup, ToggleButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, styled } from '@mui/material/styles';
import { ContainedButton2 } from 'components/DesignSystem/Button';
import FaqSection from './FaqSection';
import ResourcesSection from './ResourceSection';

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    border: 'none', // Remove default border
    padding: '8px 26px',
    borderRadius: '0', // Remove border radius to avoid top corners rounding
    '&:first-of-type': {
        borderRight: `2px solid ${theme.palette.secondary.light}`, // Right border for the first button
    },
    '&.Mui-selected': {
        backgroundColor: 'transparent', // No background color when selected
        color: theme.palette.grey[600], // Darker text color when selected
    },
    '&:not(.Mui-selected)': {
        color: theme.palette.grey[400], // Text color when not selected
    },
    textTransform: 'none', // Prevent uppercase transformation
}));

const HelpSection = ({ open, handleClose }) => {
    const theme = useTheme();
    const [helpType, setHelpType] = useState('faqs');

    const handleChange = (event, newAlignment) => {
        setHelpType(newAlignment);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '15px',
                    backgroundColor: theme.palette.secondary.lighter,
                    position: 'absolute',
                    top: '9rem',
                    left: '36.563rem',
                    right: '5.188rem',
                    bottom: '3.5rem',
                    boxShadow: 'none',
                    margin: '0', // Ensure no default margins
                    border: `1px solid ${theme.palette.secondary.dark}`,
                }
            }}
            BackdropProps={{
                sx: {
                    backgroundColor: 'transparent' // Set the backdrop color to transparent
                }
            }}
            maxWidth={false} // Disable default maxWidth
        >
            <DialogTitle>
                <Stack spacing={0.3} mt={1}>
                    <Typography variant="heading1" sx={{ color: theme.palette.secondary.main }}>
                        Help
                    </Typography>
                </Stack>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.secondary.darker,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <ToggleButtonGroup
                            value={helpType}
                            exclusive
                            onChange={handleChange}
                            aria-label="Help Type"
                        >
                            <CustomToggleButton value="faqs">
                                <Typography variant='bodyText3'>FAQ's</Typography>
                            </CustomToggleButton>
                            <CustomToggleButton value="resources">
                                <Typography variant='bodyText3'>Resources</Typography>
                            </CustomToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ borderBottom: `2px solid ${theme.palette.secondary.light}` }} />
                    </Grid>
                    {helpType === 'faqs' && (
                        <FaqSection
                        />
                    )}
                    {helpType === 'resources' && (
                        <>
                            <ResourcesSection />
                            <Box mt={2} sx={{ width: '100%', padding: '16px', backgroundColor: '#FFFFFF', border: '1px solid #78BEC4', borderRadius: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'sticky', bottom: 0 }}>
                                <Stack>
                                    <Typography variant="bodyText4" color='#1E1C1B'>Would you like to talk to a legal expert?</Typography>
                                    <Typography variant="suggestiveText" color='#707070'>Schedule a 30-minute call.</Typography>
                                </Stack>
                                <ContainedButton2 variant="contained" sx={{ width: '175px' }} >Book a Call</ContainedButton2>
                            </Box>
                        </>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default HelpSection;
