import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import WelcomeInfo from "components/featureComponent/OnboardingFlow/WelcomeInfo";
import useResponsive from "../../hooks/useResponsive";
import LogoContainer from "../../components/commonComponents/LogoContainer";


// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    width:'100%'
  }
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function WelcomePage() {
  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    sessionStorage.removeItem('video_close');
  }, [])

  return (
    <>
      <Helmet>
        <title> Sign In | Get Will Done </title>
      </Helmet>

      <StyledRoot>
        <LogoContainer>
          <Grid
            item
            // xs={12}
            md={9}
            lg={9}
            xl={8}
            sm={7}
            style={{
          
              paddingTop: '180px',
      
            }}
          >           
            <Box>
              <WelcomeInfo />
           </Box>
          </Grid>
         </LogoContainer>
      </StyledRoot>
     </>
  );
}