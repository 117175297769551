import { useEffect, useState } from 'react';
import { Box, Typography, useTheme, Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { ReactComponent as RealEstateIcon } from 'assets/real-estate-icon.svg';
import { ReactComponent as FinancialIcon } from 'assets/financial-icon.svg';
import { ReactComponent as PersonalPosessionsIcon } from 'assets/personal-posessions-icon.svg';
import { ReactComponent as OtherAssetsIcon } from 'assets/other-assets-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import { TextedButton1, ContainedButton1 } from 'components/DesignSystem/Button';
import AddAssetCard from 'components/featureComponent/assetsFlow/AddAssetCard';
import AssetInfoCardSection from 'components/featureComponent/assetsFlow/AssetInfoCardSection';
import AddAsset from 'pages/AssetPages/AddAsset';
import PageContainer from 'views/dashboard/PageContainer';
import FilterComponent from 'components/commonComponents/FilterComponent';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import { fetchAssets } from 'services/assetService';
// ==============================|| DEFAULT DASHBOARD ||============================== //

const AssetPage = () => {
    // const [isLoading, setLoading] = useState(true);
    const theme = useTheme();
    // useEffect(() => {
    //   setLoading(false);
    // }, []);

    const [open, setOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedSubType, setSelectedSubType] = useState();
    const [assets, setAssets] = useState({
        realEstate: [],
        financial: [],
        personalPossessions: [],
        otherAssets: [],
    });
    const [editId, setEditId] = useState();
    const [filteredAssets, setFilteredAssets] = useState(DUMMY_DATA.assets);
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState({ id: null, category: '' });

    const assetCategories = [
        { value: 'real_estate', heading: 'Real Estate', subHeading: 'Select and add a real estate asset.' },
        { value: 'financial', heading: 'Financial', subHeading: 'Select and add a financial asset.' },
        { value: 'personal_possessions', heading: 'Personal Possessions', subHeading: 'Select and add a personal possession.' },
        { value: 'other_assets', heading: 'Other Assets', subHeading: 'Select and add an asset from the list below.' },
    ];

    const fetchAssetsData = async () => {
        try {
            const { data } = await fetchAssets();
            const realEstate = (DUMMY_DATA.assets.filter((asset) => asset.category === 'real_estate'));
            const financial = (DUMMY_DATA.assets.filter((asset) => asset.category === 'financial'));
            const personalPossessions = (DUMMY_DATA.assets.filter((asset) => asset.category === 'personal_possessions'));
            const otherAssets = (DUMMY_DATA.assets.filter((asset) => asset.category === 'other_assets'));
            setAssets({ realEstate, financial, personalPossessions, otherAssets })
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchAssetsData();
    }, []);

    const handleClickOpen = (category) => {
        setSelectedCategory(category);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedCategory(undefined); // Reset the selected category to show all assets
    };

    const handleFilterChange = (selectedCategories) => {
        if (selectedCategories.length === 0) {
            setSelectedCategory(undefined);
            setFilteredAssets(DUMMY_DATA.assets); // Show all assets
        } else {
            setSelectedCategory(selectedCategories);
            setFilteredAssets(DUMMY_DATA.assets.filter((asset) => selectedCategories.includes(asset.category)));
        }
    };

    const currentAssetCategory = assetCategories.find((category) => category.value === selectedCategory) || {};

    const handleOnEditClick = (id, category) => {
        setEditId(id);
        handleClickOpen(category);
    };

    const handleOnDeleteClick = (id, category) => {
        console.log("delete category", category)
        setDeleteInfo({ id, category });
        setIsAlertDialogOpen(true);
    };

    const handleAlertDialogClose = () => {
        setIsAlertDialogOpen(false);
    };

    const handleDeleteConfirmation = () => {
        const { id, category } = deleteInfo;
        if (category) {
            console.log("delete category", category)
            // deleteGenerics(category, id);
            // fetchMyPeople();
        }
        setIsAlertDialogOpen(false);
    };



    return (
        <PageContainer>
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="title1">Assets</Typography>
                    <Typography
                        variant="bodyText3"
                        color={theme.palette.grey[400]}
                        display={'inline-block'}
                        maxWidth={'100%'}
                        width={'44.563rem'}
                    >
                        Thinking about all that you own can lead you up memory lane. The nostalgia may help you recall precious
                        items you want to keep in the family.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', mt: '20px' }}>
                    <AddAssetCard icon={RealEstateIcon} title="Real Estate" onClick={() => handleClickOpen('real_estate')} />
                    <AddAssetCard icon={FinancialIcon} title="Financial" onClick={() => handleClickOpen('financial')} />
                    <AddAssetCard icon={PersonalPosessionsIcon} title="Personal Possessions" onClick={() => handleClickOpen('personal_possessions')} />
                    <AddAssetCard icon={OtherAssetsIcon} title="Other Assets" onClick={() => handleClickOpen('other_assets')} />
                </Box>

                <FilterComponent
                    title="All Assets"
                    categories={assetCategories.map((category) => category.value)}
                    onFilterChange={handleFilterChange}
                    categoryHeadings={assetCategories}
                    containerSx={{ mt: 4, paddingRight: '70px' }}
                />

                <Box sx={{ padding: '24px 0' }}>
                    {console.log('selectedCategoryselectedCategory', assets)}
                    {(selectedCategory === undefined ||
                        (Array.isArray(selectedCategory) && selectedCategory.includes('real_estate'))) && (
                            <AssetInfoCardSection
                                title="Real Estate"
                                icon={RealEstateIcon}
                                assets={assets.realEstate}
                                onEditClick={(id) => handleOnEditClick(id, 'real_estate')}
                                onDeleteClick={(id) => handleOnDeleteClick(id, 'real_estate')}
                            />
                        )}
                    {(selectedCategory === undefined ||
                        (Array.isArray(selectedCategory) && selectedCategory.includes('financial'))) && (
                            <AssetInfoCardSection
                                title="Financial"
                                icon={FinancialIcon}
                                assets={assets.financial}
                                onEditClick={(id) => handleOnEditClick(id, 'financial')}
                                onDeleteClick={(id) => handleOnDeleteClick(id, 'financial')}
                            />
                        )}
                    {(selectedCategory === undefined ||
                        (Array.isArray(selectedCategory) && selectedCategory.includes('personal_possessions'))) && (
                            <AssetInfoCardSection
                                title="Personal Possessions"
                                icon={PersonalPosessionsIcon}
                                assets={assets.personalPossessions}
                                onEditClick={(id) => handleOnEditClick(id, 'personal_possessions')}
                                onDeleteClick={(id) => handleOnDeleteClick(id, 'personal_possessions')}
                            />
                        )}
                    {(selectedCategory === undefined ||
                        (Array.isArray(selectedCategory) && selectedCategory.includes('other_assets'))) && (
                            <AssetInfoCardSection
                                title="Other Assets"
                                icon={OtherAssetsIcon}
                                assets={assets.otherAssets}
                                onEditClick={(id) => handleOnEditClick(id, 'other_assets')}
                                onDeleteClick={(id) => handleOnDeleteClick(id, 'other_assets')}
                            />
                        )}
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        sx: {
                            borderRadius: '15px',
                            backgroundColor: '#FFFFFF',
                            position: 'absolute',
                            top: '9rem',
                            // left: '22.8125rem',
                            left: '25.8125rem',
                            right: '6.5625rem',
                            bottom: '3.125rem',
                            boxShadow: 24,

                            margin: '0', // Ensure no default margins
                        },
                    }}
                    maxWidth={false} // Disable default maxWidth
                >
                    <DialogTitle>
                        <Stack spacing={0.3} mt={1}>
                            <Typography variant="heading1" sx={{ color: theme.palette.secondary.main }}>
                                {currentAssetCategory.heading}
                            </Typography>
                            <Typography variant="smallText" sx={{ color: theme.palette.grey[400] }}>
                                {currentAssetCategory.subHeading}
                            </Typography>
                        </Stack>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <AddAsset assetCategory={selectedCategory} setSelectedSubType={setSelectedSubType} editId={editId} />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 25,
                                right: 20,
                                display: 'flex',
                                gap: 1,
                            }}
                        >
                            <TextedButton1
                                variant="outlined"
                                // onClick={handleExternalSubmit}
                                sx={{ mr: 2, minWidth: '125px' }}
                            >
                                <Typography variant="bodyText3">Save & Add More</Typography>
                            </TextedButton1>

                            <ContainedButton1 type="submit" sx={{ mr: 2, minWidth: '125px' }}>
                                <Typography variant="bodyText3">
                                    {/* {isEdit ? 'Update Asset' : 'Save'} */}
                                    Save
                                </Typography>
                            </ContainedButton1>
                        </Box>
                    </DialogContent>
                </Dialog>
                <AlertDialog
                    open={isAlertDialogOpen}
                    onClose={handleAlertDialogClose}
                    title={`Are you sure you want to delete this ${deleteInfo.category}?`}
                    primaryButtonLabel="Keep"
                    secondaryButtonLabel="Delete"
                    onPrimaryButtonClick={handleAlertDialogClose} // Handle 'Keep' action
                    onSecondaryButtonClick={handleDeleteConfirmation} // Handle 'Delete' action
                />
            </>
        </PageContainer>
    );
};

export default AssetPage;

const DUMMY_DATA = {
    assets: [
        {
            id: 19667,
            category: 'real_estate',
            type: 'plot',
            sub_type: 'land',
            information: {
                description_or_name: 'Sunny Villa',
                address: 'Pune',
                area_sq_ft: 2500,
            },
            createdAt: '2024-08-23T10:23:46.801Z',
            updatedAt: '2024-08-23T10:23:46.801Z',
            deletedAt: null,
            UserId: 845,
            Mappings: [],
        },
        {
            id: 19668,
            category: 'financial',
            type: 'stock_market_investments',
            sub_type: 'mutual_funds',
            information: {
                fund_name: 'Growth Fund',
                folio_no: 'GF123456',
            },
            createdAt: '2024-08-23T10:27:39.381Z',
            updatedAt: '2024-08-23T10:27:39.381Z',
            deletedAt: null,
            UserId: 845,
            Mappings: [],
        },
        {
            id: 19669,
            category: 'financial',
            type: 'bank_investments',
            sub_type: 'bank_account',
            information: {
                bank_name: 'AB Bank Ltd',
                joint_holder_name: 'None',
                branch_name: 'baner',
                account_no: '45646654564654654654',
                address: 'Pune 411021',
                account_type: 'Savings',
            },
            createdAt: '2024-08-23T10:28:54.173Z',
            updatedAt: '2024-08-23T10:28:54.173Z',
            deletedAt: null,
            UserId: 845,
            Mappings: [],
        },
        {
            id: 19670,
            category: 'personal_possessions',
            type: 'ornaments_and_other_investments',
            sub_type: 'gold',
            information: {
                description_or_name: 'Antique Vase',
                quantity: 1,
                weight: 20,
            },
            createdAt: '2024-08-23T10:31:21.414Z',
            updatedAt: '2024-08-23T10:31:21.414Z',
            deletedAt: null,
            UserId: 845,
            Mappings: [],
        },
        {
            id: 19671,
            category: 'personal_possessions',
            type: 'vehicles',
            sub_type: 'car',
            information: {
                make: 'Toyota',
                license_plate_no: 'ABC1234',
            },
            createdAt: '2024-08-23T10:31:53.579Z',
            updatedAt: '2024-08-23T10:31:53.579Z',
            deletedAt: null,
            UserId: 845,
            Mappings: [],
        },
        {
            id: 19672,
            category: 'other_assets',
            type: 'insurance_investments',
            sub_type: 'term_insurance',
            information: {
                insurance_company_name: 'Mahindra',
                policy_no: 'ADCHJ31534685',
            },
            createdAt: '2024-08-23T10:46:57.172Z',
            updatedAt: '2024-08-23T10:46:57.172Z',
            deletedAt: null,
            UserId: 845,
            Mappings: [],
        },
        {
            id: 19673,
            category: 'other_assets',
            type: 'other_assets',
            sub_type: 'other_assets',
            information: {
                name: 'OtherAssetType',
                details: 'DetailsOtherAsset',
            },
            createdAt: '2024-08-23T10:48:01.366Z',
            updatedAt: '2024-08-23T10:48:01.366Z',
            deletedAt: null,
            UserId: 845,
            Mappings: [],
        },
    ],
};
