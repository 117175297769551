import { Circle } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import { noop } from 'lodash';

const RadioField = ({
    label = '',
    name = '',
    value = '',
    onChange = noop,
    options = [],
    labelVariant = 'bodyText4',
    radioSize = 'small',
    radioColor = '#F47B20',
    containerStyle = {},
    groupStyle = {}
}) => {
    const theme = useTheme();
    return (
        <FormControl>
            <div style={{ display: 'flex', alignItems: 'center', ...containerStyle }}>
                <FormLabel style={{ marginRight: '1rem', color: theme.palette.grey[600] }}>
                    <Typography marginLeft={0} variant={labelVariant}>{label}</Typography>
                </FormLabel>
                <RadioGroup
                    aria-label={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    style={{ display: 'flex', flexDirection: 'row', ...groupStyle }}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={
                                <Radio
                                    size={radioSize}
                                    sx={{
                                        color: radioColor,
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 14,
                                        },
                                    }}
                                    checkedIcon={<Circle />}
                                />
                            }
                            label={<Typography sx={{marginLeft: '-4px'}} variant={labelVariant}>{option.label}</Typography>}
                        />
                    ))}
                </RadioGroup>
            </div>
        </FormControl>
    );
};

export default RadioField;
