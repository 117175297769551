import { useTheme } from '@emotion/react';
import { Box, Divider, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from 'components/DesignSystem/Icon';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { ContainedButton1, TextedButton2 } from '../../components/DesignSystem/Button';
import CustomTextField from '../../components/DesignSystem/InputField';
import useResponsive from '../../hooks/useResponsive';
import ServicesForm from './ServicesForm';

// Styled Components
const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export default function ServicesPage() {
    const mdUp = useResponsive('up', 'md');
    const theme = useTheme();
    const navigate = useNavigate();

    const handleGoToDashboard = () => {
        navigate('/landingPage');
    };

    return (
        <>
            <Helmet>
                <title>Services | Get Will Done </title>
            </Helmet>
            <Box>
                <Box p={'0 50px'}>  {/* header */}
                    <Box sx={{ minHeight: '130px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Icon name="will-logo.png" alt="Will Logo" height={77} width={154} />
                        </Box>
                        <Box sx={{ display: 'flex', }}>
                            <TextedButton2
                                sx={{
                                    width: '160px',
                                    height: '45px',
                                    marginRight: theme.spacing(2),
                                }}
                                onClick={handleGoToDashboard} // Call the handleGoToDashboard function on click
                            >
                                <Typography
                                    variant="bodyText4"
                                    sx={{
                                        color: theme.palette.secondary.main,
                                        fontWeight: 'bold',
                                    }}
                                    onClick={handleGoToDashboard}
                                >
                                    Go to Dashboard
                                </Typography>
                            </TextedButton2>
                            <Icon name="profile-icon.svg" alt="Profile Logo" size={48} />
                        </Box>
                    </Box>
                    <Divider sx={{ color: theme.palette.grey[100] }} /> {/* Add this line */}
                </Box>
                <StyledRoot>
                    <Grid container>
                        {/* Left Grid: Service Form */}
                        <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                backgroundColor: '#fff',
                                boxShadow: '10px 0px 18px 0px rgba(0, 0, 0, 0.04)',
                                zIndex: 11
                            }}
                        >
                            <Box sx={{ width: '100%', maxWidth: '85%', margin: '0 auto', padding: '48px 0', }}>
                                <ServicesForm />
                            </Box>
                        </Grid>
                        {/* Right Grid: Cart */}
                        <Grid
                            item
                            xs={12}
                            md={5}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                backgroundColor: '#f9f9f9',
                                backgroundImage: 'url(/assets/Service-Cart.svg)', // Replace with your image path
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <Box
                                sx={{
                                    border: `1px solid #e0e0e0`,
                                    borderRadius: '20px',
                                    p: '25px 20px 18px',
                                    backgroundColor: '#fff',
                                    // width: '523px',
                                    // height: '450px',
                                    // marginBottom: '400px',
                                    marginTop: '128px',
                                }}
                            >
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="title2">My Cart</Typography>
                                </Box>

                                <Box sx={{ mb: 3 }}>
                                    <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
                                        <CustomTextField
                                            variant="outlined"
                                            value="Coupon Code"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            sx={{
                                                height: '40px', // Adjust height as needed
                                            }}
                                        />
                                        <Typography variant="bodyText3">
                                            <Link
                                                color={theme.palette.secondary.main}
                                                sx={{
                                                    mb: 2,
                                                    marginLeft: '10px',
                                                    marginBottom: 0,
                                                    textDecoration: 'underline',
                                                }}
                                                href="#"
                                                onClick={() => {
                                                    // window.location.href = '/login'; // Redirect to the login page
                                                }}
                                            >
                                                Apply
                                            </Link>
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 1 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginLeft: '20px',
                                            }}
                                        >
                                            <Typography variant="bodyText3" color={theme.palette.grey[400]}>
                                                Make your own Will
                                            </Typography>
                                            <Typography variant="bodyText4" color="#1E1C1B">
                                                ₹7,999
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider variant='middle' sx={{ mt: 2, mb: 1, borderStyle: 'dashed', }} />

                                    <Box sx={{ mb: 1 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginLeft: '20px',
                                            }}
                                        >
                                            <Typography variant="bodyText3" color={theme.palette.grey[400]}>
                                                Call with Legal Expert
                                            </Typography>
                                            <Typography variant="bodyText4" color="#1E1C1B">
                                                ₹ 999
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider variant='middle' sx={{
                                        mt: 2, mb: 1, borderStyle: 'dashed',
                                    }} />
                                    <Box sx={{ mb: 1 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginLeft: '20px',
                                            }}
                                        >
                                            <Typography variant="bodyText3" color={theme.palette.grey[400]}>
                                                Review by Lawyer
                                            </Typography>
                                            <Typography variant="bodyText4" color="#1E1C1B">
                                                ₹4,999
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ mt: 2, mb: 1, borderColor: '#C5C5C5' }} />
                                </Box>
                                {/* Total Amount */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 3,
                                    }}
                                >
                                    <Typography
                                        variant="bodyText3"
                                        color={theme.palette.secondary.main}
                                        sx={{
                                            mb: 2,
                                            marginLeft: '20px',
                                            marginBottom: 0,
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Total
                                    </Typography>
                                    <Typography variant="bodyText4" color="#1E1C1B">
                                        ₹ 12,197
                                    </Typography>
                                </Box>

                                {/* Proceed Button */}
                                <ContainedButton1
                                    color="primary"
                                    sx={{
                                        mt: 2,
                                        width: '483px',
                                    }}
                                >
                                    <Typography variant="bodyText2">Proceed</Typography>
                                </ContainedButton1>
                            </Box>
                        </Grid>
                    </Grid>
                </StyledRoot>
            </Box>
        </>
    );
}
