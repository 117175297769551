/*
  Prop Description:
  src => source of the icon.
  fallBackImageSrc => use this prop to override the existing fallback image.
	- the fallback image will be rendered, if there is some error while loading the src image.
  size => this will maintain the 1:1 for the image ie. (height and width will be equals to size).
  height, width => use this prop, if the the ratio of height and width is not 1:1.
  style => custom html style object.
  className => custom className.

  NOTE:
  fallBackImageSrc => by default we are using require('assets/images/common/iv_fallback_icon.svg')
  src => path of the image(avoid using this, instead use name prop)
  name => name of image

  Example with name prop
  1. Image inside common folder
  <Icon
	name='iv_add_icon.svg'
  />
  2. Image inside fisdom/finity folder
  <Icon
	name='iv_buyback.svg'
  />
*/

// import fallbackImg from 'assets/images/common/iv_fallback_icon.svg';
import { Box } from '@mui/material';
import { isFunction, noop } from 'lodash-es';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { getIconSource } from './helper';
import './icon.scss';
import IconSkeleton from './subComponents/IconSkeleton';

const Image = (props) => {
	const {
		height,
		width,
		fallBackImageSrc,
		size,
		className,
		style,
		src,
		alt,
		onClick,
		dataAid,
		onLoad,
		name,
		draggable,
	} = props;
	const imgWidth = width || size;
	const imgHeight = height || size;
	const [currentSrc, updateSrc] = useState();
	const [loaded, setLoaded] = useState(false);

	const onLoadImage = (event) => {
		setLoaded(true);
		if (isFunction(onLoad)) {
			onLoad(event);
		}
	};

	useEffect(() => {
		const newSource = getIconSource({
			src,
			iconName: name,
			fallBackImageSrc,
		});
		updateSrc(newSource);
	}, [src, fallBackImageSrc, name]);

	const onError = () => {
		if (loaded) {
			return;
		}
		updateSrc('');
	};

	return (
		<>
			{!loaded && <IconSkeleton size={size} width={width} height={height} style={style} />}
			<Box onClick={onClick}>
				<img
					src={currentSrc}
					style={{
						width: imgWidth,
						height: imgHeight,
						...style,
						flexShrink: 0,
						display: !loaded ? 'none' : '',
					}}
					className={`${isFunction(onClick) && 'icon-clickable'} ${className}`}
					alt={alt}
					// onClick={onClick}
					onLoad={onLoadImage}
					onError={onError}
					data-aid={`iv_${dataAid}`}
					draggable={draggable}

				/>
			</Box>
		</>
	);
};

const Icon = memo(Image);
Icon.Skeleton = IconSkeleton;

export default Icon;

Image.defaultProps = {
	// fallBackImageSrc: fallbackImg,
	height: 0,
	width: 0,
	size: 16,
	className: '',
	style: {},
	src: '',
	alt: '',
	onClick: noop,
	dataAid: '',
	onLoad: noop,
	name: '',
	draggable: false,
};

Image.propTypes = {
	fallBackImageSrc: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	size: PropTypes.number,
	className: PropTypes.string,
	style: PropTypes.object,
	src: PropTypes.string,
	alt: PropTypes.string,
	onClick: PropTypes.func,
	dataAid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onLoad: PropTypes.func,
	name: PropTypes.string,
	draggable: PropTypes.bool,
};
