// import React, { useState } from 'react';
// import { Card, Typography, TextField, Checkbox, FormControlLabel, Divider, Switch, Box, useTheme } from '@mui/material';
// import Icon from 'components/DesignSystem/Icon';

// const DistributionCard = ({ asset }) => {
//     const theme = useTheme();

//     const [equalDistribution, setEqualDistribution] = useState(false);
//     const [remainingPercentage, setRemainingPercentage] = useState(100);

//     const [beneficiaries, setBeneficiaries] = useState(
//         asset.beneficiaries.map((ben) => ({
//             ...ben,
//             percentage: ben.percentage ? ben.percentage.toString() : '00.00', // Initialize as string for direct input manipulation
//         }))
//     );

//     const handleDistributionChange = (index, value) => {
//         // Prevent non-numeric input
//         if (value && !/^\d*\.?\d*$/.test(value)) return;

//         const newBeneficiaries = [...beneficiaries];
//         newBeneficiaries[index].percentage = value;

//         setBeneficiaries(newBeneficiaries);

//         // Calculate the remaining percentage based on valid inputs
//         const parsedValue = parseFloat(value);
//         if (!Number.isNaN(parsedValue)) {
//             calculateRemainingPercentage(newBeneficiaries);
//         }
//     };

//     const calculateRemainingPercentage = (beneficiaries) => {
//         const total = beneficiaries.reduce((sum, ben) => {
//             const parsedPercentage = parseFloat(ben.percentage);
//             return sum + (Number.isNaN(parsedPercentage) ? 0 : parsedPercentage);
//         }, 0);
//         const remaining = (100 - total).toFixed(2); // Keep 2 decimal points
//         setRemainingPercentage(parseFloat(remaining));
//     };

//     const handleEqualDistribution = () => {
//         if (equalDistribution) {
//             // Turning off equal distribution, reset percentages
//             const newBeneficiaries = beneficiaries.map((ben) => ({
//                 ...ben,
//                 percentage: '00.00',
//             }));
//             setBeneficiaries(newBeneficiaries);
//             setRemainingPercentage(100);
//         } else {
//             // Turning on equal distribution, calculate equal percentages
//             const equalPercentage = (100 / beneficiaries.length).toFixed(2);
//             const newBeneficiaries = beneficiaries.map((ben) => ({
//                 ...ben,
//                 percentage: equalPercentage,
//             }));
//             setBeneficiaries(newBeneficiaries);
//             setRemainingPercentage(0);
//         }
//         setEqualDistribution(!equalDistribution);
//     };

//     const handlePetDistribution = (index) => {
//         const newBeneficiaries = beneficiaries.map((ben, i) => ({
//             ...ben,
//             selected: i === index,
//         }));
//         setBeneficiaries(newBeneficiaries);
//         setRemainingPercentage(0);
//     };

//     return (
//         <Card sx={{ display: 'flex', p: 2, mb: 2, backgroundColor: theme.palette.grey[200] }}>
//             {/* Left Section */}
//             <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//                 <Box sx={{ display: 'flex', flexDirection: 'column'}}>
//                     <Typography variant="bodyText3">{asset.name}</Typography>
//                     <Typography variant="smallText" color={theme.palette.grey[400]}>{asset.description}</Typography>
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px'}}>
//                     <Icon name='remaining-icon.png' size={24} />
//                     <Typography variant="smallText" color={remainingPercentage >= 0 ? theme.palette.grey[400] : theme.palette.error.main}>
//                         {remainingPercentage >= 0
//                             ? `Remaining: ${remainingPercentage}%`
//                             : `Exceeded: ${Math.abs(remainingPercentage)}%`}
//                     </Typography>
//                 </Box>
//             </Box>

//             {/* Right Section */}
//             <Box sx={{ flex: 2, pl: 2 }}>
//                 <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                     <Typography variant="subtitle1">Beneficiaries</Typography>
//                     <FormControlLabel
//                         control={<Switch checked={equalDistribution} onChange={handleEqualDistribution} />}
//                         label="Equal"
//                     />
//                 </Box>

//                 <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
//                     {beneficiaries.map((ben, index) => (
//                         <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                             <Typography variant="body2" sx={{ flex: 1 }}>
//                                 {ben.name}
//                             </Typography>
//                             {asset.type === 'pet' ? (
//                                 <Checkbox checked={ben.selected || false} onChange={() => handlePetDistribution(index)} />
//                             ) : (
//                                 <TextField
//                                     type="text"
//                                     value={ben.percentage}
//                                     onChange={(e) => handleDistributionChange(index, e.target.value)}
//                                     onBlur={() => {
//                                         const newValue = parseFloat(beneficiaries[index].percentage);
//                                         if (!Number.isNaN(newValue)) {
//                                             handleDistributionChange(index, newValue.toFixed(2));
//                                         } else {
//                                             handleDistributionChange(index, '00.00');
//                                         }
//                                     }}
//                                     sx={{ width: 80 }}
//                                     inputProps={{ maxLength: 6 }} // Limit input length to handle "100.00"
//                                 />
//                             )}
//                             {index < beneficiaries.length - 1 && <Divider sx={{ my: 1 }} />}
//                         </Box>
//                     ))}
//                 </Box>
//             </Box>
//         </Card>
//     );
// };

// export default DistributionCard;
// /////////////////////////////////////////////

// import React, { useState } from 'react';
// import { Card, Typography, TextField, Checkbox, FormControlLabel, Divider, Switch, Box, Grid } from '@mui/material';

// const DistributionCard = ({ asset }) => {
//   const [beneficiaries, setBeneficiaries] = useState(
//     asset.beneficiaries.map(ben => ({
//       ...ben,
//       percentage: ben.percentage ? ben.percentage.toString() : '00.00', // Initialize as string for direct input manipulation
//     }))
//   );
//   const [equalDistribution, setEqualDistribution] = useState(false);
//   const [remainingPercentage, setRemainingPercentage] = useState(100);

//   const handleDistributionChange = (index, value) => {
//     // Prevent non-numeric input
//     if (value && !/^\d*\.?\d*$/.test(value)) return;

//     const newBeneficiaries = [...beneficiaries];
//     newBeneficiaries[index].percentage = value;

//     setBeneficiaries(newBeneficiaries);

//     // Calculate the remaining percentage based on valid inputs
//     const parsedValue = parseFloat(value);
//     if (!Number.isNaN(parsedValue)) {
//       calculateRemainingPercentage(newBeneficiaries);
//     }
//   };

//   const calculateRemainingPercentage = (beneficiaries) => {
//     const total = beneficiaries.reduce((sum, ben) => {
//       const parsedPercentage = parseFloat(ben.percentage);
//       return sum + (Number.isNaN(parsedPercentage) ? 0 : parsedPercentage);
//     }, 0);
//     const remaining = (100 - total).toFixed(2); // Keep 2 decimal points
//     setRemainingPercentage(parseFloat(remaining));
//   };

//   const handleEqualDistribution = () => {
//     if (equalDistribution) {
//       // Turning off equal distribution, reset percentages
//       const newBeneficiaries = beneficiaries.map(ben => ({
//         ...ben,
//         percentage: '00.00',
//       }));
//       setBeneficiaries(newBeneficiaries);
//       setRemainingPercentage(100);
//     } else {
//       // Turning on equal distribution, calculate equal percentages
//       const equalPercentage = (100 / beneficiaries.length).toFixed(2);
//       const newBeneficiaries = beneficiaries.map(ben => ({
//         ...ben,
//         percentage: equalPercentage,
//       }));
//       setBeneficiaries(newBeneficiaries);
//       setRemainingPercentage(0);
//     }
//     setEqualDistribution(!equalDistribution);
//   };

//   const handlePetDistribution = (index) => {
//     const newBeneficiaries = beneficiaries.map((ben, i) => ({
//       ...ben,
//       selected: i === index,
//     }));
//     setBeneficiaries(newBeneficiaries);
//     setRemainingPercentage(0);
//   };

//   return (
//     <Card sx={{ display: 'flex', p: 2, mb: 2 }}>
//       {/* Left Section */}
//       <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//         <Box>
//           <Typography variant="h6">{asset.name}</Typography>
//           <Typography variant="subtitle1">{asset.description}</Typography>
//         </Box>
//         <Box>
//           <Typography variant="body2" color={remainingPercentage >= 0 ? 'text.primary' : 'error'}>
//             {remainingPercentage >= 0 ? `Remaining: ${remainingPercentage}%` : `Exceeded: ${Math.abs(remainingPercentage)}%`}
//           </Typography>
//         </Box>
//       </Box>

//       {/* Right Section */}
//       <Box sx={{ flex: 2, pl: 2 }}>
//         {/* Top Subsection */}
//         <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
//           <Typography variant="subtitle1">Beneficiaries</Typography>
//           <FormControlLabel
//             control={<Switch checked={equalDistribution} onChange={handleEqualDistribution} />}
//             label="Equal"
//           />
//         </Box>

//         {/* Bottom Subsection */}
//         <Grid container spacing={2} sx={{ position: 'relative' }}>
//           {beneficiaries.map((ben, index) => (
//             <React.Fragment key={index}>
//               <Grid item xs={12} sm={6}>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                   <Typography variant="body2" sx={{ flex: 1 }}>{ben.name}</Typography>
//                   {asset.type === 'pet' ? (
//                     <Checkbox
//                       checked={ben.selected || false}
//                       onChange={() => handlePetDistribution(index)}
//                     />
//                   ) : (
//                     <TextField
//                       type="text"
//                       value={ben.percentage}
//                       onChange={(e) => handleDistributionChange(index, e.target.value)}
//                       onBlur={() => {
//                         const newValue = parseFloat(beneficiaries[index].percentage);
//                         if (!Number.isNaN(newValue)) {
//                           handleDistributionChange(index, newValue.toFixed(2));
//                         } else {
//                           handleDistributionChange(index, '00.00');
//                         }
//                       }}
//                       sx={{ width: 80 }}
//                       inputProps={{ maxLength: 6 }} // Limit input length to handle "100.00"
//                     />
//                   )}
//                 </Box>
//               </Grid>

//               {/* Add dashed divider after each row */}
//               {index % 2 === 1 && index < beneficiaries.length - 1 && (
//                 <Grid item xs={12}>
//                   <Divider sx={{ borderStyle: 'dashed' }} />
//                 </Grid>
//               )}
//             </React.Fragment>
//           ))}

//           {/* Vertical divider */}
//           {beneficiaries.length > 1 && (
//             <Divider
//               orientation="vertical"
//               flexItem
//               sx={{
//                 position: 'absolute',
//                 left: '50%',
//                 top: 0,
//                 bottom: 0,
//                 borderStyle: 'solid',
//                 borderColor: 'rgba(0, 0, 0, 0.12)',
//               }}
//             />
//           )}
//         </Grid>
//       </Box>
//     </Card>
//   );
// };

// export default DistributionCard;
// ////////////////////////////////////////

import { Box, Card, Divider, Grid, Switch, Typography, useTheme } from '@mui/material';
import CheckBox from 'components/DesignSystem/CheckBox';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import React, { useState } from 'react';

const DistributionCard = ({ asset }) => {
    const theme = useTheme();

    const [equalDistribution, setEqualDistribution] = useState(false);
    const [remainingPercentage, setRemainingPercentage] = useState(100);

    const [beneficiaries, setBeneficiaries] = useState(
        asset.beneficiaries.map((ben) => ({
            ...ben,
            percentage: ben.percentage ? ben.percentage.toString() : '00.00', // Initialize as string for direct input manipulation
        }))
    );

    const isEvenBeneLength = beneficiaries.length;
    const isEven = isEvenBeneLength % 2 === 0;
    const itemsToExclude = isEven ? 2 : 1;

    const handleDistributionChange = (index, value) => {
        // Prevent non-numeric input
        if (value && !/^\d*\.?\d*$/.test(value)) return;

        const newBeneficiaries = [...beneficiaries];
        newBeneficiaries[index].percentage = value;

        setBeneficiaries(newBeneficiaries);

        // Calculate the remaining percentage based on valid inputs
        const parsedValue = parseFloat(value);
        if (!Number.isNaN(parsedValue)) {
            calculateRemainingPercentage(newBeneficiaries);
        }
    };

    const calculateRemainingPercentage = (beneficiaries) => {
        const total = beneficiaries.reduce((sum, ben) => {
            const parsedPercentage = parseFloat(ben.percentage);
            return sum + (Number.isNaN(parsedPercentage) ? 0 : parsedPercentage);
        }, 0);
        const remaining = (100 - total).toFixed(2); // Keep 2 decimal points
        setRemainingPercentage(parseFloat(remaining));
    };

    const handleEqualDistribution = () => {
        if (equalDistribution) {
            // Turning off equal distribution, reset percentages
            const newBeneficiaries = beneficiaries.map((ben) => ({
                ...ben,
                percentage: '00.00',
            }));
            setBeneficiaries(newBeneficiaries);
            setRemainingPercentage(100);
        } else {
            // Turning on equal distribution, calculate equal percentages
            const equalPercentage = (100 / beneficiaries.length).toFixed(2);
            const newBeneficiaries = beneficiaries.map((ben) => ({
                ...ben,
                percentage: equalPercentage,
            }));
            setBeneficiaries(newBeneficiaries);
            setRemainingPercentage(0);
        }
        setEqualDistribution(!equalDistribution);
    };

    const handlePetDistribution = (index) => {
        const newBeneficiaries = beneficiaries.map((ben, i) => ({
            ...ben,
            selected: i === index,
        }));
        setBeneficiaries(newBeneficiaries);
        setRemainingPercentage(0);
    };

    return (
        <Card sx={{ display: 'flex', p: 2, mb: 2, backgroundColor: theme.palette.grey[200], borderRadius: '15px' }} elevation={0}>
            {/* Left Section */}
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="bodyText3">{asset.name}</Typography>
                    <Typography variant="smallText" color={theme.palette.grey[400]}>{asset.description}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Icon name='remaining-icon.png' size={24} />
                    <Typography variant="smallText" color={remainingPercentage >= 0 ? theme.palette.grey[400] : theme.palette.error.main}>
                        {remainingPercentage >= 0
                            ? `Remaining: ${remainingPercentage}%`
                            : `Exceeded: ${Math.abs(remainingPercentage)}%`}
                    </Typography>
                </Box>
            </Box>

            {/* Right Section */}
            <Box sx={{ flex: 1.2, pl: 2 }}>
                {/* Top Subsection */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxHeight: '30px', paddingBottom: '8px', paddingLeft: '16px' }}>
                    <Typography variant="suggestiveText" color={theme.palette.grey[300]}>Beneficiaries</Typography>
                    <Box>
                        <Typography variant="suggestiveText" color={'#1E1C1B'}>Equal</Typography>
                        <Switch checked={equalDistribution} onChange={handleEqualDistribution} color='secondary' disabled={asset.type === 'pet'}/>
                    </Box>
                </Box>

                {/* Bottom Subsection */}
                <Grid container spacing={'4px'} sx={{ position: 'relative', maxHeight: 200, overflowY: 'auto', overflowX: 'hidden' }}>
                    {beneficiaries.map((ben, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px', padding: '0 16px' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        <Typography variant="smallText">{ben.name}</Typography>
                                        {asset.type === 'pet' ? (
                                            <CheckBox
                                                checked={ben.selected || false}
                                                onChange={() => handlePetDistribution(index)}
                                                sx={{paddingTop: '4px'}}
                                            />
                                        ) : (
                                            <InputField
                                                type="text"
                                                value={ben.percentage}
                                                onChange={(e) => handleDistributionChange(index, e.target.value)}
                                                onBlur={() => {
                                                    const newValue = parseFloat(beneficiaries[index].percentage);
                                                    if (!Number.isNaN(newValue)) {
                                                        handleDistributionChange(index, newValue.toFixed(2));
                                                    } else {
                                                        handleDistributionChange(index, '00.00');
                                                    }
                                                }}
                                                // sx={{ width: 80 }}
                                                inputProps={{ maxLength: 6 }} // Limit input length to handle "100.00"
                                                sx={{
                                                    // height: "25/px",
                                                    width: '70px',
                                                    '& .MuiInputBase-root': {
                                                        backgroundColor: 'white',
                                                        padding: '4px 8px', // Custom padding
                                                        borderRadius: '15px'
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        fontSize: '13px', // Custom font size
                                                        color: '##1E1C1B', // Custom text color
                                                    },
                                                    '& .MuiFormLabel-root': {
                                                        color: 'darkgreen', // Custom label color
                                                    },
                                                }}
                                            />
                                        )}
                                    </Box>
                                    <Box>
                                        {(index < isEvenBeneLength - itemsToExclude) &&
                                            <Divider
                                            sx={{
                                              borderStyle: 'dashed',
                                              borderColor: '#C5C5C5',
                                            //   minWidth: "250px",
                                              width: {
                                                xs: '100px',  // 100% width for extra-small screens (mobile devices)
                                                sm: '150px',   // 75% width for small screens (tablets)
                                                md: '100px',   // 60% width for medium screens (small laptops)
                                                lg: '180px',   // 50% width for large screens (desktops)
                                                xl: '300px',   // 40% width for extra-large screens (large desktops)
                                              },
                                            }}
                                          />
                                            // <Divider sx={{ borderStyle: 'dashed', width: '300px', borderColor: '#C5C5C5', '&. @medida' }} />
                                        }
                                    </Box>
                                </Box>
                            </Grid>

                            {/* Add dashed divider after each row */}
                            {/* {index % 2 === 1 && index < beneficiaries.length - 1 && (
                                <Grid item xs={12}>
                                    <Divider sx={{ borderStyle: 'dashed' }} />
                                </Grid>
                            )} */}
                        </React.Fragment>
                    ))}

                    {/* Vertical divider */}
                    {beneficiaries.length > 1 && (
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                top: 0,
                                bottom: 0,
                                border: '1px solid #888888',
                                height: '100%'
                            }}
                        />
                    )}
                </Grid>
            </Box>
        </Card>
    );
};

export default DistributionCard;