import { Box, Chip, Stack, Typography, useTheme } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';
import { useState } from 'react';
import DistributionCard from './DistributionCard';

const ScenarioTabs = ({ scenarios, handleAddClick }) => {
  const theme = useTheme();
  const [selectedScenario, setSelectedScenario] = useState(0);

  const handleChipClick = (index) => {
    setSelectedScenario(index);
  };

  return (
    <Box sx={{ padding: '16px 0' }}>
      {/* Chips for each scenario */}
      <Stack direction="row" spacing={1} justifyContent={'space-between'}>
        <Box>
          <Chip
            label={
              <Typography variant="bodyText4" color={theme.palette.grey[600]}>
                Add +
              </Typography>
            }
            onClick={() => {
              // Logic to add a new scenario
              handleAddClick();
            }}
            sx={{
              borderColor: 'transparent',
              borderWidth: '1px',
              borderStyle: 'solid',
              backgroundColor: '#F5F5F5',
              color: '#707070',
              fontWeight: 'normal',
              padding: '0 4px',
              marginRight: '5px'
            }}
          />
          {scenarios.map((scenario, index) => (
            <Chip
              key={index}
              label={<Typography variant="bodyText4">{`Scenario ${index + 1}`}</Typography>}
              onClick={() => handleChipClick(index)}
              sx={{
                borderColor: selectedScenario === index ? theme.palette.primary.main : 'transparent',
                borderWidth: selectedScenario === index ? '1px' : '0px',
                borderStyle: 'solid',
                backgroundColor: selectedScenario === index ? '#FFF3EA' : '#F5F5F5',
                color: selectedScenario === index ? theme.palette.primary.main : '#707070',
                fontWeight: selectedScenario === index ? 'bold' : 'normal',
                margin: '0 5px'
              }}
            />
          ))}
        </Box>
          <Box >
            <Icon name='delete-icon-orange-pink-bg.png' size={27}/>
          </Box>
      </Stack>
      <Box p={'20px 0'}>
        <Typography variant="bodyText3" color={theme.palette.grey[400]}>
          In case of demise(s) of{' '}
          <Typography variant="bodyText3" color={theme.palette.grey[600]}>
            Rohit Mehta & Arjun Mehta
          </Typography>
          , how would you like to distribute your assets?
        </Typography>
      </Box>
      {/* Content for the selected scenario */}
      <Box>
        {scenarios.map((scenario, index) => (
          <Box key={index} hidden={selectedScenario !== index}>
            {selectedScenario === index &&
              scenario.content.map((item, index) => {
                return <DistributionCard asset={item} key={index} />;
              })}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ScenarioTabs;
