import { Box, Typography, useTheme } from "@mui/material";
import { ReactComponent as PrimaryDistributionIcon } from 'assets/primary-distribution-icon.svg';
import { ReactComponent as SecondaryDistributionIcon } from 'assets/secondary-distribution-icon.svg';
import DistributionCardModal from "components/featureComponent/distribution/cards/DistributionCardModal";
import { useState } from "react";
import PageContainer from "views/dashboard/PageContainer";
import PrimaryDistribution from "./PrimaryDistribution";
import SecondaryDistribution from "./SecondaryDistribution";

const distributions = [
    {
        type: 'primary',
        label: 'Primary Distribution',
        icon: PrimaryDistributionIcon,
        component: () => <PrimaryDistribution />,
    },
    {
        type: 'secondary',
        label: 'Secondary Distribution',
        icon: SecondaryDistributionIcon,
        component: () => <SecondaryDistribution />,
    },
];

const DistributionPage = () => {
    const theme = useTheme();
    const [selectedDistribution, setSelectedDistribution] = useState(distributions[0].type);


    return (
        <PageContainer>
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="title1">Distribution</Typography>
                    <Typography variant="bodyText3" color={theme.palette.grey[400]} display={'inline-block'} maxWidth={'100%'} width={"44.563rem"}>
                        Who gets to inherit your assets after you are gone is an impactful and intimate choice. Take as much time as you need to make this important decision.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '15px', mt: '20px' }}>
                    {distributions.map((distribution) => (
                        <DistributionCardModal
                            key={distribution.type}
                            label={distribution.label}
                            icon={distribution.icon}
                            isSelected={selectedDistribution === distribution.type}
                            onSelect={() => setSelectedDistribution(distribution.type)}
                        />
                    ))}
                </Box>
                <Box sx={{ marginTop: 4, width: '100%' }}>
                    {distributions.find((dist) => dist.type === selectedDistribution).component()}
                </Box>
            </>
        </PageContainer>

    )
};

export default DistributionPage;
