import * as React from 'react';
import  styled  from '@emotion/styled';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Typography } from '@mui/material';

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    height: '2.5rem',
    padding: '13.5px 16px',
    minWidth:'123px',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#F5F5F5',
    color: '#707070',
    marginRight: '10px',
    textTransform: 'none',
    '&:last-child': {
        marginRight: 0,
    },
    '&.Mui-selected': {
        backgroundColor: '#FFF3EA',
        color: '#1E1C1B',
        border: '1px solid #FFA25C',
    },
    '&.Mui-selected:hover': {
        backgroundColor: '#FFD4B8',
    }
}));

const CustomToggleButtonGroup = ({ buttons, value, onChange }) => {
    return (
        <ToggleButtonGroup
            color="primary"
            value={value}
            exclusive
            onChange={onChange}
            aria-label="Platform"
            sx={{
                marginTop: '2px',
                display: 'flex',
                border: 'none',
                borderRadius: '20px',
                '& .MuiToggleButtonGroup-grouped': {
                    borderRadius: '20px',
                },
                '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                    borderRadius: '20px',
                },
                '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                    borderRadius: '20px',
                    borderLeft: 'none'
                },
                '& .MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected': {
                    borderRadius: '20px',
                    borderLeft: '1px solid #FFA25C',  // Only apply border when selected
                }
            }}
        >
            {buttons.map((button) => (
                <CustomToggleButton key={button.value} value={button.value}>
                    <Typography variant='bodyText3'>{button.label}</Typography>
                </CustomToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}

export default CustomToggleButtonGroup;
