import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  // 100: "#F9FAFB",
  100: "#C5C5C5",
  // 200: "#F4F6F8",
  200: "#F5F5F5",
  // 300: "#DFE3E8",
  300: "#888888",
  // 400: "#C4CDD5",
  400: "#707070",
  500: "#919EAB",
  600: "#1E1C1B",
  700: "#454F5B",
  800: "#212121",
  900: "#161C24"
};

const GRAPHS = [
  "#f5b59e",
  "#f7a78a",
  "#f79977",
  "#f78b63",
  "#f67c50",
  "#f46d3c",
  "#f15d27"
];

const PRIMARY = {
  lighter: "#FFF3EA",
  light: "#FFD5B5",
  main: "#F47B20",
  dark: "#FFA25C",
  darker: "#FF8C35",
  contrastText: "#fff"
};

const SECONDARY = {
  lighter: "#EFF9F9",
  light: "#B3DBDF",
  main: "#00838F",
  dark: "#78BEC4",
  darker: "#3CA1AA",
  panelColor: '#104E5B',
  contrastText: "#fff"
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff"
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#43A047",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800]
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800]
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#F51D1D",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff"
};

const palette = {
  common: { black: "#000", white: "#fff" },
  graphs: GRAPHS,
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500]
  },
  background: {
    paper: "#fff",
    default: GREY[0],
    neutral: GREY[200]
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: "rgba(255, 156, 65, 0.18)",
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
