import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, useTheme, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import regex from 'regex';
import InputField from 'components/DesignSystem/InputField';
import DateInput from 'components/DateInput';
import { Dropdown } from 'components/DesignSystem/DropDown';
import PhoneInput from 'components/PhoneInput';
import PhoneField from 'components/PhoneField';
import Icon from 'components/DesignSystem/Icon';
import { ContainedButton1, TextedButton1 } from '../../../components/DesignSystem/Button';

const PersonalDetails = ({ data }) => {
    const navigate = useNavigate();
    const theme = useTheme();


    const initialValues = {
        first_name: 'Aditya',
        middle_name: 'Milind',
        last_name: 'Herlekar',
        dob: '1993-01-01',
        marital_status: 'single',
        phone: '+91744 721 4554|',
        pan: 'AIDPH3873R'
    }

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(2, "First name must be at least 2 characters")
            .max(100, "First name cannot be more than 100 characters")
            .required("First name is required")
            .matches(regex.nameRegex, "First name can only contain letters and spaces"),
        middle_name: Yup.string()
            .min(2, "Middle name must be at least 2 characters")
            .max(100, "Middle name cannot be more than 100 characters")
            .matches(regex.nameRegex, "Middle name can only contain letters and spaces"),
        last_name: Yup.string()
            .min(2, "Last name must be at least 2 characters")
            .max(100, "Last name cannot be more than 100 characters")
            .required("Last name is required")
            .matches(regex.nameRegex, "Last name can only contain letters and spaces"),
        dob: Yup.date()
            .required('Date of Birth is required')
            .nullable()
            .typeError('Invalid date format'),
        marital_status: Yup.string()
            .required('Marital status is required'),
        phone: Yup.string()
            .required("Phone number is required")
            .test('is-phone-valid', 'Must be a valid phone number', value => {
                const phoneNumber = value;
                return phoneNumber;
            }),
        pan: Yup.string()
            .min(10, "PAN number must be exactly 10 characters")
            .required("PAN number is required")
            .matches(regex.panRegex, "PAN number is not valid")
    });



    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            console.log("Form Submitted");
            console.log("values", values);
        }
    });
    const menuItems = [
        {
            value: "single",
            label: "Single"
        },
        {
            value: "married",
            label: "Married"
        },
        {
            value: "divorced",
            label: "Divorced"
        },
        {
            value: "widowed",
            label: "Widowed"
        }
    ]

    return (
        <>
            <Box
                sx={{
                    p: 2, // Padding inside the Box
                    backgroundColor: '#F5F5F5',
                    borderRadius: '15px',
                    marginTop: '96px',
                    marginRight: '94px'
                }}
            >
                <Box display='flex' alignItems='center' justifyContent='space-between' mt='8px'>
                    <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom>
                        Personal Details
                    </Typography>
                    <Button
                        variant="outlined"
                        endIcon={<Icon name='gwd-icons-edit.svg' />}
                        sx={{
                            // width: '67px',
                            // height: '28px',
                            borderRadius: '15px',
                            padding: '3px 15px 5px 15px',
                            border: '0.7px solid #78BEC4',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '6px 6px 8px -8px #00838F4D',
                            color: '#000000',
                            textTransform: 'none',
                            '&:hover': {
                                borderColor: '#78BEC4',
                            },
                        }}
                    >
                        <Typography variant='suggestiveText' sx={{ color: '#00838F', pt: '4px' }}>Edit</Typography>
                    </Button>
                </Box>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <Grid item mr='99px' mt='38px'>
                        <Grid container spacing={2} md={12}>
                            <Grid item xs={12} sm={4} md={4}>
                                <InputField
                                    fullWidth
                                    name="first_name"
                                    placeholder="First Name*"
                                    value={formik.values.first_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4}>
                                <InputField
                                    fullWidth
                                    name="middle_name"
                                    placeholder="Middle Name"
                                    variant="outlined"
                                    value={formik.values.middle_name}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4}>
                                <InputField
                                    fullWidth
                                    name="last_name"
                                    placeholder="Last Name*"
                                    variant="outlined"
                                    value={formik.values.last_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={7} sm={12}>
                                <DateInput
                                    name="dob"
                                    value={formik.values.dob}
                                    label='Date of Birth'
                                    onChange={(date) => formik.setFieldValue('dob', date)}
                                    onBlur={() => formik.setFieldTouched('dob', true)}
                                    error={formik.errors.dob}
                                    sx={{
                                        backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                        height: '2.5rem'
                                    }}
                                />
                            </Grid>


                            <Grid item xs={12} md={12}>
                                <Grid container md={12} lg={12} spacing={1}>
                                    <Grid item xs={12} md={7}>
                                        <Dropdown
                                            name="marital_status"
                                            value={formik.values.marital_status}
                                            options={menuItems}
                                            placeholder="Marital Status*"
                                            handleChange={formik.handleChange}
                                            dropdownColor={theme.palette.common.white}
                                            dropdownHeight="40px"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item md={7}>
                                <PhoneField
                                    name="phone"
                                    value={formik.values.phone}
                                    handleChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.phone}
                                    error={formik.touched.phone && formik.errors.phone}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                    placeholder="Mobile Number*"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item md={5}>
                                <InputField
                                    fullWidth
                                    name="pan"
                                    placeholder="PAN no.*"
                                    variant="outlined"
                                    value={formik.values.pan}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.pan && Boolean(formik.errors.pan)}
                                    helperText={formik.touched.pan && formik.errors.pan}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form >
            </Box>
            <Box sx={{
                display: 'flex', justifyContent: 'flex-end', gap: '10px', mt: '16px', mr: '94px'
            }}>
                <TextedButton1
                    // type="submit"
                    disabled={formik.isSubmitting}
                    sx={{ height: '40px', width: '109px' }}
                >
                    <Typography variant='bodyText3'>
                        Cancel
                    </Typography>
                </TextedButton1>

                <ContainedButton1
                    // type="submit"
                    disabled={formik.isSubmitting}
                    sx={{ height: '40px', width: '109px' }}
                >
                    <Typography variant='bodyText3'>
                        Save
                    </Typography>
                </ContainedButton1>
            </Box>
        </>
    );
};

export default PersonalDetails;
