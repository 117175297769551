import React, { useState } from 'react';
import { Grid, ToggleButton, ToggleButtonGroup, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField, Button, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import InputField from 'components/DesignSystem/InputField';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from 'components/DesignSystem/Icon';
import { ContainedButton2 } from 'components/DesignSystem/Button';

const CustomToggleButton2 = styled(ToggleButton)(({ theme }) => ({
    height: '2.5rem',
    padding: '13.5px 16px',
    minWidth: '123px',
    borderRadius: '10px',
    border: '1px solid #3CA1AA',
    backgroundColor: 'transparent',
    color: '#00838F',
    marginRight: '10px',
    textTransform: 'none',
    '&:last-child': {
        marginRight: 0,
    },
    '&.Mui-selected': {
        backgroundColor: '#FFFFFF',
        color: '#00838F',
        border: '1px solid #3CA1AA',
    },
    '&.Mui-selected:hover': {
        backgroundColor: '#FFFFFF', // No hover effect change
    },
    '&:hover': {
        backgroundColor: '#FFFFFF', // No hover effect change
    }
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    margin: '8px 0', // Margin between each question
    borderRadius: '8px', // Border radius for AccordionSummary
    '&.Mui-expanded': {
        // If you need to adjust styles when expanded
        borderRadius: '8px',
    },
}));

// Styled Accordion to ensure no border-radius conflict
const CustomAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: '8px', // Apply border radius to Accordion itself
    marginRight: '10px',
    '&:before': {
        display: 'none', // Remove default before pseudo-element
    },
}));

const FaqSection = () => {
    const [faqType, setFaqType] = useState('general');
    const [searchQuery, setSearchQuery] = useState('');
    const [expanded, setExpanded] = useState(false);

    const handleFaqTypeChange = (event, newFaqType) => {
        setFaqType(newFaqType);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqs = [
        { question: 'Question 1', answer: 'Answer to question 1' },
        { question: 'Question 2', answer: 'Answer to question 2' },
        { question: 'Question 3', answer: 'Answer to question 3' },
        { question: 'Question 4', answer: 'Answer to question 4' },
        { question: 'Question 5', answer: 'Answer to question 5' },
        { question: 'Question 6', answer: 'Answer to question 6' },
    ];

    // Filter FAQs based on search query
    const filteredFaqs = faqs.filter(faq => faq.question.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <Grid item container xs={12} mt='15px'>
            {/* Toggle Buttons and Search Bar Row */}
            <Box display="flex" alignItems="center" width='100%' mb={2} mr='10px'>
                <ToggleButtonGroup
                    color="primary"
                    value={faqType}
                    exclusive
                    onChange={handleFaqTypeChange}
                    aria-label="FAQ and Resources"
                    sx={{
                        display: 'flex',
                        border: 'none',
                        borderRadius: '20px',
                        marginRight: '16px',
                        '& .MuiToggleButtonGroup-grouped': {
                            borderRadius: '20px',
                        },
                        '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                            borderRadius: '20px',
                        },
                        '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                            borderRadius: '20px',
                            border: '1px solid #3CA1AA',
                        },
                        '& .MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected': {
                            borderRadius: '20px',
                            borderLeft: '1px solid #3CA1AA',  // Only apply border when selected
                        }
                    }}
                >
                    <CustomToggleButton2 value="general">
                        <Typography variant='bodyText3'>General</Typography>
                    </CustomToggleButton2>
                    <CustomToggleButton2 value="services">
                        <Typography variant='bodyText3'>Services</Typography>
                    </CustomToggleButton2>
                </ToggleButtonGroup>

                {/* Search Bar */}
                {faqType === 'general' && (
                    <InputField
                        fullWidth
                        variant="outlined"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{
                            '& .MuiInputBase-root': {
                                backgroundColor: 'white',
                                height: '2.5rem',
                                padding: '13.5px 16px'
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon name="gwd-icons-search.svg" height='23px' width='23px' />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </Box>

            {/* Content Section */}
            <Box width='100%'>
                {faqType === 'general' && (
                    <Box width='100%'>
                        {/* FAQs Display */}
                        <Box>
                            {filteredFaqs.map((faq, index) => (
                                <CustomAccordion key={index} expanded={expanded === `panel${index}`} onChange={handleAccordionChange(`panel${index}`)}>
                                    <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
                                        <Typography variant='bodyText4'>{faq.question}</Typography>
                                    </CustomAccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant='bodyText4'>{faq.answer}</Typography>
                                    </AccordionDetails>
                                </CustomAccordion>
                            ))}
                        </Box>
                        <Box mt={2} sx={{mt:'181px', padding: '16px', backgroundColor: '#FFFFFF', border: '1px solid #78BEC4', borderRadius: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Stack>
                                <Typography variant="body1" color='#1E1C1B'>Would you like to talk to a legal expert?</Typography>
                                <Typography variant="suggestiveText" color='#707070'>Schedule a 30-minute call.</Typography>
                            </Stack>
                            <ContainedButton2 variant="contained" sx={{ width: '175px' }} >Book a Call</ContainedButton2>
                        </Box>
                    </Box>
                )}
                {faqType === 'services' && (
                    <Box>
                        <Typography variant="body1">This is the Services section content.</Typography>
                        {/* Add more content for Services section here */}
                    </Box>
                )}
            </Box>
        </Grid>
    );
};

export default FaqSection;
