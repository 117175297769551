import { Box, Typography, useTheme } from '@mui/material';
import { ReactComponent as EqualDistributionIcon } from 'assets/equal-distribution-icon.svg';
import { ReactComponent as EverythingToSpouseIcon } from 'assets/everything-to-spouse-icon.svg';
import { ReactComponent as PrimaryDistributionIcon } from 'assets/primary-distribution-icon.svg';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import {
    CustomDialog,
    CustomDialogActions,
    CustomDialogContent,
    CustomDialogTitle,
} from 'components/DesignSystem/CustomDialog';
import { useState } from 'react';
import SelectDistributionModalCard from './cards/SelectDistributionModalCard';

const SelectDistributionModal = ({ open, onClose }) => {
    const theme = useTheme();
    const [selectedCard, setSelectedCard] = useState(null);

    const handleCardClick = (title) => {
        if (selectedCard === title) {
            setSelectedCard(null); // Deselect if the same card is clicked
        } else {
            setSelectedCard(title); // Select the clicked card
        }
    };

    const isSaveButtonEnabled = selectedCard !== null;

    return (
        <CustomDialog open={open} onClose={onClose} maxWidth={false}>
            <CustomDialogTitle onClose={onClose}>
                <Typography variant="heading1" color={theme.palette.secondary.main}>
                    Select Distribution Method
                </Typography>
                <Typography maxWidth="628px" variant="bodyText4" color={theme.palette.grey[400]}>
                    Who gets to inherit your assets after you are gone is an impactful and intimate choice. Take as much time as
                    you need to make this important decision.
                </Typography>
            </CustomDialogTitle>
            <CustomDialogContent>
                <Box sx={{ display: 'flex', gap: '15px', margin: '25px 0' }}>
                    <SelectDistributionModalCard
                        icon={EverythingToSpouseIcon}
                        title="Give everything to spouse"
                        onClick={() => handleCardClick('give_everything_to_spouse')}
                        isSelected={selectedCard === 'give_everything_to_spouse'}
                    />
                    <SelectDistributionModalCard
                        icon={EqualDistributionIcon}
                        title={`Equal Distribution`}
                        onClick={() => handleCardClick("equal_distribution")}
                        isSelected={selectedCard === "equal_distribution"}
                    />
                    <SelectDistributionModalCard
                        icon={PrimaryDistributionIcon}
                        title="Custom Distribution"
                        onClick={() => handleCardClick("custom_distribution")}
                        isSelected={selectedCard === "custom_distribution"}
                    />
                </Box>
                <Box>
                    <Typography variant="bodyText4" fontStyle="italic" color={theme.palette.grey[400]}>
                        Don’t worry, you can change it later.
                    </Typography>
                </Box>
            </CustomDialogContent>
            <CustomDialogActions>
                <ContainedButton1
                    onClick={onClose}
                    sx={{ minHeight: '45px', minWidth: '122px' }}
                    disabled={!isSaveButtonEnabled}
                >
                    <Typography variant="bodyText3">Next</Typography>
                </ContainedButton1>
            </CustomDialogActions>
        </CustomDialog>
    );
};

export default SelectDistributionModal;
