// utils/helper/FormHelperFunctions.js
import { CountryList } from 'utils/helper/CountryList';

export const handleCountryCodeChange = (formik, event, field) => {
    const { value } = event.target;
    formik.setFieldValue(field, value);

    if (!value) {
        formik.setFieldValue(field.replace('Code', ''), null);
    } else {
        const selectedCountry = CountryList.find((country) => country.value === value);
        if (selectedCountry) {
            const countryLabel = selectedCountry.label;
            formik.setFieldValue(field.replace('Code', ''), countryLabel);
        }
    }
};

export const checkIfExists = async (values, myPeople) => {
    // Helper function to normalize string values
    const normalize = (str) => {
        if (typeof str !== 'string') {
            return ''; // Return an empty string if the value is not a string
        }
        return str.trim().toLowerCase();
    };

    // Helper function to check if a normalized value exists in a list
    const existsInList = (list, { firstName, lastName, dob }) =>
        list.some(
            (item) =>
                normalize(item.first_name) === firstName &&
                normalize(item.last_name) === lastName &&
                normalize(item.dob) === dob
        );

    // Extract lists from myPeople object
    const {
        beneNames = [],
        witnessNames = [],
        executorNames = [],
        testatorNames = [],
        beneGuardianDetails = [],
    } = myPeople;

    // Normalize input values
    const normalizedValues = {
        firstName: normalize(values.first_name),
        lastName: normalize(values.last_name),
        dob: normalize(values.dob),
    };

    // Check if the values exist in any of the lists
    const existsInBeneficiaries = existsInList(beneNames, normalizedValues);
    const existsInWitnesses = existsInList(witnessNames, normalizedValues);
    const existsInExecutors = existsInList(executorNames, normalizedValues);
    const existInTestator = existsInList(testatorNames, normalizedValues);
    const existsInGuardian = existsInList(beneGuardianDetails, normalizedValues);

    return {
        existsInBeneficiaries,
        existInTestator,
        existsInWitnesses,
        existsInExecutors,
        existsInGuardian,
    };
};


// Helper function to get formatted PANs
export const getPanList = (items, excludeId) =>
    (items ?? [])
        .filter((item) => item.id !== excludeId)
        .map((item) => item.pan?.toUpperCase() ?? '')
        .filter(Boolean);

// Helper function to get formatted names
export const getNameList = (items, excludeId) =>
    (items ?? [])
        .filter((item) => item.id !== excludeId)
        .map((item) => ({
            first_name: item.first_name,
            last_name: item.last_name,
            dob: item.dob,
        }))
        .filter(Boolean);

// Helper function to get formatted emails
export const getEmailList = (items, excludeId) =>
    (items ?? [])
        .filter((item) => item.id !== excludeId)
        .map((item) => item.email?.toUpperCase() ?? '')
        .filter(Boolean);

// Helper function to get formatted PANs
export const getphoneList = (items, excludeId) =>
    (items ?? [])
        .filter((item) => item.id !== excludeId)
        .map((item) => item.phone?.toUpperCase() ?? '')
        .filter(Boolean);
