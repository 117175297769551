// import { Box, Button } from '@mui/material';
// import { useState } from 'react';
// import './documentPreview.scss';

// const DocumentPreview = ({ documentHtml }) => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [zoomLevel, setZoomLevel] = useState(1);
//   const totalPages = 9; // Placeholder, replace with actual logic

//   const increaseZoom = () => setZoomLevel(zoomLevel + 0.1);
//   const decreaseZoom = () => setZoomLevel(zoomLevel - 0.1);

//   const handleFullscreen = () => {
//     const elem = document.getElementById('documentPreview');
//     if (elem.requestFullscreen) {
//       elem.requestFullscreen();
//     }
//   };

//   return (
//     <Box id="documentPreview" style={{ transform: `scale(${zoomLevel})`, transition: 'transform 0.3s ease' }}>
//       {/* Render the sliced HTML content based on currentPage */}
//       <Box dangerouslySetInnerHTML={{ __html: documentHtml }} />

//       <Box>
//         <Button onClick={increaseZoom}>Zoom In</Button>
//         <Button onClick={decreaseZoom}>Zoom Out</Button>
//         <Button onClick={handleFullscreen}>Fullscreen</Button>
//       </Box>
//     </Box>
//   );
// };

// export default DocumentPreview;

// import { Box, Button } from '@mui/material';
// import { useState } from 'react';
// import './documentPreview.scss';

// const DocumentPreview = ({ documentHtml }) => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [zoomLevel, setZoomLevel] = useState(1);
//   const totalPages = 9; // Placeholder, replace with actual logic

//   const increaseZoom = () => setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Limit max zoom
//   const decreaseZoom = () => setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Limit min zoom

//   const handleFullscreen = () => {
//     const elem = document.getElementById('documentPreview');
//     if (elem.requestFullscreen) {
//       elem.requestFullscreen();
//     }
//   };

//   return (
//     <Box
//       id="documentPreviewContainer"
//       sx={{
//         width: '100%',
//         height: '100vh', // Full viewport height
//         overflow: 'auto', // Enable scrolling for the container
//       }}
//     >
//       <Box
//         id="documentPreview"
//         sx={{
//           transform: `scale(${zoomLevel})`,
//           transformOrigin: '0 0', // Ensure zoom starts from the top-left corner
//           transition: 'transform 0.3s ease',
//           width: `${100 / zoomLevel}%`, // Adjust width based on zoom level
//           height: 'auto',
//         }}
//       >
//         {/* Render the sliced HTML content based on currentPage */}
//         <Box dangerouslySetInnerHTML={{ __html: documentHtml }} />
//       </Box>

//       <Box sx={{ position: 'fixed', bottom: 16, right: 16, display: 'flex', gap: '8px' }}>
//         <Button onClick={increaseZoom}>Zoom In</Button>
//         <Button onClick={decreaseZoom}>Zoom Out</Button>
//         <Button onClick={handleFullscreen}>Fullscreen</Button>
//       </Box>
//     </Box>
//   );
// };

// export default DocumentPreview;

import { Box, Button } from '@mui/material';
import { useState } from 'react';
import './documentPreview.scss';

const DocumentPreview = ({ documentHtml }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1);
  const totalPages = 9; // Placeholder, replace with actual logic

  const increaseZoom = () => setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Limit max zoom
  const decreaseZoom = () => setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Limit min zoom

  const handleFullscreen = () => {
    const elem = document.getElementById('documentPreview');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  };

  return (
    <Box
      id="documentPreviewContainer"
      sx={{
        width: '100%',
        height: '100vh', // Full viewport height for the container
        overflow: 'auto', // Enable scrolling in the container
      }}
    >
      <Box
        id="documentPreview"
        sx={{
          transform: `scale(${zoomLevel})`,
          transformOrigin: '0 0', // Start zooming from the top-left corner
          transition: 'transform 0.3s ease',
          width: '100%', // Make the content fill the available width
          height: 'auto', // Ensure height is adjusted based on content
          overflow: 'hidden', // Prevent the inner content from adding scrollbars
        }}
      >
        {/* Render the HTML content */}
        <Box dangerouslySetInnerHTML={{ __html: documentHtml }} />
      </Box>

      {/* Control buttons */}
      <Box sx={{ position: 'fixed', bottom: 16, right: 16, display: 'flex', gap: '8px' }}>
        <Button onClick={increaseZoom}>Zoom In</Button>
        <Button onClick={decreaseZoom}>Zoom Out</Button>
        <Button onClick={handleFullscreen}>Fullscreen</Button>
      </Box>
    </Box>
  );
};

export default DocumentPreview;
