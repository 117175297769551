import React from 'react';
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { TextedButton1 } from '../../../components/DesignSystem/Button';

const PurchaseHistory = ({ data }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: 2, // Padding inside the Box
        backgroundColor: '#F5F5F5',
        borderRadius: '15px',
        marginTop: '96px',
        marginRight: '94px',
      }}
    >
      <Box
        sx={{
          mb: 0, // Margin bottom
        }}
      >
        <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom>
          Purchase History
        </Typography>
      </Box>
      <Table sx={{ width: '100%', borderCollapse: 'collapse', borderSpacing: 0 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="bodyText3">Order ID</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyText3">Service</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyText3">Date</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyText3">Invoice</Typography>
            </TableCell>
          </TableRow>
          {/* Divider Row */}
          <TableRow>
            <TableCell colSpan={4}>
              <Divider
                sx={{
                  my: 0, // Remove vertical margins
                  borderColor: 'white', // Color of the divider
                  borderWidth: '1px', // Thickness of the divider
                  borderStyle: 'solid', // Style of the border
                  margin: 0, // Ensure there's no margin applied
                  padding: 0, // Ensure there's no padding applied
                  height: '1px', // Ensure the height of the divider is just a line
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((purchase, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>
                  <Typography variant="bodyText3">{purchase.orderId}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyText3">{purchase.service}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyText3">{purchase.date}</Typography>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      border: (theme) => `1px solid ${theme.palette.primary.light}`, // Border for the box
                      borderRadius: '10px', // Optional: add border radius to the box
                      display: 'inline-block', // Optional: ensure the box fits around the button
                    }}
                  >
                    <TextedButton1
                      variant="contained"
                      sx={{
                        width: '71px', // Set your desired width here
                        height: '29px',
                        border: 'none', // Remove any default border if needed
                      }}
                    >
                      <Typography variant="bodyText3">{purchase.invoice}</Typography>
                    </TextedButton1>
                  </Box>
                </TableCell>
              </TableRow>
              {index < data.length - 1 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Divider
                      sx={{
                        my: 0, // Remove vertical margins
                        borderColor: 'white', // Color of the divider
                        borderWidth: '1px', // Thickness of the divider
                        borderStyle: 'solid', // Style of the border
                        margin: 0, // Ensure there's no margin applied
                        padding: 0, // Ensure there's no padding applied
                        height: '1px', // Ensure the height of the divider is just a line
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PurchaseHistory;
