import { Box, Button, Popper, Typography, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import 'react-phone-input-material-ui/lib/style.css';
// import 'react-phone-input-material-ui/lib/high-res.css';
import rawCountries from 'utils/rawCountries';
import { CustomAutocomplete } from './DesignSystem/AutoComplete/CustomAutocomplete';
import InputField from './DesignSystem/InputField';

const countries = rawCountries.map((c) => ({ country: c[0], code: c[2], dial: c[3] })).filter((c) => !!c.dial);

const PhoneField = ({
    name,
    value,
    handleChange,
    onBlur,
    error,
    helperText,
    fullWidth = true,
    required = false,
    type = 'number',
    disabled = false,
    placeholder,
    sx,
}) => {
    const [val, setVal] = useState(value);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const [country, selectCountry] = useState({
        country: 'India',
        code: 'in',
        dial: '91',
    });

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false)
            setAnchorEl(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    // Synchronize local state with the value prop
    useEffect(() => {
        setVal(value ? value.split(':')[2] : '');
        const code = value ? value.split(':')[0] : 'in';
        selectCountry(
            countries.find((c) => c.code === code) ?? {
                country: 'India',
                code: 'in',
                dial: '91',
            }
        );
    }, [value]);

    const toggleMenu = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen((o) => !o);
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const phone = inputValue ? `${country.code}:${country.dial}:${inputValue}` : '';
        setVal(inputValue);
        handleChange({ target: { name, value: phone } });
    };

    return (
        <Box className={'react-tel-input'} sx={{ width: '100%', input: { pl: '0!important', }, '.flag': { pl: 0 }, ...sx }}>
            <InputField
                id={name}
                name={name}
                value={val}
                onChange={handleInputChange}
                onBlur={onBlur}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                variant="outlined"
                fullWidth={fullWidth}
                error={error}
                helperText={helperText}
                // sx={{
                //     '& .MuiInputBase-root': {
                //         padding: '6.8px 16px', // Adjust padding here (Top/Bottom, Left/Right)
                //     },
                // }}
                InputProps={{
                    startAdornment: (
                        <Box>
                            <Button
                                disabled={disabled}
                                size={'small'}
                                startIcon={<div className={`flag ${country.code}`} />}
                                variant={'text'}
                                sx={{ color: 'black', padding: 0, paddingRight: '16px' }}
                                onClick={toggleMenu}
                            >
                                <Typography variant="bodyText3" color={theme.palette.grey[600]} >
                                    +{country.dial}
                                </Typography>
                            </Button>

                        </Box>
                    ),
                }}
            />
            <Popper sx={{ zIndex: 1301 }} placement={'auto'} open={open} anchorEl={anchorEl} ref={dropdownRef}>
                <Box sx={{ width: 300, background: 'white' }}>
                    <CustomAutocomplete
                        open
                        placeholder='Search'
                        getOptionLabel={(option) => option.country}
                        value={country}
                        onChange={(e, v) => {
                            selectCountry(
                                v ?? {
                                    country: 'India',
                                    code: 'in',
                                    dial: '91',
                                }
                            );
                            const updatedValue = `${v?.code}:${v?.dial}:${val}`;
                            setVal(val); // Update local state to reflect the change
                            handleChange({ target: { name, value: updatedValue } });
                            toggleMenu(e);
                        }}
                        options={countries}
                    />
                </Box>
            </Popper>
        </Box>
    );
};

export default PhoneField;
