import { Box, Typography, useTheme } from '@mui/material';
import PageContainer from 'views/dashboard/PageContainer';
import DocumentPreview from './DocumentPreview';
import { dummyHtml } from './dummyHtml';
import WillGenerationChecklist from './WillGenerationChecklist';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const MyWill = () => {
    // const [isLoading, setLoading] = useState(true);
    const theme = useTheme();

    return (
        <PageContainer>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="title1">My Will</Typography>
                <Typography variant="bodyText3" color={theme.palette.grey[400]} display={'block'} width={"70%"}>
                    This Preview shows you how all your details will appear in the final Will. Use the Checklist as a guide of progress towards readying and Generating your Will.
                </Typography>
                <Box sx={{ display: 'flex', height: '100%' }}>
                    <Box sx={{ width: '50%',height: '660px', border: '1px solid #C5C5C5', borderRadius: '20px', overflowX: 'auto'}}>
                        <DocumentPreview documentHtml={dummyHtml} />
                    </Box>
                    <Box sx={{ flex: 1, }}>
                        <WillGenerationChecklist />
                    </Box>
                </Box>
            </Box>
        </PageContainer>
    );
};

export default MyWill;