import { Box, IconButton, InputAdornment, TextField, Typography, styled, useTheme } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as VisibilityOff } from "../../../assets/password-invisible.svg";
import { ReactComponent as Visibility } from "../../../assets/password-visible.svg";


const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    backgroundColor: theme.palette.grey[200], // Set the background color
    padding: '10px 16px', // Adjust padding here (Top/Bottom, Left/Right)
    '&.MuiOutlinedInput-notchedOutline': {
      border: 'none', // No border in the static phase
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent', // No border in the static phase
    },
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
    '&.Mui-error fieldset': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'DM Sans, sans-serif',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '27px',
    color: theme.palette.text.primary,
    padding: 0,
    caretColor: theme.palette.grey[300],
  },
  '& .MuiInputBase-input::placeholder': {
    fontStyle: 'italic',
    // color: theme.palette.grey[300],
    color: theme.palette.common.black,
    opacity: 0.6,
  },
  '& .MuiFormLabel-root': {
    fontFamily: 'DM Sans, sans-serif',
    fontWeight: 400,
    fontSize: '18px',
    fontStyle: 'italic',
    lineHeight: '27px',
    color: theme.palette.text.secondary,
    top: '-2px',
    left: '16px',
    transformOrigin: 'top left',
    transform: 'translate(0, 12px) scale(1)',
  },
  '& .MuiFormHelperText-root': {
    alignSelf: 'end',
    fontFamily: 'DM Sans, sans-serif',
    fontStyle: 'italic'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.grey[300],
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: theme.palette.error.main,
  },
  // Customizing the browser's autocomplete suggestions
  '& input:-webkit-autofill': {
    boxShadow: `0 0 0 100px ${theme.palette.grey[200]} inset`,
  },
}));
const InputField = ({ label, error, type, bottomText, ...props }) => {
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  return (
    <Box position='relative'>
      <CustomTextField
        label={label}
        error={error}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        variant="outlined"
        InputLabelProps={{ shrink: false }} // This keeps the label floated above the input
        InputProps={{
          endAdornment: type === 'password' && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
      {bottomText &&
        <Typography color={theme.palette.grey[400]} variant='suggestiveText'
          sx={{
            position: 'absolute',
            top: '100%',
            right: 0,
            marginTop: '2px',
            display: 'block',
            fontStyle: 'italic',
          }}
        >
          {bottomText}
        </Typography>
      }
    </Box>
  );
};

export default InputField;
