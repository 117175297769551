import { Box, Button, Typography, useTheme } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';

const GwdImageContentCard = () => {   // TODO: To pass the props from parent
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: '1px solid #E0E0E0',
        borderRadius: '15px',
        overflow: 'hidden',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        mb: '30px',
        position: 'relative',
      }}
    >
      <Box>
        <Icon name='make-your-own-will-img.png' width={'100%'} height={'100%'} />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          padding: '21px 20px 24px',
          color: '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'absolute',
          bottom: 0,
          width: '100%'
        }}
      >
        <Box>
          <Typography variant="smallText" sx={{ display: 'flex', alignItems: 'center', mb: 1 }} gutterBottom={false}>
            <Icon name='blue-tick-icon.png' style={{ marginRight: '5px'}}/>Create & generate your Will easily & hassle-free
          </Typography>
          <Typography variant="smallText" sx={{ display: 'flex', alignItems: 'center', }} gutterBottom={false}>
            <Icon name='blue-tick-icon.png' style={{ marginRight: '5px'}}/>Make any changes you need to your Will for 12 months
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
          <Typography variant="bodyText4" >
            ₹7,999
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#fff',
              textTransform: 'capitalize',
              borderRadius: '10px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: theme.palette.common.white,
              },
            }}
          >
            <Typography variant='bodyText3' color={theme.palette.secondary.main}>
              Added
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GwdImageContentCard;
