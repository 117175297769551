import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup';
import { Dropdown } from 'components/DesignSystem/DropDown';
import InputField from 'components/DesignSystem/InputField';
import FileUpload from 'components/fileUpload';
import DateInput from 'components/DateInput';
import { assetTypes } from 'utils/helper/AssetTypes';
import { assetSubTypes } from 'utils/helper/AssetSubTypes';

const AddAsset = ({ assetCategory, editId, setSelectedSubType }) => {
    const [type, setType] = useState();
    const [subType, setSubType] = useState('');

    // Extract asset types based on assetCategory
    const buttons = assetTypes[assetCategory] || [];
    // Handle change in toggle button group
    const handleChange = (event, newValue) => {
        setType(newValue);
        setSubType(''); // Reset subType when type changes
        setSelectedSubType(null); // Reset subType when type changes
    };

    const menuItems = assetSubTypes[type] || [];
    const selectedSubType = menuItems.find((item) => item.value === subType);
    const inputFields = selectedSubType?.inputFields || [];

    // Define initial values and validation schema based on inputFields
    const [initialValues, setInitialValues] = useState({});
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));

    // Helper function to remove the asterisk from placeholder
    const removeAsterisk = (placeholder) => placeholder.replace('*', '');

    useEffect(() => {
        // Set initial values dynamically based on the selected subtype
        const newInitialValues = inputFields.reduce((acc, field) => {
            acc[field.name] = '';
            return acc;
        }, {});
        setInitialValues(newInitialValues);

        // Set validation schema dynamically based on the selected subtype
        const newValidationSchema = Yup.object().shape(
            inputFields.reduce((acc, field) => {
                const isRequired = field.placeholder.includes('*');
                const cleanPlaceholder = removeAsterisk(field.placeholder); // Remove asterisk
                const fieldValidation =
                    field.type === 'number'
                        ? Yup.number().typeError(`${cleanPlaceholder} must be a number`).nullable()
                        : Yup.string().nullable();

                if (isRequired) {
                    acc[field.name] =
                        field.type === 'number'
                            ? fieldValidation.required(`${cleanPlaceholder} is required`)
                            : fieldValidation.required(`${cleanPlaceholder} is required`);
                } else {
                    acc[field.name] = fieldValidation;
                }

                return acc;
            }, {})
        );
        // console.log("newValidationSchema", newValidationSchema)
        setValidationSchema(newValidationSchema);
    }, [subType, selectedSubType]);

    useEffect(() => {
        if (editId) {
            // const data = myPeopleData?.beneficiaries.find((b) => b.id === editId) || {};
            // setBeneficiaryType(data.type || 'person'); // Set beneficiary type if data.type exists
            // formik.setValues(data); // Populate form with fetched data
            // setInitialLoading(false); // Set loading to false
        } else {
            // setInitialLoading(false); // For new entries, no need to fetch data
        }
    }, [editId]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            console.log('Form Submitted');
            console.log('values', values);
        },
    });

    return (
        <>
            <Grid item container md={12}>
                <CustomToggleButtonGroup buttons={buttons} value={type} onChange={handleChange} />
            </Grid>

            <Grid item>
                <form>
                    <Grid item container spacing={2} xs={12} md={6} mt={1}>
                        <Grid item xs={12} md={12}>
                            <Dropdown
                                name="subType"
                                options={menuItems}
                                placeholder="Select Type"
                                values={formik.values.subType}
                                handleChange={(e) => {
                                    formik.handleChange(e);
                                    setSubType(e.target.value);
                                    setSelectedSubType(e.target.value);
                                }}
                            />
                        </Grid>

                        {/* Render additional input fields based on selected subtype */}
                        {inputFields.map((field) => (
                            <Grid item xs={12} key={field.name}>
                                {field.type === 'file' ? (
                                    <FileUpload
                                        name={field.name}
                                        label={field.placeholder}
                                        accept="image/png,image/jpeg" // Accept only PNG and JPEG/JPG files
                                        maxFileSize={2097152}
                                        fileTypeText="Supports file up to 2MB"
                                        // fileTypeText="Supports file up to 2MB (jpeg/jpg/png)"
                                        value={formik.values[field.name]}
                                        onChange={(file) => formik.setFieldValue(field.name, file)}
                                        error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                                        helperText={formik.touched[field.name] && formik.errors[field.name]}
                                    />
                                ) : field.type === 'date' ? (
                                    <DateInput
                                        name={field.name}
                                        value={formik.values.signing_date}
                                        label={field.placeholder}
                                        // onChange={value => { formik.values[field.name] }}
                                        error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                                        helperText={formik.touched[field.name] && formik.errors[field.name]}
                                    />
                                ) : (
                                    <InputField
                                        name={field.name}
                                        type={field.type}
                                        value={formik.values[field.name]}
                                        placeholder={field.placeholder}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                                        helperText={formik.touched[field.name] && formik.errors[field.name]}
                                        fullWidth
                                        multiline={field.multiline}
                                        rows={field.rows}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </form>
            </Grid>
        </>
    );
};
export default AddAsset;
