import { Box, Chip, Typography, useTheme } from '@mui/material';
import FilterComponent from 'components/commonComponents/FilterComponent';
import SelectDistributionModal from 'components/featureComponent/distribution/SelectDistributionModal';
import { useState } from 'react';
import DistributionCard from './DistributionCard';

const PrimaryDistribution = () => {
    const theme = useTheme();
    const [selectDistributionModalOpen, setSelectDistributionModalOpen] = useState(false);

    const handleSelectDistributionDialogOpen = () => {
        setSelectDistributionModalOpen(true);
    };

    const handleOnClose = () => {
        setSelectDistributionModalOpen(false);
    };
    return (
        <Box paddingRight='75px'>
            <FilterComponent title="Primary Distribution" />
            <Box textAlign="end" paddingTop='15px' paddingBottom='17px'>
                <Chip
                    clickable
                    sx={{ maxHeight: '24px' }}
                    onClick={handleSelectDistributionDialogOpen}
                    label={
                        <Typography color={theme.palette.primary.main} variant="smallText" sx={{ fontSize: '13px !important' }}>
                            Change Distribution Method
                        </Typography>
                    }
                />
            </Box>
            <Box>
                {
                    assetData.map((item, index) => {
                        return <DistributionCard asset={item} key={index}/>
                    })
                }
            </Box>
            <SelectDistributionModal open={selectDistributionModalOpen} onClose={handleOnClose} />
        </Box>
    );
};

export default PrimaryDistribution;

const assetData = [{
    name: 'Property 01',
    description: 'Commercial Property • Office',
    type: 'property', // or 'pet'
    beneficiaries: [
        { name: 'Ananya Chakraborty', percentage: '' },
        { name: 'Saurav Chakraborty', percentage: '' },
        { name: 'Meera Chakraborty', percentage: '' },
        { name: 'Vikram Mehta', percentage: '' },
        { name: 'Pushkar Sahu', percentage: '' },
        { name: 'Om Anand', percentage: '' },
    ],
},
{
    name: 'Pet',
    description: 'Dog',
    type: 'pet', // or 'pet'
    beneficiaries: [
        { name: 'Ananya Chakraborty', percentage: '' },
        { name: 'Saurav Chakraborty', percentage: '' },
        { name: 'Meera Chakraborty', percentage: '' },
        { name: 'Vikram Mehta', percentage: '' },
        { name: 'Pushkar Sahu', percentage: '' },
        { name: 'Om Anand', percentage: '' },
    ],
},];