

import { Box, Card as MuiCard, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(MuiCard)(({ theme, isSelected }) => ({
    padding: '8px 16px',
    maxWidth: '300px',
    borderRadius: '10px',
    backgroundColor: isSelected ? '#FFF3EA' : '#F7F7F7',
    border: isSelected ? '1px solid #FFD5B5' : '1px solid transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s, border 0.3s',
    boxShadow: 'none',
}));

const CreateDistributionCard = ({ name, isSelected, onSelect }) => {
    const theme = useTheme()
    return (
        <StyledCard isSelected={isSelected} onClick={onSelect}>
            <Typography variant='bodyText3' color={theme.palette.grey[600]}>{name}</Typography>
        </StyledCard>
    );
};

export default CreateDistributionCard;
