export const dummyHtml = `<!doctype html>
<html class="no-js" lang="">

<head>
    <meta charset="utf-8">
    <meta name="theme-color" content="#fafafa">
    <style>
        table {
            border: 1px solid black;
            width: 99%;
            table-layout: fixed;
            border-collapse: collapse;
            page-break-inside: auto
        }

        tr {
            page-break-inside: avoid;
            page-break-after: auto
        }


        td p {
            margin: 0
        }

        td {
            vertical-align: top
        }

        th,
        td {
            padding-left: 1mm;
            font-size: 10pt;
            border: 1px solid black;
            white-space: break-spaces;
            word-break: break-word;
            word-wrap: break-word;
        }

        thead {
            display: table-header-group;
            font-weight: bold;
        }

        tfoot {
            display: table-footer-group
        }

       

        .page.landscape {
            min-height: 185mm;
            width: 272mm;
            page: landscape;
        }

        .page.center {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            justify-items: center;
        }

        .page * {
            color: #000;
            font-family: "Calibri", sans-serif;
        }


        .text-center {
            text-align: center;
        }

        h1 {
            text-align: center;
            font-size: 26pt;
        }

        h2 {
            text-align: center;
            font-size: 11pt;
        }

        h2.large {
            font-size: 14pt;
        }

        h3,
        h4,
        div {
            font-size: 11pt;
        }

        ol,
        ul {
            counter-reset: list;
        }

        ol:not(.plain)>li,
        ul:not(.plain)>li,
        ol:not(.plain)[inlist=true]>li,
        ul:not(.plain)[inlist=true]>li {
            list-style: none;
        }

        ol>li:before,
        ul>li:before {
            content: "(" counter(list, lower-alpha) ") ";
            counter-increment: list;
        }

        ol[inlist=true]>li:before,
        ul[inlist=true]>li:before {
            content: "(" counter(list, lower-roman) ") ";
            counter-increment: list;
        }

        ol.plain>li:before,
        ul.plain>li:before {
            content: "";
        }

        p,
        li {
            text-align: justify;
            font-size: 11pt;
        }

        li:first-child {
            margin-top: 1rem;
        }

        li:not(:last-child) {
            margin-bottom: 1rem;
        }

        .highlight {
            /*background: yellow;*/
        }

        body {
            margin: 0;
        }

        .photograph {
            height: 4.5cm;
            width: 3.5cm;
            border: 1px solid black;
            text-align: center;
            color: #bbb;
            font-weight: normal;
        }

        .photograph.right {
            position: relative;
            right: 2.5cm;
            margin: 8px 8px 8px auto;
        }

        .testator-photo-box {
            position: relative;
        }

        .testator-photo-box .label {
            position: absolute;
            top: 0;
        }

        .page-number {
            float: right;
        }

        .plain li {
            position: relative;
            border-bottom: 1px dashed lightgray;
        }
    </style>
</head>

<body class="print">
    <div class="page">
        <div class="center">
            <h1 class="text-center">WILL AND TESTAMENT <br /> <br />OF<br /> <br /><span>
                    MR. RUTURAJ  PANDIT</span></h1>
        </div>
        <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 1 of 6</p>
    </div>
    <div class="page">
        <h2>Contents</h2>
        <ol class="plain">
            <li>PRELIMINARY DECLARATION<span class="page-number"></span></li>
            <li>BENEFICIARIES UNDER THIS WILL<span class="page-number"></span></li>
            <li>ASSETS AND DEVICES FOR WHICH THIS WILL IS EXECUTED<span class="page-number"></span></li>
            <li>DISTRIBUTION OF PROPERTIES<span class="page-number"></span></li>
            <li>EXECUTOR AND ADMINISTRATIVE POWERS<span class="page-number"></span></li>
            <li>OPERATION OF THE WILL<span class="page-number"></span></li>
            <li>MISCELLANEOUS PROVISIONS<span class="page-number"></span></li>

            <li style="list-style-type: none">Annexure A<span class="page-number"></span>
            </li>
            <li style="list-style-type: none">List of Beneficiaries<span class="page-number"></span>
            </li>
            <li style="list-style-type: none">Annexure B<span class="page-number"></span>
            </li>
             <li style="list-style-type: none"><ol type="A"><li>primary distribution<ol type="a"><li>Details of Movable Properties<ol type="i"></ol></li><li>Details of Residual Assets</ol></li></ol></li>

            <li style="list-style-type: none">Annexure E<span class="page-number"></span>
            </li>

        </ol>

        <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 2 of 6</p>
    </div>

    <!-- Add your site or application content here -->

    <!--  Content  -->

    <div class="page">
        <h2 class="large">WILL AND TESTAMENT</h2>
        <h3>1. PRELIMINARY DECLARATION</h3>
        <p>I, Mr Ruturaj Pandit, born on January 10, 1961, holding PAN No. CQNQP0564J,
            residing
            at gfds, .sdfgfd,  Panchale, Nashik - 422103, Maharashtra, India, make
            my Will as under:</p>

        <p>I am well versed and conversant with English Language.</p>

        <p>            I state that I have not made any Will in the past. I hereby expressly revoke any writings, notation made
            by me (if
            any) and declare that they have no legal implication whatsoever and I further hereby declare the present
            to be my
            last Will.
        </p>

        <p>I declare that at the time of making this Will, I am in good physical health, sound mind and have a
            proper
            understanding of the contents of this Will.</p>

        <p>My doctor’s certificate is attached to this Will (as Annexure D) certifying that I am in good physical health,
            sound mind and good understanding at the time of signing this Will.</p>

        <p>This Will is made by me of my own independent decision and free choice, and I have not been influenced,
            cajoled,
            forced or pressurized by anyone in any manner whatsoever to make this Will.</p>

        <p>I do not wish to cause difficulties to my Beneficiaries by leaving this material world without making a
            Will and I
            wish to ensure peaceful and just distribution of my Properties amongst my Beneficiaries. Hence, I have
            decided to devise and bequeath all my moveable and immoveable properties more particularly described in
            Annexure A
            (collectively referred to as the “Properties”) under this Will.</p>

        <p>All the Properties owned by me are either separate property, self-acquired property or is my share in
            the ancestral property and is capable of being passed on to my Beneficiaries under this Will. I have
            specifically included details of the joint holder (if any) for such Properties which are jointly held by
            me with another person in Annexure B. </p>

        <p>I have full right, absolute power, and complete authority to bequeath (i) the Properties, or any other
            property which may be substituted in place of the mentioned Properties (ii) properties
            which may be acquired or received by me after making this Will.</p>

        <p>It is my intention to repay the loans and mortgages, if any, created in connection with the Properties
            in my foreseeable future or lifetime. Should any debt or obligations remain unpaid, the same shall be
            paid in the manner as mentioned under this Will before distributing my Properties amongst my Beneficiaries.
        </p>

        <h3>2. BENEFICIARIES UNDER THIS WILL</h3>
        <p>I state that the people listed in Annexure A shall be my beneficiaries (hereinafter referred to as my
            “Beneficiaries” and independently as “Beneficiary”) in respect of the Properties owned or acquired by me on
            such date this Will becomes effective. No other person shall have any claims in respect of the Properties
            owned or acquired by me.</p>

        <h3>3. ASSETS AND DEVICES FOR WHICH THIS WILL IS EXECUTED</h3>

        <p>As of today, I am owner of the following Properties:</p>
        <ol type="a">
        </ol>
        <p>I have listed the details of the aforesaid Immovable Properties and Movable Properties in Annexure B to
            this Will,
            which I would like to consider for this Will.</p>

        <h3>4. DISTRIBUTION OF PROPERTIES</h3>

        <p>I bequeath the Properties to my Beneficiaries / Alternative Beneficiaries as under:</p>
        <ol type="a">
            <li>It is my wish that after I pass away all my rights, title, and interest in the Properties mentioned
                in this Will
                to be bequeathed in manner as mentioned in the Annexure B.
            </li>
            <li>If there is any enhancement, accretion, depletion, alteration, or modification to any of my Properties
                in this Will then the allocation method provided in this clause shall apply as-is to such enhanced,
                accreted, altered, or modified assets or property. The Properties mentioned under this Will, or such
                properties which are not explicitly mentioned under this Will but are acquired by me / stand in my name
                after making this Will, shall be bequeathed to my Beneficiaries / Alternative Beneficiaries in the
                manner as mentioned in this Will and no other person will have any right/ title/ interest in such
                Properties.
            </li>

            </li>


        </ol>

        <h3>5. EXECUTOR AND ADMINISTRATIVE POWERS</h3>

        <ol type="a">
            <li>
                To ensure timely and appropriate execution of my Will, I appoint the people listed in AnnexureC to jointly and
                severally to act as the executors of my Will (“Executor/s”). The Executors are ready and willing to
                carry
                out all responsibilities and duties as the Executors under this Will and accordingly have recorded
                their
                affirmation by signing on Annexure D.
            </li>
            <li>Any provision of the Properties made in favour of the Beneficiaries under this Will shall be subject
                to making
                provisions for my funeral and last rites and after clearing my liabilities and debts, if any.
            </li>
            <li>In addition to the Executor’s power as per law, I confer all such rights on the Executor/s to enable
                the
                Executor/s to carry on the proper administration and appurtenant duties relating to distribution of
                the Properties
                and to do all such acts which are in the best interest of my wishes as per this Will.
            </li>
            <li>
                The Executor/s shall have authority to engage the services of accountants and other advisors as they
                may deem
                necessary to assists with the execution of this Will and to pay reasonable compensation for their
                services from
                my estate in consultation with the Beneficiaries.
            </li>
            <li>
                The Executor/s shall also be entrusted to do the following:
                <ol inlist="true" type="i">
                    <li>To ascertain the Properties after my demise.</li>
                    <li>
                        To collect the debts owed to me in collaboration of the wishes of Beneficiaries.
                    </li>
                    <li>To apply for a Probate, whenever necessary on behalf of my Beneficiaries.</li>
                </ol>
            </li>
            <li>After my demise, my Executor/s shall forthwith make a list of my existing assets and liabilities.
                Till the
                time the Properties mentioned in this Will and acquired by me during my lifetime, are disposed of
                after paying all
                the liabilities, the Executor/s shall give an annual, true, and correct account of all the assets
                and liabilities,
                to the Beneficiaries.
            </li>

            <li>
                Before distributing the Properties to the Beneficiaries, the Executors shall have a right to dispose
                / sell of the
                Properties as required
                <ol inlist="true" type="i">
                    <li>to pay the outstanding loans including dispose / sell of the specific Properties against
                        which loans have
                        been taken by me.
                    </li>
                    <li>for any other reason to give effect to this Will, in consultation with the Beneficiaries.
                    </li>
                </ol>
            </li>
        </ol>

        <h3>6. OPERATION OF THE WILL</h3>

        <ol type="a">
            <li>This Will shall become operative immediately upon my death and shall have no effect during my
                lifetime.</li>
            <li>On this Will becoming operative, the Beneficiaries are free to enjoy all the rights attached to the
                Properties.
            </li>
        </ol>
        </br>
        <h3>7. MISCELLANEOUS PROVISIONS</h3>

        <ol type="a">
            <li>I have not entered into a contract to make “Will” or in a contract to revoke Will. Any similarity of
                the
                provisions of my Will to the provisions of the Will of my spouse or any other person, if any
                executed on the same
                or different dates than my Will shall not be construed as evidence of such a contract.
            </li>
            <li>Words signifying one gender shall include the others and words signifying the singular shall include
                the plural
                and vice versa where appropriate.
            </li>
            <li>If any of the provisions of this Will are deemed unenforceable, the remaining provisions shall
                remain in full
                force and effect.
            </li>
            <li>
                I have kept one copy of this Will with each of my Executor/s.
            </li>

          
            </li>
        </ol>
        <p>I hope my Beneficiaries accept the Properties bequeathed to them as a small token of love and regard for them
            and give my best wishes to them for a future prosperous life. In the event any Beneficiary rejects or
            refuses to accept any of the bequest made under this Will, the Beneficiary identified as an alternative
            Beneficiary in Annexures below shall be entitled to the identified Property/ies.
        </p>

        <p style="margin-top: 1cm; text-align: center; font-weight: bold">[This space is intentionally left blank]
        </p>
        <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 3 of 6</p>
    </div>
<!doctype html>
<html class="no-js" lang="">

    <head>
        <meta charset="utf-8">
        <meta name="theme-color" content="#fafafa">
        <style>
            @page {
                size: A4;
            }

            @page landscape {
                size: A4 landscape;
            }

            @media screen {
                .page {
                    padding: 2.5cm;
                    margin: 0 auto;
                    border: 1px solid black;
                }
            }

            table {
                border: 1px solid black;
                width: 99%;
                table-layout: fixed;
                border-collapse: collapse;
                page-break-inside: auto
            }

            tr {
                page-break-inside: avoid;
                page-break-after: auto
            }


            td p {
                margin: 0
            }

            td {
                vertical-align: top
            }

            th,
            td {
                padding-left: 1mm;
                font-size: 10pt;
                border: 1px solid black;
                white-space: break-spaces;
                word-break: break-word;
                word-wrap: break-word;
            }

            thead {
                display: table-header-group;
                font-weight: bold;
            }

            tfoot {
                display: table-footer-group
            }

            .page {
                page-break-after: always;
                page-break-before: always;
                page-break-inside: avoid;
                user-select: none;
            }

            .page.landscape {
                page: landscape;
            }

            .page.center {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;
            }

            .page * {
                color: #000;
                font-family: "Calibri", sans-serif;
            }

            .print .thumbnail {
                max-width: 80px;
            }

            .text-center {
                text-align: center;
            }

            h2 {
                text-align: center;
                font-size: 11pt;
            }

            h3,
            h4,
            div {
                font-size: 11pt;
            }

            ol,
            ul {
                counter-reset: list;
            }

            ol:not(.plain)>li,
            ul:not(.plain)>li,
            ol:not(.plain)[inlist=true]>li,
            ul:not(.plain)[inlist=true]>li {
                list-style: none;
            }

            ol>li:before,
            ul>li:before {
                content: "(" counter(list, lower-alpha) ") ";
                counter-increment: list;
            }

            ol[inlist=true]>li:before,
            ul[inlist=true]>li:before {
                content: "(" counter(list, lower-roman) ") ";
                counter-increment: list;
            }

            ol.plain>li:before,
            ul.plain>li:before {
                content: "";
            }

            p,
            li {
                text-align: justify;
                font-size: 11pt;
            }

            li:first-child {
                margin-top: 1rem;
            }

            li:not(:last-child) {
                margin-bottom: 1rem;
            }

            .highlight {
                /*background: yellow;*/
            }

            body {
                margin: 0;
            }

            .photograph {
                height: 4.5cm;
                width: 3.5cm;
                border: 1px solid black;
                text-align: center;
                color: #bbb;
                font-weight: normal;
            }

            .photograph.right {
                position: relative;
                right: 2.5cm;
                margin: 8px 8px 8px auto;
            }

            .testator-photo-box {
                position: relative;
            }

            .testator-photo-box .label {
                position: absolute;
                top: 0;
            }
        </style>
    </head>

    <body class="print">

        <!-- Add your site or application content here -->

        <!--  Content  -->
        <div class="page">
            <h2>Annexure A</h2>
            <h3 class="text-center">Beneficiary / List of Beneficiaries</h3>
            <div class="table-container"><table>
            <thead><tr><th align="center" style="border: 1px solid black; padding: 8px;" width="90">Name of the beneficiary</th><th align="center" style="border: 1px solid black; padding: 8px;" width="80">Relationship</th><th align="center" style="border: 1px solid black; padding: 8px;" width="70">Date of Birth</th><th align="center" style="border: 1px solid black; padding: 8px;" width="70">Contact details</th><th align="center" style="border: 1px solid black; padding: 8px;" width="90">PAN</th></tr></thead>
            <tbody></tbody>
          </table></div>
            <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 4 of 6</p>
        </div>
    </body><!doctype html>
<html class="no-js" lang="">

    <head>
        <meta charset="utf-8">
        <meta name="theme-color" content="#fafafa">
        <style>
            @page {
                size: A4;
            }

            @page landscape {
                size: A4 landscape;
            }

            @media screen {
                .page {
                    padding: 2.5cm;
                    margin: 0 auto;
                    border: 1px solid black;
                }
            }

            table {
                border: 1px solid black;
                width: 99%;
                table-layout: fixed;
                border-collapse: collapse;
                page-break-inside: auto
            }

            tr {
                page-break-inside: avoid;
                page-break-after: auto
            }


            td p {
                margin: 0
            }

            td {
                vertical-align: top
            }

            th,
            td {
                padding-left: 1mm;
                font-size: 10pt;
                border: 1px solid black;
                white-space: break-spaces;
                word-break: break-word;
                word-wrap: break-word;
            }

            thead {
                display: table-header-group;
                font-weight: bold;
            }

            tfoot {
                display: table-footer-group
            }

            .page {
                page-break-after: always;
                page-break-before: always;
                page-break-inside: avoid;
                user-select: none;
            }

            .page.landscape {
                page: landscape;
            }

            .page.center {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;
            }

            .page * {
                color: #000;
                font-family: "Calibri", sans-serif;
            }

            .print .thumbnail {
                max-width: 80px;
            }

            .text-center {
                text-align: center;
            }

            h2 {
                text-align: center;
                font-size: 11pt;
            }

            h3,
            h4,
            div {
                font-size: 11pt;
            }

            ol,
            ul {
                counter-reset: list;
            }

            ol:not(.plain)>li,
            ul:not(.plain)>li,
            ol:not(.plain)[inlist=true]>li,
            ul:not(.plain)[inlist=true]>li {
                list-style: none;
            }

            ol>li:before,
            ul>li:before {
                content: "(" counter(list, lower-alpha) ") ";
                counter-increment: list;
            }

            ol[inlist=true]>li:before,
            ul[inlist=true]>li:before {
                content: "(" counter(list, lower-roman) ") ";
                counter-increment: list;
            }

            ol.plain>li:before,
            ul.plain>li:before {
                content: "";
            }

            p,
            li {
                text-align: justify;
                font-size: 11pt;
            }

            li:first-child {
                margin-top: 1rem;
            }

            li:not(:last-child) {
                margin-bottom: 1rem;
            }

            .highlight {
                /*background: yellow;*/
            }

            body {
                margin: 0;
            }

            .photograph {
                height: 4.5cm;
                width: 3.5cm;
                border: 1px solid black;
                text-align: center;
                color: #bbb;
                font-weight: normal;
            }

            .photograph.right {
                position: relative;
                right: 2.5cm;
                margin: 8px 8px 8px auto;
            }

            .testator-photo-box {
                position: relative;
            }

            .testator-photo-box .label {
                position: absolute;
                top: 0;
            }

            h3 {
                page-break-after: avoid;
            }
        </style>
    </head>

    <body class="print">

        <!-- Add your site or application content here -->

        <!--  Content  -->
        <div class="page">
            <h2>Annexure B</h2>
            <h3 class="text-center">Details of Property / Properties</h3>
            <div class="table-container"></div>
            <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 5 of 6</p>
        </div>
    </body><!doctype html>
<html class="no-js" lang="">

    <head>
        <meta charset="utf-8">
        <meta name="theme-color" content="#fafafa">
        <style>
            @page {
                size: A4;
            }

            @page landscape {
                size: A4 landscape;
            }

            @media screen {
                .page {
                    padding: 2.5cm;
                    margin: 0 auto;
                    border: 1px solid black;
                }
            }

            table {
                border: 1px solid black;
                width: 99%;
                table-layout: fixed;
                border-collapse: collapse;
                page-break-inside: auto
            }

            tr {
                page-break-inside: avoid;
                page-break-after: auto
            }


            td p {
                margin: 0
            }

            td {
                vertical-align: top
            }

            th,
            td {
                padding-left: 1mm;
                font-size: 10pt;
                border: 1px solid black;
                white-space: break-spaces;
                word-break: break-word;
                word-wrap: break-word;
            }

            thead {
                display: table-header-group;
                font-weight: bold;
            }

            tfoot {
                display: table-footer-group
            }

            .page {
                page-break-after: always;
                page-break-before: always;
                page-break-inside: avoid;
                user-select: none;
            }

            .page.landscape {
                page: landscape;
            }

            .page.center {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;
            }

            .page * {
                color: #000;
                font-family: "Calibri", sans-serif;
            }

            .print .thumbnail {
                max-width: 80px;
            }

            .text-center {
                text-align: center;
            }

            h2 {
                text-align: center;
                font-size: 11pt;
            }

            h3,
            h4,
            div {
                font-size: 11pt;
            }

            ol,
            ul {
                counter-reset: list;
            }

            ol:not(.plain)>li,
            ul:not(.plain)>li,
            ol:not(.plain)[inlist=true]>li,
            ul:not(.plain)[inlist=true]>li {
                list-style: none;
            }

            ol>li:before,
            ul>li:before {
                content: "(" counter(list, lower-alpha) ") ";
                counter-increment: list;
            }

            ol[inlist=true]>li:before,
            ul[inlist=true]>li:before {
                content: "(" counter(list, lower-roman) ") ";
                counter-increment: list;
            }

            ol.plain>li:before,
            ul.plain>li:before {
                content: "";
            }

            p,
            li {
                text-align: justify;
                font-size: 11pt;
            }

            li:first-child {
                margin-top: 1rem;
            }

            li:not(:last-child) {
                margin-bottom: 1rem;
            }

            .highlight {
                /*background: yellow;*/
            }

            body {
                margin: 0px;
            }

            .photograph {
                height: 4.5cm;
                width: 3.5cm;
                border: 1px solid black;
                text-align: center;
                color: #bbb;
                font-weight: normal;
            }

            .photograph.right {
                position: relative;
                right: 2.5cm;
                margin: 8px 8px 8px auto;
            }

            .testator-photo-box {
                position: relative;
            }

            .testator-photo-box .label {
                position: absolute;
                top: 0;
            }
        </style>
    </head>

    <body class="print">

        <!-- Add your site or application content here -->

        <!--  Content  -->


    </body><!doctype html>
<html class="no-js" lang="">

    <head>
        <meta charset="utf-8">
        <meta name="theme-color" content="#fafafa">
        <style>
            @page {
                size: A4;
            }

            @page landscape {
                size: A4 landscape;
            }

            @media screen {
                .page {
                    padding: 2.5cm;
                    margin: 0 auto;
                    border: 1px solid black;
                }
            }

            table {
                border: 1px solid black;
                width: 99%;
                table-layout: fixed;
                border-collapse: collapse;
                page-break-inside: auto
            }

            tr {
                page-break-inside: avoid;
                page-break-after: auto
            }


            td p {
                margin: 0
            }

            td {
                vertical-align: top
            }

            th,
            td {
                font-size: 10pt;
                border: 1px solid black;
                white-space: break-spaces;
                word-break: break-word;
                word-wrap: break-word;
            }

            thead {
                display: table-header-group;
                font-weight: bold;
            }

            tfoot {
                display: table-footer-group
            }

            .page {
                min-height: 272mm;
                width: 185mm;
                page-break-after: always;
                page-break-before: always;
                page-break-inside: avoid;
                user-select: none;
            }

            .page.landscape {
                min-height: 185mm;
                width: 272mm;
                page: landscape;
            }

            .page.center {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;
            }

            .page * {
                color: #000;
                font-family: "Calibri", sans-serif;
            }


            .text-center {
                text-align: center;
            }

            h2 {
                text-align: center;
                font-size: 11pt;
            }

            h3,
            h4,
            div {
                font-size: 11pt;
            }

            ol,
            ul {
                counter-reset: list;
            }

            ol:not(.plain)>li,
            ul:not(.plain)>li,
            ol:not(.plain)[inlist=true]>li,
            ul:not(.plain)[inlist=true]>li {
                list-style: none;
            }

            ol>li:before,
            ul>li:before {
                content: "(" counter(list, lower-alpha) ") ";
                counter-increment: list;
            }

            ol[inlist=true]>li:before,
            ul[inlist=true]>li:before {
                content: "(" counter(list, lower-roman) ") ";
                counter-increment: list;
            }

            ol.plain>li:before,
            ul.plain>li:before {
                content: "";
            }

            p,
            li {
                text-align: justify;
                font-size: 11pt;
            }

            li:first-child {
                margin-top: 1rem;
            }

            li:not(:last-child) {
                margin-bottom: 1rem;
            }

            .highlight {
                /*background: yellow;*/
            }

            body {
                margin: 0px;
            }

            .photograph {
                height: 4.5cm;
                width: 3.5cm;
                border: 1px solid black;
                text-align: center;
                color: #bbb;
                font-weight: normal;
            }

            .photograph.right {
                position: relative;
                right: 2.5cm;
                margin: 8px 8px 8px auto;
            }

            .testator-photo-box {
                position: relative;
            }

            .testator-photo-box .label {
                position: absolute;
                top: 0;
            }
        </style>
    </head>

    <body class="print">

        <!-- Add your site or application content here -->

        <!--  Content  -->
        <div class="page">
            <h2>Annexure D</h2>
            <h3 class="text-center">MEDICAL FITNESS CERTIFICATE</h3>
            <p>
                I certify that I have carefully examined Mr Ruturaj Pandit aged about 63 years, residing
                at gfds, .sdfgfd,  Panchale, Nashik - 422103, Maharashtra, India. Based on the examination, I certify that Mr Ruturaj Pandit is in good mental and
                physical
                health and is free from any physical or mental incapacities which may possibly interfere with
                Mr Ruturaj Pandit
                ability to prepare his Will.
            </p>
            <p>
                Place:
                <br />
                <br />
                <br />
                Date:
            </p>
            <p style="margin-top: 5cm;">
                Signature & Seal of the Registered Medical Practitioner
                <br />
                <br />
                <br />
                <br />
                Name of Medical Practitioner
            </p>
            <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 6 of 6</p>
        </div>
    </body>`

// export const dummyHtmlBackup = `<!doctype html>
// <html class="no-js" lang="">

// <head>
//     <meta charset="utf-8">
//     <meta name="theme-color" content="#fafafa">
//     <style>
//         @page {
//             size: A4;
//         }

//         @page landscape {
//             size: A4 landscape;
//         }

//         @media screen {
//             .page {
//                 padding: 2.5cm;
//                 margin: 0 auto;
//                 border: 1px solid black;
//             }
//         }

//         table {
//             border: 1px solid black;
//             width: 99%;
//             table-layout: fixed;
//             border-collapse: collapse;
//             page-break-inside: auto
//         }

//         tr {
//             page-break-inside: avoid;
//             page-break-after: auto
//         }


//         td p {
//             margin: 0
//         }

//         td {
//             vertical-align: top
//         }

//         th,
//         td {
//             padding-left: 1mm;
//             font-size: 10pt;
//             border: 1px solid black;
//             white-space: break-spaces;
//             word-break: break-word;
//             word-wrap: break-word;
//         }

//         thead {
//             display: table-header-group;
//             font-weight: bold;
//         }

//         tfoot {
//             display: table-footer-group
//         }

//         .page {
//             min-height: 272mm;
//             width: 185mm;
//             max-width: 185mm;
//             page-break-after: always;
//             page-break-before: always;
//             page-break-inside: avoid;
//             user-select: none;
//             position: relative;
//         }

//         .page.landscape {
//             min-height: 185mm;
//             width: 272mm;
//             page: landscape;
//         }

//         .page.center {
//             display: flex;
//             align-content: center;
//             align-items: center;
//             justify-content: center;
//             justify-items: center;
//         }

//         .page * {
//             color: #000;
//             font-family: "Calibri", sans-serif;
//         }


//         .text-center {
//             text-align: center;
//         }

//         h1 {
//             text-align: center;
//             font-size: 26pt;
//         }

//         h2 {
//             text-align: center;
//             font-size: 11pt;
//         }

//         h2.large {
//             font-size: 14pt;
//         }

//         h3,
//         h4,
//         div {
//             font-size: 11pt;
//         }

//         ol,
//         ul {
//             counter-reset: list;
//         }

//         ol:not(.plain)>li,
//         ul:not(.plain)>li,
//         ol:not(.plain)[inlist=true]>li,
//         ul:not(.plain)[inlist=true]>li {
//             list-style: none;
//         }

//         ol>li:before,
//         ul>li:before {
//             content: "(" counter(list, lower-alpha) ") ";
//             counter-increment: list;
//         }

//         ol[inlist=true]>li:before,
//         ul[inlist=true]>li:before {
//             content: "(" counter(list, lower-roman) ") ";
//             counter-increment: list;
//         }

//         ol.plain>li:before,
//         ul.plain>li:before {
//             content: "";
//         }

//         p,
//         li {
//             text-align: justify;
//             font-size: 11pt;
//         }

//         li:first-child {
//             margin-top: 1rem;
//         }

//         li:not(:last-child) {
//             margin-bottom: 1rem;
//         }

//         .highlight {
//             /*background: yellow;*/
//         }

//         body {
//             margin: 0;
//         }

//         .photograph {
//             height: 4.5cm;
//             width: 3.5cm;
//             border: 1px solid black;
//             text-align: center;
//             color: #bbb;
//             font-weight: normal;
//         }

//         .photograph.right {
//             position: relative;
//             right: 2.5cm;
//             margin: 8px 8px 8px auto;
//         }

//         .testator-photo-box {
//             position: relative;
//         }

//         .testator-photo-box .label {
//             position: absolute;
//             top: 0;
//         }

//         .page-number {
//             float: right;
//         }

//         .plain li {
//             position: relative;
//             border-bottom: 1px dashed lightgray;
//         }
//     </style>
// </head>

// <body class="print">
//     <div class="page">
//         <div class="center">
//             <h1 class="text-center">WILL AND TESTAMENT <br /> <br />OF<br /> <br /><span>
//                     MR. RUTURAJ  PANDIT</span></h1>
//         </div>
//         <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 1 of 6</p>
//     </div>
//     <div class="page">
//         <h2>Contents</h2>
//         <ol class="plain">
//             <li>PRELIMINARY DECLARATION<span class="page-number"></span></li>
//             <li>BENEFICIARIES UNDER THIS WILL<span class="page-number"></span></li>
//             <li>ASSETS AND DEVICES FOR WHICH THIS WILL IS EXECUTED<span class="page-number"></span></li>
//             <li>DISTRIBUTION OF PROPERTIES<span class="page-number"></span></li>
//             <li>EXECUTOR AND ADMINISTRATIVE POWERS<span class="page-number"></span></li>
//             <li>OPERATION OF THE WILL<span class="page-number"></span></li>
//             <li>MISCELLANEOUS PROVISIONS<span class="page-number"></span></li>

//             <li style="list-style-type: none">Annexure A<span class="page-number"></span>
//             </li>
//             <li style="list-style-type: none">List of Beneficiaries<span class="page-number"></span>
//             </li>
//             <li style="list-style-type: none">Annexure B<span class="page-number"></span>
//             </li>
//              <li style="list-style-type: none"><ol type="A"><li>primary distribution<ol type="a"><li>Details of Movable Properties<ol type="i"></ol></li><li>Details of Residual Assets</ol></li></ol></li>

//             <li style="list-style-type: none">Annexure E<span class="page-number"></span>
//             </li>

//         </ol>

//         <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 2 of 6</p>
//     </div>

//     <!-- Add your site or application content here -->

//     <!--  Content  -->

//     <div class="page">
//         <h2 class="large">WILL AND TESTAMENT</h2>
//         <h3>1. PRELIMINARY DECLARATION</h3>
//         <p>I, Mr Ruturaj Pandit, born on January 10, 1961, holding PAN No. CQNQP0564J,
//             residing
//             at gfds, .sdfgfd,  Panchale, Nashik - 422103, Maharashtra, India, make
//             my Will as under:</p>

//         <p>I am well versed and conversant with English Language.</p>

//         <p>            I state that I have not made any Will in the past. I hereby expressly revoke any writings, notation made
//             by me (if
//             any) and declare that they have no legal implication whatsoever and I further hereby declare the present
//             to be my
//             last Will.
//         </p>

//         <p>I declare that at the time of making this Will, I am in good physical health, sound mind and have a
//             proper
//             understanding of the contents of this Will.</p>

//         <p>My doctor’s certificate is attached to this Will (as Annexure D) certifying that I am in good physical health,
//             sound mind and good understanding at the time of signing this Will.</p>

//         <p>This Will is made by me of my own independent decision and free choice, and I have not been influenced,
//             cajoled,
//             forced or pressurized by anyone in any manner whatsoever to make this Will.</p>

//         <p>I do not wish to cause difficulties to my Beneficiaries by leaving this material world without making a
//             Will and I
//             wish to ensure peaceful and just distribution of my Properties amongst my Beneficiaries. Hence, I have
//             decided to devise and bequeath all my moveable and immoveable properties more particularly described in
//             Annexure A
//             (collectively referred to as the “Properties”) under this Will.</p>

//         <p>All the Properties owned by me are either separate property, self-acquired property or is my share in
//             the ancestral property and is capable of being passed on to my Beneficiaries under this Will. I have
//             specifically included details of the joint holder (if any) for such Properties which are jointly held by
//             me with another person in Annexure B. </p>

//         <p>I have full right, absolute power, and complete authority to bequeath (i) the Properties, or any other
//             property which may be substituted in place of the mentioned Properties (ii) properties
//             which may be acquired or received by me after making this Will.</p>

//         <p>It is my intention to repay the loans and mortgages, if any, created in connection with the Properties
//             in my foreseeable future or lifetime. Should any debt or obligations remain unpaid, the same shall be
//             paid in the manner as mentioned under this Will before distributing my Properties amongst my Beneficiaries.
//         </p>

//         <h3>2. BENEFICIARIES UNDER THIS WILL</h3>
//         <p>I state that the people listed in Annexure A shall be my beneficiaries (hereinafter referred to as my
//             “Beneficiaries” and independently as “Beneficiary”) in respect of the Properties owned or acquired by me on
//             such date this Will becomes effective. No other person shall have any claims in respect of the Properties
//             owned or acquired by me.</p>

//         <h3>3. ASSETS AND DEVICES FOR WHICH THIS WILL IS EXECUTED</h3>

//         <p>As of today, I am owner of the following Properties:</p>
//         <ol type="a">
//         </ol>
//         <p>I have listed the details of the aforesaid Immovable Properties and Movable Properties in Annexure B to
//             this Will,
//             which I would like to consider for this Will.</p>

//         <h3>4. DISTRIBUTION OF PROPERTIES</h3>

//         <p>I bequeath the Properties to my Beneficiaries / Alternative Beneficiaries as under:</p>
//         <ol type="a">
//             <li>It is my wish that after I pass away all my rights, title, and interest in the Properties mentioned
//                 in this Will
//                 to be bequeathed in manner as mentioned in the Annexure B.
//             </li>
//             <li>If there is any enhancement, accretion, depletion, alteration, or modification to any of my Properties
//                 in this Will then the allocation method provided in this clause shall apply as-is to such enhanced,
//                 accreted, altered, or modified assets or property. The Properties mentioned under this Will, or such
//                 properties which are not explicitly mentioned under this Will but are acquired by me / stand in my name
//                 after making this Will, shall be bequeathed to my Beneficiaries / Alternative Beneficiaries in the
//                 manner as mentioned in this Will and no other person will have any right/ title/ interest in such
//                 Properties.
//             </li>

//             </li>


//         </ol>

//         <h3>5. EXECUTOR AND ADMINISTRATIVE POWERS</h3>

//         <ol type="a">
//             <li>
//                 To ensure timely and appropriate execution of my Will, I appoint the people listed in AnnexureC to jointly and
//                 severally to act as the executors of my Will (“Executor/s”). The Executors are ready and willing to
//                 carry
//                 out all responsibilities and duties as the Executors under this Will and accordingly have recorded
//                 their
//                 affirmation by signing on Annexure D.
//             </li>
//             <li>Any provision of the Properties made in favour of the Beneficiaries under this Will shall be subject
//                 to making
//                 provisions for my funeral and last rites and after clearing my liabilities and debts, if any.
//             </li>
//             <li>In addition to the Executor’s power as per law, I confer all such rights on the Executor/s to enable
//                 the
//                 Executor/s to carry on the proper administration and appurtenant duties relating to distribution of
//                 the Properties
//                 and to do all such acts which are in the best interest of my wishes as per this Will.
//             </li>
//             <li>
//                 The Executor/s shall have authority to engage the services of accountants and other advisors as they
//                 may deem
//                 necessary to assists with the execution of this Will and to pay reasonable compensation for their
//                 services from
//                 my estate in consultation with the Beneficiaries.
//             </li>
//             <li>
//                 The Executor/s shall also be entrusted to do the following:
//                 <ol inlist="true" type="i">
//                     <li>To ascertain the Properties after my demise.</li>
//                     <li>
//                         To collect the debts owed to me in collaboration of the wishes of Beneficiaries.
//                     </li>
//                     <li>To apply for a Probate, whenever necessary on behalf of my Beneficiaries.</li>
//                 </ol>
//             </li>
//             <li>After my demise, my Executor/s shall forthwith make a list of my existing assets and liabilities.
//                 Till the
//                 time the Properties mentioned in this Will and acquired by me during my lifetime, are disposed of
//                 after paying all
//                 the liabilities, the Executor/s shall give an annual, true, and correct account of all the assets
//                 and liabilities,
//                 to the Beneficiaries.
//             </li>

//             <li>
//                 Before distributing the Properties to the Beneficiaries, the Executors shall have a right to dispose
//                 / sell of the
//                 Properties as required
//                 <ol inlist="true" type="i">
//                     <li>to pay the outstanding loans including dispose / sell of the specific Properties against
//                         which loans have
//                         been taken by me.
//                     </li>
//                     <li>for any other reason to give effect to this Will, in consultation with the Beneficiaries.
//                     </li>
//                 </ol>
//             </li>
//         </ol>

//         <h3>6. OPERATION OF THE WILL</h3>

//         <ol type="a">
//             <li>This Will shall become operative immediately upon my death and shall have no effect during my
//                 lifetime.</li>
//             <li>On this Will becoming operative, the Beneficiaries are free to enjoy all the rights attached to the
//                 Properties.
//             </li>
//         </ol>
//         </br>
//         <h3>7. MISCELLANEOUS PROVISIONS</h3>

//         <ol type="a">
//             <li>I have not entered into a contract to make “Will” or in a contract to revoke Will. Any similarity of
//                 the
//                 provisions of my Will to the provisions of the Will of my spouse or any other person, if any
//                 executed on the same
//                 or different dates than my Will shall not be construed as evidence of such a contract.
//             </li>
//             <li>Words signifying one gender shall include the others and words signifying the singular shall include
//                 the plural
//                 and vice versa where appropriate.
//             </li>
//             <li>If any of the provisions of this Will are deemed unenforceable, the remaining provisions shall
//                 remain in full
//                 force and effect.
//             </li>
//             <li>
//                 I have kept one copy of this Will with each of my Executor/s.
//             </li>

          
//             </li>
//         </ol>
//         <p>I hope my Beneficiaries accept the Properties bequeathed to them as a small token of love and regard for them
//             and give my best wishes to them for a future prosperous life. In the event any Beneficiary rejects or
//             refuses to accept any of the bequest made under this Will, the Beneficiary identified as an alternative
//             Beneficiary in Annexures below shall be entitled to the identified Property/ies.
//         </p>

//         <p style="margin-top: 1cm; text-align: center; font-weight: bold">[This space is intentionally left blank]
//         </p>
//         <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 3 of 6</p>
//     </div>
// <!doctype html>
// <html class="no-js" lang="">

//     <head>
//         <meta charset="utf-8">
//         <meta name="theme-color" content="#fafafa">
//         <style>
//             @page {
//                 size: A4;
//             }

//             @page landscape {
//                 size: A4 landscape;
//             }

//             @media screen {
//                 .page {
//                     padding: 2.5cm;
//                     margin: 0 auto;
//                     border: 1px solid black;
//                 }
//             }

//             table {
//                 border: 1px solid black;
//                 width: 99%;
//                 table-layout: fixed;
//                 border-collapse: collapse;
//                 page-break-inside: auto
//             }

//             tr {
//                 page-break-inside: avoid;
//                 page-break-after: auto
//             }


//             td p {
//                 margin: 0
//             }

//             td {
//                 vertical-align: top
//             }

//             th,
//             td {
//                 padding-left: 1mm;
//                 font-size: 10pt;
//                 border: 1px solid black;
//                 white-space: break-spaces;
//                 word-break: break-word;
//                 word-wrap: break-word;
//             }

//             thead {
//                 display: table-header-group;
//                 font-weight: bold;
//             }

//             tfoot {
//                 display: table-footer-group
//             }

//             .page {
//                 page-break-after: always;
//                 page-break-before: always;
//                 page-break-inside: avoid;
//                 user-select: none;
//             }

//             .page.landscape {
//                 page: landscape;
//             }

//             .page.center {
//                 display: flex;
//                 align-content: center;
//                 align-items: center;
//                 justify-content: center;
//                 justify-items: center;
//             }

//             .page * {
//                 color: #000;
//                 font-family: "Calibri", sans-serif;
//             }

//             .print .thumbnail {
//                 max-width: 80px;
//             }

//             .text-center {
//                 text-align: center;
//             }

//             h2 {
//                 text-align: center;
//                 font-size: 11pt;
//             }

//             h3,
//             h4,
//             div {
//                 font-size: 11pt;
//             }

//             ol,
//             ul {
//                 counter-reset: list;
//             }

//             ol:not(.plain)>li,
//             ul:not(.plain)>li,
//             ol:not(.plain)[inlist=true]>li,
//             ul:not(.plain)[inlist=true]>li {
//                 list-style: none;
//             }

//             ol>li:before,
//             ul>li:before {
//                 content: "(" counter(list, lower-alpha) ") ";
//                 counter-increment: list;
//             }

//             ol[inlist=true]>li:before,
//             ul[inlist=true]>li:before {
//                 content: "(" counter(list, lower-roman) ") ";
//                 counter-increment: list;
//             }

//             ol.plain>li:before,
//             ul.plain>li:before {
//                 content: "";
//             }

//             p,
//             li {
//                 text-align: justify;
//                 font-size: 11pt;
//             }

//             li:first-child {
//                 margin-top: 1rem;
//             }

//             li:not(:last-child) {
//                 margin-bottom: 1rem;
//             }

//             .highlight {
//                 /*background: yellow;*/
//             }

//             body {
//                 margin: 0;
//             }

//             .photograph {
//                 height: 4.5cm;
//                 width: 3.5cm;
//                 border: 1px solid black;
//                 text-align: center;
//                 color: #bbb;
//                 font-weight: normal;
//             }

//             .photograph.right {
//                 position: relative;
//                 right: 2.5cm;
//                 margin: 8px 8px 8px auto;
//             }

//             .testator-photo-box {
//                 position: relative;
//             }

//             .testator-photo-box .label {
//                 position: absolute;
//                 top: 0;
//             }
//         </style>
//     </head>

//     <body class="print">

//         <!-- Add your site or application content here -->

//         <!--  Content  -->
//         <div class="page">
//             <h2>Annexure A</h2>
//             <h3 class="text-center">Beneficiary / List of Beneficiaries</h3>
//             <div class="table-container"><table>
//             <thead><tr><th align="center" style="border: 1px solid black; padding: 8px;" width="90">Name of the beneficiary</th><th align="center" style="border: 1px solid black; padding: 8px;" width="80">Relationship</th><th align="center" style="border: 1px solid black; padding: 8px;" width="70">Date of Birth</th><th align="center" style="border: 1px solid black; padding: 8px;" width="70">Contact details</th><th align="center" style="border: 1px solid black; padding: 8px;" width="90">PAN</th></tr></thead>
//             <tbody></tbody>
//           </table></div>
//             <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 4 of 6</p>
//         </div>
//     </body><!doctype html>
// <html class="no-js" lang="">

//     <head>
//         <meta charset="utf-8">
//         <meta name="theme-color" content="#fafafa">
//         <style>
//             @page {
//                 size: A4;
//             }

//             @page landscape {
//                 size: A4 landscape;
//             }

//             @media screen {
//                 .page {
//                     padding: 2.5cm;
//                     margin: 0 auto;
//                     border: 1px solid black;
//                 }
//             }

//             table {
//                 border: 1px solid black;
//                 width: 99%;
//                 table-layout: fixed;
//                 border-collapse: collapse;
//                 page-break-inside: auto
//             }

//             tr {
//                 page-break-inside: avoid;
//                 page-break-after: auto
//             }


//             td p {
//                 margin: 0
//             }

//             td {
//                 vertical-align: top
//             }

//             th,
//             td {
//                 padding-left: 1mm;
//                 font-size: 10pt;
//                 border: 1px solid black;
//                 white-space: break-spaces;
//                 word-break: break-word;
//                 word-wrap: break-word;
//             }

//             thead {
//                 display: table-header-group;
//                 font-weight: bold;
//             }

//             tfoot {
//                 display: table-footer-group
//             }

//             .page {
//                 page-break-after: always;
//                 page-break-before: always;
//                 page-break-inside: avoid;
//                 user-select: none;
//             }

//             .page.landscape {
//                 page: landscape;
//             }

//             .page.center {
//                 display: flex;
//                 align-content: center;
//                 align-items: center;
//                 justify-content: center;
//                 justify-items: center;
//             }

//             .page * {
//                 color: #000;
//                 font-family: "Calibri", sans-serif;
//             }

//             .print .thumbnail {
//                 max-width: 80px;
//             }

//             .text-center {
//                 text-align: center;
//             }

//             h2 {
//                 text-align: center;
//                 font-size: 11pt;
//             }

//             h3,
//             h4,
//             div {
//                 font-size: 11pt;
//             }

//             ol,
//             ul {
//                 counter-reset: list;
//             }

//             ol:not(.plain)>li,
//             ul:not(.plain)>li,
//             ol:not(.plain)[inlist=true]>li,
//             ul:not(.plain)[inlist=true]>li {
//                 list-style: none;
//             }

//             ol>li:before,
//             ul>li:before {
//                 content: "(" counter(list, lower-alpha) ") ";
//                 counter-increment: list;
//             }

//             ol[inlist=true]>li:before,
//             ul[inlist=true]>li:before {
//                 content: "(" counter(list, lower-roman) ") ";
//                 counter-increment: list;
//             }

//             ol.plain>li:before,
//             ul.plain>li:before {
//                 content: "";
//             }

//             p,
//             li {
//                 text-align: justify;
//                 font-size: 11pt;
//             }

//             li:first-child {
//                 margin-top: 1rem;
//             }

//             li:not(:last-child) {
//                 margin-bottom: 1rem;
//             }

//             .highlight {
//                 /*background: yellow;*/
//             }

//             body {
//                 margin: 0;
//             }

//             .photograph {
//                 height: 4.5cm;
//                 width: 3.5cm;
//                 border: 1px solid black;
//                 text-align: center;
//                 color: #bbb;
//                 font-weight: normal;
//             }

//             .photograph.right {
//                 position: relative;
//                 right: 2.5cm;
//                 margin: 8px 8px 8px auto;
//             }

//             .testator-photo-box {
//                 position: relative;
//             }

//             .testator-photo-box .label {
//                 position: absolute;
//                 top: 0;
//             }

//             h3 {
//                 page-break-after: avoid;
//             }
//         </style>
//     </head>

//     <body class="print">

//         <!-- Add your site or application content here -->

//         <!--  Content  -->
//         <div class="page">
//             <h2>Annexure B</h2>
//             <h3 class="text-center">Details of Property / Properties</h3>
//             <div class="table-container"></div>
//             <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 5 of 6</p>
//         </div>
//     </body><!doctype html>
// <html class="no-js" lang="">

//     <head>
//         <meta charset="utf-8">
//         <meta name="theme-color" content="#fafafa">
//         <style>
//             @page {
//                 size: A4;
//             }

//             @page landscape {
//                 size: A4 landscape;
//             }

//             @media screen {
//                 .page {
//                     padding: 2.5cm;
//                     margin: 0 auto;
//                     border: 1px solid black;
//                 }
//             }

//             table {
//                 border: 1px solid black;
//                 width: 99%;
//                 table-layout: fixed;
//                 border-collapse: collapse;
//                 page-break-inside: auto
//             }

//             tr {
//                 page-break-inside: avoid;
//                 page-break-after: auto
//             }


//             td p {
//                 margin: 0
//             }

//             td {
//                 vertical-align: top
//             }

//             th,
//             td {
//                 padding-left: 1mm;
//                 font-size: 10pt;
//                 border: 1px solid black;
//                 white-space: break-spaces;
//                 word-break: break-word;
//                 word-wrap: break-word;
//             }

//             thead {
//                 display: table-header-group;
//                 font-weight: bold;
//             }

//             tfoot {
//                 display: table-footer-group
//             }

//             .page {
//                 page-break-after: always;
//                 page-break-before: always;
//                 page-break-inside: avoid;
//                 user-select: none;
//             }

//             .page.landscape {
//                 page: landscape;
//             }

//             .page.center {
//                 display: flex;
//                 align-content: center;
//                 align-items: center;
//                 justify-content: center;
//                 justify-items: center;
//             }

//             .page * {
//                 color: #000;
//                 font-family: "Calibri", sans-serif;
//             }

//             .print .thumbnail {
//                 max-width: 80px;
//             }

//             .text-center {
//                 text-align: center;
//             }

//             h2 {
//                 text-align: center;
//                 font-size: 11pt;
//             }

//             h3,
//             h4,
//             div {
//                 font-size: 11pt;
//             }

//             ol,
//             ul {
//                 counter-reset: list;
//             }

//             ol:not(.plain)>li,
//             ul:not(.plain)>li,
//             ol:not(.plain)[inlist=true]>li,
//             ul:not(.plain)[inlist=true]>li {
//                 list-style: none;
//             }

//             ol>li:before,
//             ul>li:before {
//                 content: "(" counter(list, lower-alpha) ") ";
//                 counter-increment: list;
//             }

//             ol[inlist=true]>li:before,
//             ul[inlist=true]>li:before {
//                 content: "(" counter(list, lower-roman) ") ";
//                 counter-increment: list;
//             }

//             ol.plain>li:before,
//             ul.plain>li:before {
//                 content: "";
//             }

//             p,
//             li {
//                 text-align: justify;
//                 font-size: 11pt;
//             }

//             li:first-child {
//                 margin-top: 1rem;
//             }

//             li:not(:last-child) {
//                 margin-bottom: 1rem;
//             }

//             .highlight {
//                 /*background: yellow;*/
//             }

//             body {
//                 margin: 0px;
//             }

//             .photograph {
//                 height: 4.5cm;
//                 width: 3.5cm;
//                 border: 1px solid black;
//                 text-align: center;
//                 color: #bbb;
//                 font-weight: normal;
//             }

//             .photograph.right {
//                 position: relative;
//                 right: 2.5cm;
//                 margin: 8px 8px 8px auto;
//             }

//             .testator-photo-box {
//                 position: relative;
//             }

//             .testator-photo-box .label {
//                 position: absolute;
//                 top: 0;
//             }
//         </style>
//     </head>

//     <body class="print">

//         <!-- Add your site or application content here -->

//         <!--  Content  -->


//     </body><!doctype html>
// <html class="no-js" lang="">

//     <head>
//         <meta charset="utf-8">
//         <meta name="theme-color" content="#fafafa">
//         <style>
//             @page {
//                 size: A4;
//             }

//             @page landscape {
//                 size: A4 landscape;
//             }

//             @media screen {
//                 .page {
//                     padding: 2.5cm;
//                     margin: 0 auto;
//                     border: 1px solid black;
//                 }
//             }

//             table {
//                 border: 1px solid black;
//                 width: 99%;
//                 table-layout: fixed;
//                 border-collapse: collapse;
//                 page-break-inside: auto
//             }

//             tr {
//                 page-break-inside: avoid;
//                 page-break-after: auto
//             }


//             td p {
//                 margin: 0
//             }

//             td {
//                 vertical-align: top
//             }

//             th,
//             td {
//                 font-size: 10pt;
//                 border: 1px solid black;
//                 white-space: break-spaces;
//                 word-break: break-word;
//                 word-wrap: break-word;
//             }

//             thead {
//                 display: table-header-group;
//                 font-weight: bold;
//             }

//             tfoot {
//                 display: table-footer-group
//             }

//             .page {
//                 min-height: 272mm;
//                 width: 185mm;
//                 page-break-after: always;
//                 page-break-before: always;
//                 page-break-inside: avoid;
//                 user-select: none;
//             }

//             .page.landscape {
//                 min-height: 185mm;
//                 width: 272mm;
//                 page: landscape;
//             }

//             .page.center {
//                 display: flex;
//                 align-content: center;
//                 align-items: center;
//                 justify-content: center;
//                 justify-items: center;
//             }

//             .page * {
//                 color: #000;
//                 font-family: "Calibri", sans-serif;
//             }


//             .text-center {
//                 text-align: center;
//             }

//             h2 {
//                 text-align: center;
//                 font-size: 11pt;
//             }

//             h3,
//             h4,
//             div {
//                 font-size: 11pt;
//             }

//             ol,
//             ul {
//                 counter-reset: list;
//             }

//             ol:not(.plain)>li,
//             ul:not(.plain)>li,
//             ol:not(.plain)[inlist=true]>li,
//             ul:not(.plain)[inlist=true]>li {
//                 list-style: none;
//             }

//             ol>li:before,
//             ul>li:before {
//                 content: "(" counter(list, lower-alpha) ") ";
//                 counter-increment: list;
//             }

//             ol[inlist=true]>li:before,
//             ul[inlist=true]>li:before {
//                 content: "(" counter(list, lower-roman) ") ";
//                 counter-increment: list;
//             }

//             ol.plain>li:before,
//             ul.plain>li:before {
//                 content: "";
//             }

//             p,
//             li {
//                 text-align: justify;
//                 font-size: 11pt;
//             }

//             li:first-child {
//                 margin-top: 1rem;
//             }

//             li:not(:last-child) {
//                 margin-bottom: 1rem;
//             }

//             .highlight {
//                 /*background: yellow;*/
//             }

//             body {
//                 margin: 0px;
//             }

//             .photograph {
//                 height: 4.5cm;
//                 width: 3.5cm;
//                 border: 1px solid black;
//                 text-align: center;
//                 color: #bbb;
//                 font-weight: normal;
//             }

//             .photograph.right {
//                 position: relative;
//                 right: 2.5cm;
//                 margin: 8px 8px 8px auto;
//             }

//             .testator-photo-box {
//                 position: relative;
//             }

//             .testator-photo-box .label {
//                 position: absolute;
//                 top: 0;
//             }
//         </style>
//     </head>

//     <body class="print">

//         <!-- Add your site or application content here -->

//         <!--  Content  -->
//         <div class="page">
//             <h2>Annexure D</h2>
//             <h3 class="text-center">MEDICAL FITNESS CERTIFICATE</h3>
//             <p>
//                 I certify that I have carefully examined Mr Ruturaj Pandit aged about 63 years, residing
//                 at gfds, .sdfgfd,  Panchale, Nashik - 422103, Maharashtra, India. Based on the examination, I certify that Mr Ruturaj Pandit is in good mental and
//                 physical
//                 health and is free from any physical or mental incapacities which may possibly interfere with
//                 Mr Ruturaj Pandit
//                 ability to prepare his Will.
//             </p>
//             <p>
//                 Place:
//                 <br />
//                 <br />
//                 <br />
//                 Date:
//             </p>
//             <p style="margin-top: 5cm;">
//                 Signature & Seal of the Registered Medical Practitioner
//                 <br />
//                 <br />
//                 <br />
//                 <br />
//                 Name of Medical Practitioner
//             </p>
//             <p style="text-align:right;position:absolute;bottom:0;right:2.5cm">Page 6 of 6</p>
//         </div>
//     </body>`