import { Box, Grid, Typography } from '@mui/material';
import Lottie from 'components/DesignSystem/Lottie';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../logo';
 
const PasswordChangeSuccess = () => {
    const navigate = useNavigate();
 
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/login');
        }, 3000);
 
        return () => clearTimeout(timer);
    }, [navigate]);
 
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: '2.8rem',
                    left: '2rem',
                    width: '16rem',
                    height: '8rem',
                }}
            >
                <Logo />
            </Box>
            <Grid
                container
                sx={{
                    margin: 'auto',
                    padding: 10,
                    paddingTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {/* Image Box */}
                <Box
                    sx={{
                        width: '6.977rem',
                        height: '6.976rem',
                        mt: '320px',
                        mb: '25.38px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Lottie name={'Loader'} size={111} />
                </Box>
 
                {/* Text Box */}
                <Box
                    sx={{
                        textAlign: 'center', // Center align text
                    }}
                >
                    <Typography variant="title2" gutterBottom>
                        You have successfully changed your password!
                    </Typography>
                </Box>
            </Grid>
        </>
    );
};
 
export default PasswordChangeSuccess;