import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import Icon from '../Icon';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    padding: 0,
}));

const CheckBox = (props) => (
    <CustomCheckbox
        {...props}
        icon={<Icon name='checkbox-unchecked.svg'/>} // Set custom icon for unchecked state
        checkedIcon={<Icon name='checked.svg'/>}
    />
);

export default CheckBox;
