import { Box, Container, Link, Typography, useTheme } from '@mui/material';
import Lottie from 'components/DesignSystem/Lottie';
import { useNavigate } from 'react-router-dom';

const WillGenerationSuccess = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                minHeight: '100vh',
                textAlign: 'center',
            }}
        >
            <Box sx={{ paddingTop: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                {/* <Icon name='profile-completion-icon.png' size={111} style={{ textAlign: 'center' }} /> */}
                <Lottie name={'Loader'} size={111} style={{ textAlign: 'center' }}/>
                <Typography variant="title2" sx={{ mb: 4, mt: '25px', width: 'max-content' }}>
                    You have successfully generated your Will!
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="bodyText3" color={theme.palette.grey[400]} sx={{ color: '#707070', mb: '12px' }}>
                    We have sent a copy of your Will on your mail.
                </Typography>
                <Typography variant="bodyText3" sx={{ textDecoration: 'underline' }}>
                    <Link onClick={() => console.log("clicked")} color={theme.palette.secondary.main} href="#">
                        Check mail
                    </Link>
                </Typography>
                <Typography variant='bodyText4'  sx={{ textDecoration: 'underline', marginTop: '56px' }}>
                    <Link onClick={() => navigate('/dashboard/default')} color={theme.palette.secondary.main}>
                        ← Go to Dashboard
                    </Link>
                </Typography>
            </Box>
        </Container >
    );
};

export default WillGenerationSuccess;
