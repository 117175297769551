import { Box, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ReactComponent as HelpIcon } from 'assets/help-button.svg';
import HelpSection from 'components/featureComponent/helpResourceSection/HelpResourceSection';
import Logo from 'components/logo/Logo';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { uploadWillApi } from 'services/profileService';
import formValidation from 'utils/helper/formValidations';
import * as Yup from 'yup';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import DateInput from '../../components/DateInput';
import { ContainedButton1 } from '../../components/DesignSystem/Button';
import InputField from '../../components/DesignSystem/InputField';
import FileUpload from '../../components/fileUpload';

const ExistingWillDetailsPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const showSwalAlert = useSwalAlert();
    const handleClose = () => setOpen(false);

    const initialValues = {
        signing_date: null,
        registrar_place: '',
        registrar_number: '',
        serial_number: '',
        file: null
    };

    const validationSchema = Yup.object().shape({
        signing_date: formValidation.dateValidation,
        registrar_place: formValidation.registrarPlaceValidation,
        registrar_number: Yup.string()
            .required('required field'),
        serial_number: Yup.string()
            .required('required field'),
        file: formValidation.fileValidation
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                // Convert file to base64
                const base64File = await convertFileToBase64(values.file);

                // Prepare payload
                const WillData = {
                    file: base64File,
                    signed_at: values.signing_date,
                    registered_with: values.registrar_place,
                    registered_office_number: values.registrar_number,
                    serial_number: values.serial_number
                };

                // Send the data to the API
                await uploadWillApi({ will: WillData });

                // Show SweetAlert2 notification and wait for it to close
                await showSwalAlert({
                    title: "Will uploaded successfully",
                    icon: "success",
                    timer: 1500
                });

                // Reset form and navigate to another page
                navigate('/profileCompletion');
                formik.resetForm();

            } catch (err) {
                // Show SweetAlert2 notification and wait for it to close
                await showSwalAlert({
                    title: "Failde to upload Will",
                    icon: "error",
                    timer: 1500
                });
                console.log("Failed to upload file")
            }
        }
    });

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    return (
        <>
            <Box sx={{
                position: 'absolute', top: "2.8rem", left: "2rem", width: '16rem', height: '8rem',
                '@media (max-width: 600px)': {
                    top: '1rem',
                }, '@media (max-width: 1270px)': {
                    top: '1rem',
                    left: '5rem'
                },
            }}>
                <Logo />
            </Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    sx={{
                        maxWidth: 800,
                        height: '100%',
                        margin: 'auto',
                        padding: 8,
                        paddingTop: 0,
                        paddingLeft: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Box sx={{ maxWidth: 700, marginTop: '8.438rem' }}>
                        <Typography variant="title1" gutterBottom>
                            Existing Will
                        </Typography>

                        <Stack spacing={0.7} mt={2}>
                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[400] }}>
                                Do you have an existing Will, Aditya? If so, please upload it below.
                            </Typography>
                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[400] }}>
                                If not, that is okay. You can proceed to the final step.
                            </Typography>
                        </Stack>

                        <Grid container spacing={3} mt={1.5}>

                            <Grid item xs={12} md={7} sm={12}>
                                <DateInput
                                    name="signing_date"
                                    value={formik.values.signing_date}
                                    label="Signing Date"
                                    onChange={value => formik.setFieldValue('signing_date', value)}
                                    helperText={formik.touched.signing_date && formik.errors.signing_date}
                                    error={formik.touched.signing_date && Boolean(formik.errors.signing_date)}
                                    onBlur={() => formik.setFieldTouched('signing_date', true)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputField
                                    fullWidth
                                    name="registrar_place"
                                    placeholder="Sub-Registrar's Office Place*"
                                    variant="outlined"
                                    value={formik.values.registrar_place}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.registrar_place && Boolean(formik.errors.registrar_place)}
                                    helperText={formik.touched.registrar_place && formik.errors.registrar_place}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputField
                                    fullWidth
                                    name="registrar_number"
                                    placeholder="Sub-Registrar's Office Number*"
                                    type='number'
                                    variant="outlined"
                                    value={formik.values.registrar_number}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.registrar_number && Boolean(formik.errors.registrar_number)}
                                    helperText={formik.touched.registrar_number && formik.errors.registrar_number}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputField
                                    fullWidth
                                    name="serial_number"
                                    placeholder="Serial Number*"
                                    type='number'
                                    variant="outlined"
                                    value={formik.values.serial_number}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.serial_number && Boolean(formik.errors.serial_number)}
                                    helperText={formik.touched.serial_number && formik.errors.serial_number}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FileUpload
                                    name="file"
                                    label="Upload File"
                                    accept="application/pdf"
                                    maxFileSize={10485760} // 10MB
                                    value={formik.values.file}
                                    onChange={(file) => formik.setFieldValue("file", file)}
                                    error={formik.errors.file && formik.touched.file ? formik.errors.file : null}
                                />
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'flex', marginTop: 5 }}>
                            <ContainedButton1
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ width: 138, height: 45, marginRight: 2 }}
                                disabled={!(formik.isValid && formik.dirty)}
                            >
                                <Typography variant='bodyText3'>
                                    Save
                                </Typography>
                            </ContainedButton1>
                            <ContainedButton1
                                color="secondary"
                                sx={{ width: 138, height: 45 }}
                                onClick={() => navigate('/profileCompletion')}
                                disabled={(formik.isValid && formik.dirty)}
                            >
                                <Typography variant='bodyText3'>
                                    Do it Later
                                </Typography>
                            </ContainedButton1>
                        </Box>
                    </Box>
                </Grid>
            </form>
            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: '3.125rem',
                    right: '3.125rem',
                    bgcolor: '#FFF3E0',
                    color: '#FF8C42',
                    '&:hover': {
                        bgcolor: '#FFE0B2',
                    },
                }}
                onClick={() => setOpen(true)}
            >
                <HelpIcon />
            </IconButton>
            <HelpSection
                open={open}
                handleClose={handleClose}
            />
        </>
    );
};

export default ExistingWillDetailsPage;
