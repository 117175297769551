import { Box } from "@mui/material";
import FilterComponent from "components/commonComponents/FilterComponent";
import CreateDistributionModal from "components/featureComponent/distribution/CreateDistributionModal";
import { useState } from "react";
import ScenarioTabs from "./ScenarioTabs";

const SecondaryDistribution = () => {
    const [selectDistributionModalOpen, setSelectDistributionModalOpen] = useState(false);

    const handleAddClick = () => {
        setSelectDistributionModalOpen(true);
    };

    const handleOnClose = () => {
        setSelectDistributionModalOpen(false);
    };

    return (
        <>
            <Box paddingRight='75px'>
                <FilterComponent title="Secondary Distribution" />
                <ScenarioTabs scenarios={scenarios} handleAddClick={handleAddClick}/>
            </Box>
            <CreateDistributionModal open={selectDistributionModalOpen} onClose={handleOnClose} />
        </>
    );
};

export default SecondaryDistribution;

const scenarios = [
    {
        content: [  // Scenario 1 content
            {
                name: 'Property 01',
                description: 'Commercial Property • Office',
                type: 'property',
                beneficiaries: [
                    { name: 'Ananya Chakraborty', percentage: '' },
                    { name: 'Saurav Chakraborty', percentage: '' },
                    { name: 'Meera Chakraborty', percentage: '' },
                    { name: 'Vikram Mehta', percentage: '' },
                    { name: 'Pushkar Sahu', percentage: '' },
                    { name: 'Om Anand', percentage: '' },
                ],
            },
            {
                name: 'Pet',
                description: 'Dog',
                type: 'pet',
                beneficiaries: [
                    { name: 'Ananya Chakraborty', percentage: '' },
                    { name: 'Saurav Chakraborty', percentage: '' },
                    { name: 'Meera Chakraborty', percentage: '' },
                    { name: 'Vikram Mehta', percentage: '' },
                    { name: 'Pushkar Sahu', percentage: '' },
                    { name: 'Om Anand', percentage: '' },
                ],
            },
        ]
    },
    {
        content: [  // Scenario 2 content
            {
                name: 'Property 01',
                description: 'Commercial Property • Shop',
                type: 'property',
                beneficiaries: [
                    { name: 'Ananya Chakraborty', percentage: '' },
                    { name: 'Saurav Chakraborty', percentage: '' },
                    { name: 'Meera Chakraborty', percentage: '' },
                    { name: 'Vikram Mehta', percentage: '' },
                ],
            },
            {
                name: 'Pet',
                description: 'Cat',
                type: 'pet', // or 'pet'
                beneficiaries: [
                    { name: 'Ananya Chakraborty', percentage: '' },
                    { name: 'Saurav Chakraborty', percentage: '' },
                    { name: 'Meera Chakraborty', percentage: '' },
                    { name: 'Vikram Mehta', percentage: '' },
                ],
            },
            {
                name: 'Property 02',
                description: 'Commercial Property • Office',
                type: 'property',
                beneficiaries: [
                    { name: 'Ananya Chakraborty', percentage: '' },
                    { name: 'Saurav Chakraborty', percentage: '' },
                    { name: 'Meera Chakraborty', percentage: '' },
                    { name: 'Vikram Mehta', percentage: '' },
                ],
            },
        ]
    },
];
