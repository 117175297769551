import React, { useState, useEffect, useRef } from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import InputField from './DesignSystem/InputField';

const inputStyle = (sx) => ({
    '& .MuiInputBase-input': {
        textAlign: 'center', // Center text inside the input field
    },
    '& .MuiOutlinedInput-root': {
        ...sx,
    },
});

// Helper functions for validation
const isValidDay = (day) => /^\d{1,2}$/.test(day) && day >= 1 && day <= 31;
const isValidMonth = (month) => /^\d{1,2}$/.test(month) && month >= 1 && month <= 12;
const isValidYear = (year) => /^\d{1,4}$/.test(year);

function DateInput({ value, onChange, onBlur, helperText, error, label, sx = {}, name, ...props }) {
    const dayRef = useRef(null);
    const monthRef = useRef(null);
    const yearRef = useRef(null);
    const theme = useTheme();

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [focusedField, setFocusedField] = useState(null);
    const [validationError, setValidationError] = useState('');

    // Update internal state when the value prop changes (e.g., when formik.resetForm() is called)
    useEffect(() => {
        if (value) {
            const [y, m, d] = value.split('-');
            setDay(d || '');
            setMonth(m || '');
            setYear(y || '');
        } else {
            setDay('');
            setMonth('');
            setYear('');
        }
    }, [value]);

    const notifyChange = (d = day, m = month, y = year) => {
        if (onChange) {
            if (d || m || y) {
                onChange(`${y}-${m}-${d}`);
            } else {
                onChange('');
            }
        }
    };

    const handleDayChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,2}$/.test(value)) {
            setDay(value);
            if (value.length === 2) {
                monthRef.current.focus();
            }
            notifyChange(value, month, year);
        }
    };

    const handleMonthChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,2}$/.test(value)) {
            setMonth(value);
            if (value.length === 2) {
                yearRef.current.focus();
            } else if (value.length === 0) {
                dayRef.current.focus();
            }
            notifyChange(day, value, year);
        }
    };

    const handleYearChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
            setYear(value);
            notifyChange(day, month, value);
        }

        if (value === '') {
            setYear('');
            if (value.length === 0) {
                monthRef.current.focus();
            }
            if (focusedField === 'year') {
                notifyChange(day, month, '');
            }
        }
    };

    const handleBlur = () => {
        if (onBlur) {
            onBlur({
                target: {
                    name,
                },
            });
        }
    };

    useEffect(() => {
        if (!isValidDay(day) || !isValidMonth(month) || !isValidYear(year)) {
            setValidationError('Please enter a valid date.');
        } else {
            setValidationError('');
        }
    }, [day, month, year]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Box mr={2}>
                    <Typography variant="bodyText3">
                        {label}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    <Box>
                        <InputField
                            inputRef={dayRef}
                            placeholder="DD*"
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 2 }}
                            value={day}
                            onChange={handleDayChange}
                            onFocus={() => setFocusedField('day')}
                            onBlur={handleBlur}
                            error={error}
                            sx={inputStyle(sx)}
                        />
                    </Box>
                    <Box>
                        <Typography variant="bodyText3" sx={{ color: theme.palette.grey[300], mx: '4px' }}>-</Typography>
                    </Box>
                    <Box>
                        <InputField
                            inputRef={monthRef}
                            placeholder="MM*"
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 2 }}
                            value={month}
                            onChange={handleMonthChange}
                            onFocus={() => setFocusedField('month')}
                            onBlur={handleBlur}
                            error={error}
                            sx={inputStyle(sx)}
                        />
                    </Box>
                    <Box>
                        <Typography variant="bodyText3" sx={{ color: theme.palette.grey[300], mx: '4px' }}>-</Typography>
                    </Box>
                    <Box>
                        <InputField
                            inputRef={yearRef}
                            placeholder="YYYY*"
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 4 }}
                            value={year}
                            onChange={handleYearChange}
                            onFocus={() => setFocusedField('year')}
                            onBlur={handleBlur}
                            error={error}
                            sx={{
                                ...inputStyle(),
                                '& .MuiOutlinedInput-root': {
                                    padding: '10px 8px', // Add padding specifically to the year input
                                    ...sx,
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            {error && <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', mr: 2 }}>
                <Typography variant="errorText" sx={{ mt: 0.5 }}>
                    {helperText}
                </Typography>
            </Box>}
        </Box>
    );
}

export default DateInput;
