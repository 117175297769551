import {
  Box,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CustomInputField = ({ placeholder, name, value, onChange, sx, ...props }) => {
  return (
    <InputField
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      sx={{
        '& .MuiInputBase-root': {
          height: '30px',
        },
        '& .MuiInputBase-input::placeholder': {
          fontSize: '14px',
        },
        ...sx,
      }}
      {...props}
    />
  );
};

const CustomStepIcon = ({ active, completed, icon }) => {
  const theme = useTheme();
  const color = completed || active ? '#FFF3EA' : theme.palette.grey[200];
  const border = completed || active ? '1px solid #FFA25C' : `1px solid ${theme.palette.grey[100]}`;
  const textColor = completed || active ? '#F47B20' : theme.palette.grey[100];

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 33,
        height: 33,
        borderRadius: '50%',
        border,
        backgroundColor: color,
      }}
    >
      <Typography color={textColor} variant="bodyText2">
        {icon}
      </Typography>
    </Box>
  );
};

const CustomInnerStepIcon = (props) => {
  const { active, completed } = props;

  return (
    <Box sx={{ marginLeft: '5px' }}>
      {completed ? (
        <Icon name="stepper-checked-orange.png" size={15} />
      ) : (
        <Icon name="stepper-unchecked-orange.png" size={15} />
      )}
    </Box>
  );
};

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: '#FFD5B5', // Default color for remaining steps
  },
  '&.Mui-active .MuiStepConnector-line': {
    borderColor: theme.palette.primary.main, // Color for the active step
  },
  '&.Mui-completed .MuiStepConnector-line': {
    borderColor: theme.palette.primary.main, // Color for completed steps
  },
}));

const CustomInnerStepConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderLeft: `1px dashed ${theme.palette.primary.light}`,
  },
  '&.Mui-active .MuiStepConnector-line': {
    borderLeft: `1px dashed ${theme.palette.primary.main}`,
  },
  '&.Mui-completed .MuiStepConnector-line': {
    borderLeft: `1px dashed ${theme.palette.primary.main}`,
  },
}));

const WillGenerationChecklist = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [isPlanPurchased, setIsPlanPurchased] = useState(false);
  const [step1Completed, setStep1Completed] = useState([true, true, false, false]);
  const [willCustody, setWillCustody] = useState({
    executorHasCopy: false,
    copyWithPerson: false,
    copyInBankLocker: false,
    wealthManager: false,
  });

  const [wealthManagers, setWealthManagers] = useState([
    { name: '', clientID: '', email: '', mobile: '', relationshipManager: '' },
  ]);

  const handleStepClick = (step) => {
    if (step === 1 && !isPlanPurchased) {
      return; // Block navigation to step 2 until the plan is purchased
    }
    setActiveStep(step);
  };

  const handlePlanPurchase = () => {
    setIsPlanPurchased(true);
  };
  const handleCheckboxChange = (event) => {
    setWillCustody({ ...willCustody, [event.target.name]: event.target.checked });
    if (event.target.name === 'wealthManager' && !event.target.checked) {
      setWealthManagers([{ name: '', clientID: '', email: '', mobile: '', relationshipManager: '' }]);
    }
  };

  const handleWealthManagerChange = (index, event) => {
    const updatedWealthManagers = [...wealthManagers];
    updatedWealthManagers[index][event.target.name] = event.target.value;
    setWealthManagers(updatedWealthManagers);
  };

  const handleAddWealthManager = () => {
    if (wealthManagers.length < 3) {
      setWealthManagers([
        ...wealthManagers,
        { name: '', clientID: '', email: '', mobile: '', relationshipManager: '' },
      ]);
    }
  };

  const handleDeleteWealthManager = (index) => {
    const updatedWealthManagers = wealthManagers.filter((_, i) => i !== index);
    setWealthManagers(updatedWealthManagers);
  };

  return (
    <Box sx={{ marginLeft: '32px', paddingRight: '80px' }}>
      <Typography variant="heading1" gutterBottom color={theme.palette.secondary.main}>
        Will Generation Checklist
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical" connector={<CustomStepConnector />}>
        <Step onClick={() => handleStepClick(0)}>
          <StepLabel StepIconComponent={CustomStepIcon} sx={{ marginLeft: '-4px' }}>
            <Typography variant="heading2">Checklist</Typography>
          </StepLabel>
          <StepContent sx={{
            borderLeft: activeStep === 0 ? `1px solid #FFD5B5` : theme.palette.primary.main
          }}>
            <Stepper orientation="vertical" connector={<CustomInnerStepConnector />}>
              {['Personal details filled', 'My People added', 'Assets added', 'Distribution completed'].map(
                (label, index) => (
                  <Step key={label} active={step1Completed[index]} completed={step1Completed[index]}>
                    <StepLabel StepIconComponent={CustomInnerStepIcon}>
                      <Typography variant="smallText">{label}</Typography>
                    </StepLabel>
                  </Step>
                )
              )}
            </Stepper>
          </StepContent>
        </Step>

        <Step onClick={() => handleStepClick(1)}>
          <StepLabel StepIconComponent={CustomStepIcon} sx={{ marginLeft: '-4px' }}>
            {isPlanPurchased ? (
              <Typography variant="heading2" sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                Purchase a Plan <Icon name="purchase-plan-icon.png" size={20} />
              </Typography>
            ) : (
              <ContainedButton1
                onClick={handlePlanPurchase}
                sx={{ padding: '14px 20px', textTransform: 'none', maxHeight: '40px' }}
              >
                <Typography variant="bodyText3">Purchase a Plan</Typography>
              </ContainedButton1>
            )}
          </StepLabel>
        </Step>

        <Step onClick={() => handleStepClick(2)}>
          <StepLabel StepIconComponent={CustomStepIcon} sx={{ marginLeft: '-4px' }}>
            <Typography variant="heading2">Will Custody Log</Typography>
          </StepLabel>
          {activeStep === 2 && (
            <StepContent sx={{
              borderLeft: activeStep === 2 ? `1px solid #FFD5B5` : theme.palette.primary.main
            }}>
              {willCustodyOptions.map(({ name, label, textFields }) => (
                <Box key={name}>
                  <Box sx={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                    <Checkbox
                      checked={willCustody[name]}
                      onChange={handleCheckboxChange}
                      name={name}
                      icon={<Icon name="orange-checkbox-default.svg" size={15} />}
                      checkedIcon={<Icon name="orange-checkbox-checked.svg" size={15} />}
                      sx={{ padding: 0 }}
                    />
                    <Typography variant="smallText">{label}</Typography>
                  </Box>
                  <Box>
                    {willCustody[name] && (
                      <Grid container md={10} lg={10} spacing={1} columnSpacing={2}>
                        {textFields.map((field, index) => (
                          <Grid item xs={12} sm={6} key={index}>
                            <CustomInputField fullWidth placeholder={field.label} disabled={field.disabled} />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Box>
                  <Divider sx={{ borderStyle: 'dashed', my: 2 }} variant="fullWidth" orientation="horizontal" />
                </Box>
              ))}
              <Box>
                <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center', mb: '16px' }}>
                  <Checkbox
                    checked={willCustody.wealthManager}
                    onChange={handleCheckboxChange}
                    name={'wealthManager'}
                    icon={<Icon name="orange-checkbox-default.svg" size={15} />}
                    checkedIcon={<Icon name="orange-checkbox-checked.svg" size={15} />}
                    sx={{ padding: 0 }}
                  />
                  <Typography variant="smallText">Add Wealth Manager(s)</Typography>
                  {willCustody.wealthManager && wealthManagers.length < 3 && (
                    <Chip
                      onClick={handleAddWealthManager}
                      label={<Typography variant="suggestiveText">Add +</Typography>}
                      sx={{ backgroundColor: theme.palette.grey[200], height: '19px', p: '8px 0' }}
                    />
                  )}
                </Box>
                {willCustody.wealthManager && (
                  <Box>
                    {wealthManagers.map((manager, index) => (
                      <Box key={index} sx={{ marginBottom: 2 }}>
                        <Grid container md={12} lg={12} spacing={1} columnSpacing={2}>
                          <Grid item md={5} lg={5}>
                            <CustomInputField
                              placeholder="Name"
                              name="name"
                              value={manager.name}
                              onChange={(e) => handleWealthManagerChange(index, e)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={5} lg={5}>
                            <CustomInputField
                              placeholder="Client ID"
                              name="clientID"
                              value={manager.clientID}
                              onChange={(e) => handleWealthManagerChange(index, e)}
                              fullWidth
                            />
                          </Grid>
                          {wealthManagers.length > 1 && (
                            <Grid item md={2} lg={2}>
                              <IconButton
                                onClick={() => handleDeleteWealthManager(index)}
                                disabled={wealthManagers.length === 1}
                              >
                                <Icon name="delete-icon-pink.svg" size={20} />
                              </IconButton>
                            </Grid>
                          )}
                          <Grid item md={5} lg={5}>
                            <CustomInputField
                              placeholder="Email ID"
                              name="email"
                              value={manager.email}
                              onChange={(e) => handleWealthManagerChange(index, e)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={5} lg={5}>
                            <CustomInputField
                              placeholder="Mobile Number"
                              name="mobile"
                              value={manager.mobile}
                              onChange={(e) => handleWealthManagerChange(index, e)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={5} lg={5}>
                            <CustomInputField
                              placeholder="Relationship Manager"
                              name="relationshipManager"
                              value={manager.relationshipManager}
                              onChange={(e) => handleWealthManagerChange(index, e)}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </StepContent>
          )}
        </Step>

        <Step>
          <StepLabel StepIconComponent={CustomStepIcon} sx={{ marginLeft: '-4px' }}>
            <ContainedButton1
              variant="contained"
              color="primary"
              sx={{ height: '40px', padding: '0 8px' }}
              onClick={() => navigate('/willGenerationSuccess')}
            >
              <Typography variant="bodyText3" color={theme.palette.common.white}>
                Generate Will
              </Typography>
            </ContainedButton1>
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};

export default WillGenerationChecklist;

const willCustodyOptions = [
  {
    name: 'executorHasCopy',
    label: 'Each executor has a copy of this Will',
    textFields: [],
  },
  {
    name: 'copyWithPerson',
    label: 'A copy of this Will is also with this person',
    textFields: [{ label: 'Person Name' }, { label: 'Location' }],
  },
  {
    name: 'copyInBankLocker',
    label: 'A copy of this Will is also kept in a bank locker',
    textFields: [
      { label: 'Bank Name' },
      { label: 'Branch Name' },
      { label: 'Bank Locker No.' },
      { label: 'Branch Address' },
    ],
  },
];
