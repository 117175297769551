import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Divider } from '@mui/material';
import ServiceDetailCard from './ServiceDetailCard';
import GwdImageContentCard from './GwdImageContentCard';

export default function ServicesForm() {
  const theme = useTheme();

  return (
    <Box>
      {/* First Box with Typography */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="title1" color={theme.palette.primary.main} gutterBottom>
          Make your Own Will
        </Typography>
      </Box>

      {/* Second Box with Typography */}
      <Box sx={{ mb: '20px', maxWidth: '94%' }}>
        <Typography variant="bodyText4" color={theme.palette.grey[400]}>
          Create a complete digital Will with GetWillDone. Get a one-year subscription with unlimited edits and
          resources to guide you, along with expert help from our lawyers.
        </Typography>
      </Box>

      {/* Image Below Second Box */}
      {/* <Box sx={{ mb: '30px' }}>
        <Box
          component="img"
          src="/assets/Service.svg" // Replace with the correct path to your image
          alt="Service"
          sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Box> */}

        <GwdImageContentCard/>

      <Box sx={{ mb: '20px' }}>
        <Typography variant="title2" color="#1E1C1B">
          Value Added Services
        </Typography>
      </Box>

      <ServiceDetailCard
        title="Call With Legal Expert"
        subtitle="Get all your Will-related questions answered by a lawyer in a 30-minute session."
        price="999"
        buttonContent="+ Added"
      />
      <ServiceDetailCard
        title="Review by Lawyer"
        subtitle="Get your Will reviewed by lawyer and all questions answered by a lawyer in one session."
        price="1999"
        buttonContent="+ Added"
      />
      <Divider sx={{ my: '20px', borderStyle: 'dashed', borderColor: '#C5C5C5'}}/>
      <ServiceDetailCard
        title="Notary at Doorstep"
        subtitle="Get your Will notarised at your doorstep. (Currently available only in Pune.)"
        buttonContent="Get a Quote"
      />
      <ServiceDetailCard
        title="Register your Will"
        subtitle="Get your Will registered with the authorities, easily & hassle-free."
        buttonContent="Get a Quote"
      />
    </Box>
  );
}
