import { Box, Typography } from '@mui/material';
import AssetInfoCard from 'components/commonComponents/cards/AssetsCard';

const AssetInfoCardSection = ({ title, icon: Icon, assets, onEditClick, onDeleteClick }) => {
    return (
        <Box sx={{ marginBottom: '44px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                <Box sx={{ marginRight: '8px', height: '30px' }}>
                    <Icon height='30px' />
                </Box>
                <Typography variant="bodyText2">
                    {title}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                {assets.map((asset, index) => (
                    <AssetInfoCard
                        key={index}
                        asset={asset}
                        onEdit={onEditClick}
                        onDelete={onDeleteClick}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default AssetInfoCardSection;
