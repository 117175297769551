import React from 'react';
import { Box, Typography, Stepper, Step, StepLabel, Button } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from '@emotion/react';
import { ContainedButton1 } from '../../DesignSystem/Button';


const JourneyInfo = () => {
    const theme = useTheme();
    const navigate = useNavigate(); // Initialize useNavigate

    const handleNext = () => {
        navigate('/personalDetails'); // Navigate to the JourneyPage
    };

    const steps = [
        {
            label: 'First, create your profile with important personal details.',
            icon: '/assets/icons/Profile.svg',
        },
        {
            label: 'Next, add the people you love and trust to be an integral part of your Will.',
            icon: '/assets/icons/Beneficiary.svg',
        },
        {
            label: 'After that, add all your assets that you have worked hard for and want to pass on.',
            icon: '/assets/icons/Assets.svg',
        },
        {
            label: 'Lastly, distribute the assets among your beneficiaries, the way you want.',
            icon: '/assets/icons/Distribution.svg',
        },
        {
            label: 'You can Preview your Will at any time, and Generate it when you are ready.',
            icon: '/assets/icons/Alert_Copy.svg',
        },
    ];

    return (
        <Box
            sx={{
                marginLeft:"104px"
            }}
        >
            <Box sx={{ mb: '41px' }}>
                <Typography variant="title1" gutterBottom>
                    Journey
                </Typography>
            </Box>



            <Stepper orientation="vertical" sx={{
                '& .MuiStepConnector-root': {
                    marginLeft: '25px', // Adjust the margin here
                },
                '& .MuiStepConnector-line': {
                    borderColor: theme.palette.primary.dark, // Adjust color if needed
                }
            }}>
                {steps.map((step, index) => (
                    <Step key={index} active>
                        <StepLabel
                            icon={
                                <img
                                    src={step.icon}
                                    alt={`Step ${index + 1}`}
                                    style={{ width: '50px', height: '50px' }}
                                />
                            }
                        >
                            <Typography variant="bodyText3" fontWeight={"400"} sx={{ mb: '63px' }}>{step.label}</Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box sx={{ display: 'flex', justifyContent: 'left', md: 4 }}>
                <ContainedButton1
                    sx={{
                        mt: '154px',
                        minWidth: "125px", // Adjust this value as needed for your design
                    }}
                    onClick={handleNext} // Add onClick handler
                >
                    <Typography variant='bodyText3'>
                        Next
                    </Typography>
                </ContainedButton1>
            </Box>


        </Box>
    );
};

export default JourneyInfo;

