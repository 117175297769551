import PropTypes from 'prop-types';

// material-ui
import { Divider, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { drawerWidth } from 'store/constant';

// project imports
import { ContainedButton1, ContainedButton2 } from 'components/DesignSystem/Button';
import Icon from 'components/DesignSystem/Icon';
import { useDispatch } from 'react-redux';
import { MENU_OPEN } from 'store/actions';
import MenuList from './MenuList';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleServices = () => {
    navigate('/services');
  };

  const goToMyWill = () => {
    dispatch({ type: MENU_OPEN, id: null });
    navigate('/dashboard/myWill');
  };

  const drawer = (
    <>
      <Box>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px',
            marginTop: '30px',
            textAlign: 'center',
            background: theme.palette.secondary.panelColor,
            position: 'relative',
          }}
        >
          <Box>
            <Icon name='gwd-logo-white.png' height={90} width={171} style={{ margin: 'auto'}}/>
          </Box>
          <Box marginY={3}>
            <Typography color={theme.palette.secondary.light} variant="bodyText4" fontWeight={500} onClick={() => navigate('/landingPage')}>
              ← Go to Dashboard
            </Typography>
            <Divider orientation="horizontal" sx={{ marginTop: '24px' }} />
          </Box>
          <MenuList />
          {/* <MenuCard /> */}
          <ContainedButton1 fullWidth onClick={goToMyWill}>
            <Typography variant="bodyText2">My Will</Typography>
          </ContainedButton1>
          {/* <Box sx={{ position: 'absolute', bottom: 50 }}> */}
          <Box sx={{marginTop:'316px' }}>
            <ContainedButton2
              sx={{
                background: theme.palette.secondary.panelColor,
                border: '1px solid #EFF9F915',
                // minWidth: '328px',
                // opacity: '80%',
              }}
              fullWidth
              onClick={handleServices}
            >
              <Typography variant="bodyText2">Services</Typography>
            </ContainedButton2>
          </Box>
        </PerfectScrollbar>
      </Box>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      // sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            // background: theme.palette.secondary.panelColor,
            color: theme.palette.text.primary,
            background: 'unset',
            border: 'none',
            [theme.breakpoints.up('md')]: {
              // top: '88px',
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
