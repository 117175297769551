// ProfileIcon.js
import { Box, Divider, IconButton, Tooltip, useTheme } from '@mui/material';
import { ReactComponent as HelpIcon } from 'assets/help-button.svg';
import { ReactComponent as ProfileIconSelected } from 'assets/profile-icon-selected.svg';
import { ReactComponent as ProfileIconNil } from 'assets/profile-icon.svg';
import HelpSection from 'components/featureComponent/helpResourceSection/HelpResourceSection';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MENU_OPEN } from 'store/actions';


export const ProfileIcon = () => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate(); // Hook for navigation
    const dispatch = useDispatch();

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        dispatch({ type: MENU_OPEN, id: null });
        navigate('/dashboard/profilePage'); // Replace with your profile screen route
    };

    return (
        <Tooltip title="Go to Profile">
            <Box
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
            >
                {isHovered ? (
                    <ProfileIconSelected height={'48px'} style={{ border: '1px solid red', borderRadius: '50px' }} />
                ) : (
                    <ProfileIconNil height={'48px'} />
                )}
            </Box>
        </Tooltip>
    );
};

const PageContainer = ({ children, showHelpIcon = true }) => {
    const theme = useTheme()
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // minHeight: '100vh',
                    minHeight: '100%',
                    position: 'relative',
                    bgcolor: theme.palette.grey[0],
                    // p: 2,
                }}
            >
                {/* Profile section */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <ProfileIcon /> {/* Use the ProfileIcon component */}
                </Box>

                {/* Divider */}
                <Divider sx={{ my: 2 }} />

                {/* Content area */}
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {children}
                </Box>

                {/* Bulb icon */}
                {showHelpIcon && <IconButton
                    sx={{
                        position: 'fixed',
                        bottom: '3.125rem',
                        right: '3.125rem',
                        bgcolor: '#FFF3E0',
                        color: '#FF8C42',
                        '&:hover': {
                            bgcolor: '#FFE0B2',
                        },
                    }}
                    onClick={() => setOpen(true)}
                >
                    <HelpIcon />
                </IconButton>}
            </Box>
            <HelpSection
                open={open}
                handleClose={handleClose}
            />

        </>
    );
};

export default PageContainer;