import { Box, Divider, Link, Typography, useTheme } from '@mui/material';
import axios from "axios";
import InputField from 'components/DesignSystem/InputField';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useStateWithCallback } from "../../../utils/useStateWithCallback";
import { ContainedButton1 } from "../../DesignSystem/Button";
import { OTPField } from "../../OTPField";

const VerifyOtpForm = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const params = useLocation();
    const { email } = params.state;
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [otpError, setOtpError] = useState("");
    // Example password validation regex
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

    const handleNewPasswordChange = (event) => {
        const value = event.target.value;
        setNewPassword(value);

        // Validate new password
        if (!passwordRegex.test(value)) {
            setNewPasswordError('Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.');
        } else {
            setNewPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        setConfirmPassword(value);

        // Validate confirm password
        if (value !== newPassword) {
            setConfirmPasswordError('Password does not match.');
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleVerifyClick = useCallback(() => {
        axios.post("/auth/update-password", {
            email, password: newPassword, otp
        }).then((response) => {
            console.log("responseresponse", response)
            navigate('/password-success'); // Navigate to password success page

        }).catch((error) => {

            setOtpError("Invalid OTP");

        });


    }, [email, newPassword, otp, confirmPassword]);

    const interval = useRef(null);
    const [seconds, setSeconds] = useStateWithCallback(900, (sec) => {
        if (sec <= 0) {
            if (interval.current !== null) clearInterval(interval.current);
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `OTPs have expired. Please click resend to generate new OTPs`,
                showConfirmButton: false,
                timer: 3000
            });
        }
    });

    useEffect(() => {
        interval.current = setInterval(() => setSeconds(prev => prev - 1), 1000);
        return () => clearInterval(interval.current);
    }, [setSeconds]);

    const timer = useMemo(() => {
        const date = new Date(seconds * 1000);
        const isoDate = date.toISOString();
        return isoDate.substring(14, 19);
    }, [seconds]);

    const handleResendOtp = useCallback(() => {
        axios.post("/auth/forgot-password", {
            email // Pass the email to the API
        }).then((res) => {
            // Assuming the API returns the response you need
            if (res.data.sendTo === "email") {
                setSeconds(900); // Reset timer
                Swal.fire({
                    position: "top-center",
                    icon: "success",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `OTP has been resent to your email`,
                    showConfirmButton: false,
                    timer: 3000
                });
            } else if (res.data.sendTo === "phone") {
                setSeconds(900); // Reset timer
                Swal.fire({
                    position: "top-center",
                    icon: "success",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `OTP has been resent to your phone`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        }).catch((e) => {
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: e.response?.data?.message || `Unable to resend OTP! Please try again`,
                showConfirmButton: false,
                timer: 3000
            });
        });
    }, [email]);


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'top',
                justifyContent: 'flex-start',
                height: '60vh',
                bgcolor: 'background.paper',
                marginLeft: "104px",
                width: '402px',
            }}
        >
            <Box sx={{ mb: "5px" }}>
                <Typography variant="title1" sx={{ mb: "5px" }} gutterBottom>
                    OTP Verification
                </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
                <Typography variant="bodyText3" color={theme.palette.grey[400]} sx={{ mb: "30px" }}>
                    An OTP has been sent to {email.replace(/(.{2})(.*)(.@.*)/, "$1****$3")}
                </Typography>
            </Box>

            <Box sx={{ mb: 0 }}>
                <Box sx={{ mb: "11px" }}>
                    <OTPField
                        inputStyle={{
                            fontSize: theme.typography.fontSize,
                            width: theme.spacing(3.5),
                            padding: theme.spacing(1),
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        isInputNum
                        isInputSecure
                    />
                    {otpError && (
                        <Box sx={{textAlign: 'end'}}>
                            <Typography variant="errorText" sx={{ mt: 0.5 }} >
                                {otpError}
                            </Typography>
                        </Box>

                    )}
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    color: seconds <= 0 ? "error.main" : seconds <= 60 ? "warning.main" : theme.palette.secondary.main,
                    mb: '20px'
                }}
            >
                <Box
                    component="img"
                    src="/assets/icons/Stopwatch.svg"
                    alt="Clock Icon"
                    sx={{
                        width: '14.12px',
                        height: '16px',
                        marginRight: theme.spacing(0.5),
                    }}
                />
                {seconds > 0 ? (
                    <Typography
                        variant="suggestiveText"
                        sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            width: '30px',
                            height: '18px',
                        }}
                    >
                        {timer}
                    </Typography>
                ) : (
                    <Typography variant="suggestiveText">
                        OTP Expired! Please click resend to generate new ones
                    </Typography>
                )}
            </Box>

            <Box sx={{ mb: 1 }}>
                <Typography
                    variant="suggestiveText"
                    gutterBottom
                    sx={{
                        color: theme.palette.secondary.main,
                        textDecoration: 'underline',
                        mb: "30px",
                        cursor: 'pointer' 
                    }}
                    onClick={handleResendOtp}
                >
                    Resend OTP
                </Typography>
            </Box>

            <InputField
                placeholder="Create Password"
                sx={{
                    width: '402px',
                    mb: "20px",
                }}
                type="password"
                variant="outlined"
                fullWidth
                value={newPassword}
                onChange={handleNewPasswordChange}
                error={!!newPasswordError}
                helperText={newPasswordError}
            />

            <InputField
                placeholder="Confirm Password"
                sx={{
                    width: '402px',
                    mb: '40px',
                }}
                type="password"
                variant="outlined"
                fullWidth
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
            />

            <ContainedButton1
                sx={{
                    mb: "50px",
                    width: '402px',
                }}
                onClick={handleVerifyClick}
                disabled={!newPassword || !confirmPassword || !!newPasswordError || !!confirmPasswordError}
            >
                <Typography variant='bodyText3'>
                    Verify
                </Typography>
            </ContainedButton1>
            <Divider
                sx={{
                    mb: "15px",
                    width: "402px",
                    borderColor: theme.palette.grey[100]
                }}
            />

            <Typography variant="bodyText4">
                Back to <Link
                    color={theme.palette.secondary.main}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = '/login';
                    }}
                >
                    Log In
                </Link>
            </Typography>
        </Box>
    );
};

export default VerifyOtpForm;
