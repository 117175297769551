import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// Define your custom button styles
const ContainedButton1 = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main, // Contained 1 color
    textTransform:'capitalize',
    color: '#FFFFFF',
    borderRadius: '10px',
    padding: '12px 0',
    boxShadow: 'none', // No shadow by default
    '&:hover': {
        backgroundColor: theme.palette.primary.main, // Hover state same as original for now
        boxShadow: '0px 6px 6px -4px rgba(244, 123, 32, 0.3)', // Shadow on hover
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[100], // Disabled color
        color: theme.palette.grey[0],
    },
}));

const ContainedButton2 = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main, // Contained 2 color
    color: theme.palette.grey[0],
    textTransform:'capitalize',
    borderRadius: '10px',
    boxShadow: 'none', // No shadow by default
    '&:hover': {
        backgroundColor: theme.palette.secondary.main, // Hover state same as original for now
        boxShadow: '0px 6px 6px -4px rgba(244, 123, 32, 0.3)', // Shadow on hover
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[100], // Disabled color
        color: theme.palette.grey[0],
    },
}));

const TextedButton1 = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.lighter, // Texted 1 background color
    color: theme.palette.primary.main,
    textTransform:'capitalize',
    borderRadius: '10px',
    boxShadow: 'none', // No shadow by default
    '&:hover': {
        backgroundColor: '#FFF3EA', // Hover state same as original for now
        boxShadow: '0px 6px 6px -4px rgba(244, 123, 32, 0.2)', // Shadow on hover
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[200],
    },
}));

const TextedButton2 = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.lighter, // Texted 2 background color
    color: theme.palette.secondary.main,
    textTransform:'capitalize',
    borderRadius: '10px',
    boxShadow: 'none', // No shadow by default
    '&:hover': {
        backgroundColor: theme.palette.secondary.lighter, // Hover state same as original for now
        boxShadow: '0px 6px 6px -4px rgba(244, 123, 32, 0.2)', // Shadow on hover
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[0],
        color: theme.palette.grey[100],
    },
}));

const SplitButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main, // Split button color
    color: theme.palette.grey[0],
    textTransform:'capitalize',
    borderRadius: '10px',
    boxShadow: 'none', // No shadow by default
    '&:hover': {
        backgroundColor: theme.palette.primary.main, // Hover state same as original for now
        boxShadow: '0px 6px 6px -4px rgba(244, 123, 32, 0.3)', // Shadow on hover
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[100], // Disabled color
        color: theme.palette.grey[0],
    },
    '& .MuiButton-startIcon': {
        marginRight: theme.spacing(1),
    },
}));

export { ContainedButton1, ContainedButton2, TextedButton1, TextedButton2, SplitButton };
