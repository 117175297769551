import { Navigate, useRoutes } from "react-router-dom";
// layouts
import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import LandingPage from "pages/LandingPage/LandingPage";
import ServicesPage from "pages/ServicePage/ServicesPage";
import VerifyOtpPage from "pages/VerifyOtpPage";
import Loadable from 'ui-component/Loadable';
import AssetPage from "views/AssetPage";
import DistributionPage from "views/distribution";
import MyWill from "views/myWill";
import WillGenerationSuccess from "views/myWill/WillGenerationSuccess";
import ProfilePage from "views/profile";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import LoginPage from "./pages/LoginPage";
import JourneyPage from "./pages/OnboardingFlow/JourneyPage";
import WelcomePage from "./pages/OnboardingFlow/WelcomePage";
import PasswordSuccessPage from "./pages/PasswordSuccessPage";
import AddressDetailsPage from "./pages/ProfileProcessPages/AddressDetailsPage";
import ExistingWillDetailsPage from "./pages/ProfileProcessPages/ExistingWillDetailsPage";
import PersonalDetailsPage from "./pages/ProfileProcessPages/PersonalDetailsPage";
import ProfileCompletionPage from "./pages/ProfileProcessPages/ProfileCompletionPage";
import VerifyRegistrationOtpForm from "./pages/VerifyRegistrationOtpPage";


// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));
const AssetDefault = Loadable(lazy(() => import('views/AssetPage')));
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

export default function Router() {
    const routes = useRoutes([
        {
            path: '/login',
            element: <LoginPage />
        },
        {
            path: '/personalDetails',
            element: <PersonalDetailsPage />
        },
        {
            path: '/addressDetails',
            element: <AddressDetailsPage />
        },
        {
            path: '/existingDetails',
            element: <ExistingWillDetailsPage />
        },
        {
            path: '/profileCompletion',
            element: <ProfileCompletionPage />
        },
        {
            path: '/forgot-password',
            element: <ForgotPasswordPage />
        },
        {
            path: '/verify-otp',
            element: <VerifyOtpPage />
        },
        {
            path: '/verifyRegistrationOtp',
            element: <VerifyRegistrationOtpForm /> 
        },
        {
            path: '/password-success',
            element: <PasswordSuccessPage />
        },
        {
            path: '/welcome',
            element: <WelcomePage />
        },
        {
            path: '/journey',
            element: <JourneyPage />
        },
        {
            path: '/Services',
            element: <ServicesPage />
        },
        {
            path: '/landingPage',
            element: <LandingPage /> 
        },
        {
            path: '/willGenerationSuccess',
            element: <WillGenerationSuccess /> 
        },
        {
            path: '/dashboard',
            element: <MainLayout />,
            children: [
                {
                    path: '/dashboard/default',
                    element: <DashboardDefault />
                },
                {
                    path: '/dashboard/asset',
                    element: <AssetPage />
                },
                {
                    path: '/dashboard/distribution',
                    element: <DistributionPage />
                },
                {
                    path: '/dashboard/profilePage',
                    element: <ProfilePage />
                },
                {
                    path: '/dashboard/myWill',
                    element: <MyWill />
                },
            ]
        },
        {
            path: '*',
            element: <Navigate to="/login" />  // Redirect unknown routes to login
        }
    ]);

    return routes;
}
