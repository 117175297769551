// src/hooks/useSwalAlert.js
import { useCallback } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const useSwalAlert = () => {
    const showSwalAlert = useCallback(({ title, icon = 'success', timer = 3000 }) => {
        return Swal.fire({
            position: 'center',
            icon,
            color: '#fff',
            background: '#00838F',
            toast: true,
            title,
            showConfirmButton: false,
            timer,
        });
    }, []);

    return showSwalAlert;
};

export default useSwalAlert;
