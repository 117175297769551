import React, { useState, useRef } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { LuUpload } from "react-icons/lu";
import { PiCheckCircleFill } from "react-icons/pi";
import Icon from './DesignSystem/Icon';


const FileUpload = ({ value, onChange, error, uploadText = "Upload File", fileTypeText = "Supports PDF up to 10MB", accept, maxFileSize }) => {
    const [fileName, setFileName] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const fileInputRef = useRef(null);
    const theme = useTheme();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (accept && !accept.split(',').includes(file.type)) {
                alert(`File type not allowed. Expected: ${accept}`);
                return;
            }
            if (maxFileSize && file.size > maxFileSize) {
                alert('File size exceeds the limit');
                return;
            }
            setFileName(file.name);
            setIsSelected(true);
            if (onChange) {
                onChange(file);
            }
        } else {
            setFileName('');
            setIsSelected(false);
            if (onChange) {
                onChange(null);
            }
        }
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleDelete = () => {
        setFileName('');
        setIsSelected(false);
        if (onChange) {
            onChange(null);
        }
        // Reset the file input field
        fileInputRef.current.value = '';
    };

    return (
        <Grid container>
            <Grid item sm={12}>
                <Box display="flex" alignItems="center" gap={0.5}>
                    <Box
                        display="flex" alignItems="center" justifyContent="center"
                        sx={{
                            width: '14.75rem',
                            height: '5rem',
                            borderRadius: '0.625rem',
                            backgroundColor: theme.palette.grey[200],
                        }}
                    >
                        <Box
                            sx={{
                                width: '14.25rem',
                                height: '4.5rem',
                                borderRadius: '0.5rem',
                                border: `0.0625rem dashed ${theme.palette.grey[100]}`,
                                backgroundColor: isSelected ? theme.palette.secondary.lighter : theme.palette.grey[0],
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                '&:hover': {
                                    borderColor: theme.palette.primary.main,
                                },
                            }}
                            onClick={handleClick}
                        >
                            {isSelected ? (
                                <Typography variant="bodyText2" style={{ margin: '0.5rem' }} sx={{ color: theme.palette.secondary.darker }}>
                                    Upload Successful
                                </Typography>
                            ) : (
                                <Typography variant="bodyText2" style={{ margin: '1rem' }} sx={{ color: theme.palette.secondary.main }}>
                                    {uploadText}
                                </Typography>
                            )}
                            {isSelected ? (

                                <PiCheckCircleFill
                                    size={'2.2rem'}
                                    color={theme.palette.secondary.main}
                                    style={{ marginRight: '0.5rem' }}
                                />
                            ) : (
                                <LuUpload
                                    size={'1.875rem'}
                                    color={theme.palette.secondary.main}
                                    style={{ margin: '1rem' }}
                                />
                            )}
                        </Box>
                    </Box>

                    {isSelected ? (
                        <Box display="flex" alignItems="center" ml={2} gap={1}>
                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[800] }}>
                                {fileName}
                            </Typography>
                            <Icon name='delete-icon-pink.svg' height='40px' width='40px' onClick={handleDelete} />
                        </Box>
                    ) :
                        <Box display="flex" alignItems="center" ml={2} gap={1}>
                            <Typography variant="bodyText3" sx={{ color: theme.palette.grey[100] }}>
                                File Name
                            </Typography>
                        </Box>
                    }

                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept={accept}
                        onChange={handleFileChange}
                    />
                </Box>
            </Grid>
            <Grid item sm={6} mt={0.5} display={'flex'} justifyContent='center'>
                {!isSelected && <Typography variant="errorText" mr={3}>
                    {error ?? fileTypeText}
                </Typography>}
                {/* {error && (
                    <Grid item xs={12}>
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    </Grid>
                )} */}
            </Grid>
        </Grid >
    );
};

export default FileUpload;