import React from 'react';
import { Box, Typography, Grid, Paper, useTheme } from '@mui/material';

const FAQSection = () => {
  const theme = useTheme();
  const faqs = [
    { question: "What is a Will?", answer: "A Will is a document where you decide who gets your things after your demise. It ensures your assets are distributed amongst your loved ones as per your wishes." },
    { question: "What is a Will?", answer: "A Will is a document where you decide who gets your things after your demise. It ensures your assets are distributed amongst your loved ones as per your wishes." },
    { question: "What is a Will?", answer: "A Will is a document where you decide who gets your things after your demise. It ensures your assets are distributed amongst your loved ones as per your wishes." },
    { question: "What is a Will?", answer: "A Will is a document where you decide who gets your things after your demise. It ensures your assets are distributed amongst your loved ones as per your wishes." },
    { question: "What is a Will?", answer: "A Will is a document where you decide who gets your things after your demise. It ensures your assets are distributed amongst your loved ones as per your wishes." },
    // Add more FAQ objects here
  ];

  return (
    <Grid container spacing={3} sx={{ display: 'flex', flexDirection: 'row', minWidth: 'max-content', borderRadius: '15px' }}>
      {faqs.map((faq, index) => (
        <Grid item key={index}>
          <Paper sx={{ height: '13.563rem', width: '19.875rem', p: '20px', borderRadius: '15px', boxShadow: 'none' }}>
            <Typography variant="bodyText4" color={theme.palette.grey[600]}>{faq.question}</Typography>
            <Grid item sx={12} mt='16px'>
              <Typography variant="bodyText4" color={theme.palette.grey[400]}>{faq.answer}</Typography>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default FAQSection;
