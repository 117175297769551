import { Box, Card, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as DeleteIcon } from 'assets/delete-icon.svg';
import { ReactComponent as EditIcon } from 'assets/edit-icon.svg';

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: '#F7F7F7',
    borderRadius: '8px',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
    padding: '12px',
    // width: '20.188rem',
     width: '30%',
     minHeight: '105px',
    display: 'flex',
    justifyContent: 'space-between',
}));

const AssetInfoCard = ({ asset, onEdit, onDelete }) => {
    const theme = useTheme();
    const { information } = asset;
    const title = Object.entries(information).slice(0, 1)?.[0]?.[1] ?? 'default value';
    const fields = Object.entries(information).slice(1, 3);

    function formatKey(key) {
        // Split the key by underscores, capitalize each part, and join them back together
        return key
            .split('_') // Split by underscore
            .map(part => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part
            .join(' '); // Join with a space
    }

    return (
        <StyledCard>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        mb:'4px'
                    }}
                >
                    <Typography variant='bodyText3' sx={{ width: '100%' }}>
                        {title}
                    </Typography>
                </Box>
                {fields.map(([key, value]) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <Typography key={key} variant="bodyText4" color={theme.palette.grey[400]}>
                            {formatKey(key)}: {value}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Box margin={'-4px'}>
                <Box onClick={onEdit} sx={{ cursor: 'pointer', margin: '-2px 0' }}>
                    <EditIcon sx={{ width: '16px', height: '16px' }} />
                </Box>
                <Box onClick={onDelete} sx={{ cursor: 'pointer' }}>
                    <DeleteIcon sx={{ width: '16px', height: '16px' }} />
                </Box>
            </Box>
        </StyledCard>
    );
};

export default AssetInfoCard;
