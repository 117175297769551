// assets
import { IconDashboard } from '@tabler/icons-react';
// import { ReactComponent as MyPeopleIcon } from 'assets/panel-my-people.svg';
import { ReactComponent as MyPeopleIcon } from  'assets/panel-my-people.svg';
import { ReactComponent as AssetIcon } from  'assets/panel-assets.svg';
import { ReactComponent as DistributionIcon } from  'assets/panel-distribution.svg';

// // constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  // title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'myPeople',
      title: 'My People',
      type: 'item',
      url: '/dashboard/default',
      icon: MyPeopleIcon,
      breadcrumbs: false
    },
    {
      id: 'assets',
      title: 'Assets',
      type: 'item',
      url: '/dashboard/asset',
      icon: AssetIcon,
      breadcrumbs: false
    },
    {
      id: 'distribution',
      title: 'Distributon',
      type: 'item',
      url: '/dashboard/distribution',
      icon: DistributionIcon,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
