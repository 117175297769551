import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';

const IconSkeleton = ({ className, size, height, width, style }) => {
	const imgHeight = height || size;
	const imgWidth = width || size;
	return (
		<Skeleton
			className={className}
			style={{ borderRadius: '8px', ...style }}
			variant='rectangular'
			width={imgWidth}
			height={imgHeight}
		/>
	);
};
IconSkeleton.defaultProps = {
	className: '',
	size: 32,
	width: '',
	height: '',
	style: {},
};
IconSkeleton.propTypes = {
	className: PropTypes.string,
	size: PropTypes.number,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	style: PropTypes.object,
};
export default IconSkeleton;
