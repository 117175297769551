import { Box, Typography, useTheme } from '@mui/material';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import CheckBox from 'components/DesignSystem/CheckBox';
import {
    CustomDialog,
    CustomDialogActions,
    CustomDialogContent,
    CustomDialogTitle,
} from 'components/DesignSystem/CustomDialog';
import { useEffect, useState } from 'react';
import CreateDistributionCard from './cards/CreateDistributionCard';

const dummyData = ['Priya Mehta', 'Rohit Mehta', 'Arjun Mehta', 'Kavya Mehta'];

const CreateDistributionModal = ({ open, onClose }) => {
    const theme = useTheme();
    const [selectedCards, setSelectedCards] = useState([]);
    const [step, setStep] = useState(1);
    const [selectedStep2Cards, setSelectedStep2Cards] = useState([]);

    useEffect(() => {
        if (open) {
            setSelectedCards([]);
            setSelectedStep2Cards([]);
            setStep(1);
        }
    }, [open]);


    const handleSelect = (name) => {
        setSelectedCards((prev) => (prev.includes(name) ? prev.filter((item) => item !== name) : [...prev, name]));
    };

    const handleSelectStep2 = (name) => {
        setSelectedStep2Cards((prev) =>
            prev.includes(name) ? prev.filter((item) => item !== name) : [...prev, name]
        );
    };

    const handleNext = () => {
        if (step === 1) {
            setStep(2);
        }
    };

    const beneficiariesForDistribution = dummyData.filter(name => !selectedCards.includes(name));

    return (
        <CustomDialog open={open} onClose={onClose} maxWidth={false}>
            <CustomDialogTitle onClose={onClose}>
                <Typography variant="heading1" color={theme.palette.secondary.main}>
                    Create new distribution scenario
                </Typography>
                {step === 1 && (
                    <Typography maxWidth="628px" variant="bodyText4" color={theme.palette.grey[400]}>
                        If you survive any beneficiaries, you can give their share to someone else. You can add scenarios for
                        precise distribution.
                    </Typography>
                )}
            </CustomDialogTitle>
            <CustomDialogContent>
                {step === 1 && (
                    <>
                        <Box sx={{ display: 'flex', gap: '15px', margin: '25px 0 15px 0' }}>
                            <Typography variant="bodyText2">In case of demise(s) of</Typography>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '24px' }}>
                                {dummyData.map((name) => (
                                    <CreateDistributionCard
                                        key={name}
                                        name={name}
                                        isSelected={selectedCards.includes(name)}
                                        onSelect={() => handleSelect(name)}
                                    />
                                ))}
                            </Box>
                            <Typography variant="bodyText4" fontStyle="italic" color={theme.palette.grey[400]}>
                                You can select multiple people.
                            </Typography>
                        </Box>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Box sx={{ display: 'flex', gap: '15px', margin: '25px 0 15px 0' }}>
                            <Typography variant="bodyText2">Asset(s) will be distributed to</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', mt: '15px', mb: '12px', alignItems: 'center' }}>
                            <CheckBox label="Select All to Distribute Equally" sx={{ Padding: '0px !important' }} />
                            <Typography variant='bodyText4'>Select All to Distribute Equally</Typography>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '15px' }}>
                                {beneficiariesForDistribution.map((name) => (
                                    <CreateDistributionCard
                                        key={name}
                                        name={name}
                                        isSelected={selectedStep2Cards.includes(name)}
                                        onSelect={() => handleSelectStep2(name)}
                                    />
                                ))}
                            </Box>
                            <Typography variant="bodyText4" color={theme.palette.grey[400]}>
                                or <a href="#" style={{ color: theme.palette.secondary.main }}>click here</a> to add new beneficiary
                            </Typography>
                        </Box>
                    </>
                )}
            </CustomDialogContent>
            <CustomDialogActions>
                <ContainedButton1
                    onClick={handleNext}
                    sx={{ minHeight: '45px', minWidth: '122px' }}
                    disabled={(step === 1 && selectedCards.length === 0) || (step === 2 && selectedStep2Cards.length === 0)}
                >
                    <Typography variant="bodyText3">Next</Typography>
                </ContainedButton1>
            </CustomDialogActions>
        </CustomDialog>
    );
};

export default CreateDistributionModal;
