import { Box, Button, Typography, useTheme } from '@mui/material';

const ServiceDetailCard = ({ title = '', subtitle = '', price = '', buttonContent = '' }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                mb: 3,
                // width: '718px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px',
                backgroundColor: theme.palette.secondary.lighter,
                border: `1px solid ${theme.palette.secondary.lighter}`,
                borderRadius: '10px',
            }}
        >
            {/* Left Content */}
            <Box>
                <Typography variant="bodyText3" color="#1E1C1B">
                    {/* Call With Legal Expert */}
                    {title}
                </Typography>
                <Typography variant="suggestiveText" color={theme.palette.grey[400]}>
                    <Box component="span" sx={{ display: 'block' }}>
                        {/* Get all your Will-related questions answered by a lawyer in a 30-minute session. */}
                        {subtitle}
                    </Box>
                </Typography>
            </Box>

            {/* Right Content (Price and Add Button) */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {price && (
                    <Typography variant="bodyText4" color="#1E1C1B" sx={{ mr: 2 }}>
                        ₹{price}
                    </Typography>
                )}
                <Button
                    variant="outlined"
                    sx={{
                        borderColor: theme.palette.secondary.main,
                        padding: '6px 16px',
                        borderRadius: '10px',
                        textTransform: 'none',
                    }}
                >
                    <Typography variant="bodyText3" sx={{ color: theme.palette.secondary.main }}>
                        {buttonContent}
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default ServiceDetailCard;
