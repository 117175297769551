import { CurrencyRupeeOutlined } from '@mui/icons-material';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import { Box, Container, Divider, Grid, Stack, Typography, useTheme, } from '@mui/material';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { ContainedButton1, ContainedButton2 } from 'components/DesignSystem/Button';
import Icon from 'components/DesignSystem/Icon';
import Header from 'components/featureComponent/landingPage/Header';
import YouTubeVideoSection from 'components/featureComponent/landingPage/YouTubeVideoSection';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FAQSection from '../../components/featureComponent/landingPage/FAQSection';
import StatsCard from '../../components/featureComponent/landingPage/StatsCard';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 'calc(50% - 2px)', // Adjust top to center the connector,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            border: '1px solid #FFA25C',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            border: '1px solid #FFA25C',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 1,
        border: '1px dashed #F47B20', // Dashed border for unselected connectors
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));


const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: ownerState.active || ownerState.completed
        ? '#F47B20'
        : '#FFF3EA',
    border: ownerState.active || ownerState.completed
        ? '1px solid #FFA25C'
        : '1px solid #F47B20',
    zIndex: 1,
    color: ownerState.active || ownerState.completed ? '#fff' : '#F47B20',
    width: 98, // Set width to 98 pixels
    height: 97, // Set height to 97 pixels
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: ownerState.active ? '0 4px 10px 0 rgba(0,0,0,.25)' : 'none',
    ...(ownerState.completed && {
        backgroundImage: 'linear-gradient( 95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));



function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <Icon name='landing-page-profile-icon.svg'  height={98} width={97} />,
        2:<Icon name='landing-page-people-icon.svg'  height={97} width={97} />,
        // 3: <CurrencyRupeeOutlined />,
        // 4: <FeedOutlinedIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};


const steps = [
    'Basic Details',
    'Contact Details',
    'Financial Details',
    'Other Details',
];





const LandingPage = () => {
    const theme = useTheme();
    const [progress, setProgress] = useState(50);
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate('/dashboard/default');
        // setProgress(100);
    };

    return (
        <>
            <Container maxWidth='false' disableGutters sx={{ maxWidth: '120rem', padding: '0 31px', }}>
                <Header />
                {/* First Box */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        bgcolor: theme.palette.grey[200],
                        borderRadius: '30px',
                        minHeight: 460,
                        width: '100%',
                        marginTop: '29px',
                        padding: '25px'
                    }}
                >
                    <Grid container height='100%'>
                        <Grid item container spacing={1} xs={12} md={8} sx={{ padding: 2 }}>
                            <Grid xs={12}>
                                <Stack>
                                    <Typography variant="largeTitle"
                                        color='#1E1C1B'>
                                        Welcome, Aditya
                                    </Typography>
                                    <Typography variant="heading2" sx={{ mb: 2, color: '#707070' }}>
                                        You're just two steps away from creating your Will !
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={8} lg={8} xl={8}>
                                <Stepper activeStep={currentStep} alternativeLabel connector={<ColorlibConnector />}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon} />
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>

                            <Grid item xs={12} md={8} mt={2}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: '#FFFFFF', p: 2, borderRadius: '0.938rem' }}>
                                    <Typography variant="bodyText3">
                                        You have finished {progress}% of your Will
                                    </Typography>
                                    <ContainedButton1 sx={{ padding: '10px 33px' }} variant="contained" onClick={handleEdit} >
                                        <Typography variant="bodyText3"> Edit Your Will</Typography>
                                    </ContainedButton1>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item container spacing={1.4} xs={12} md={4}>
                            <Grid item xs={12}>
                                <StatsCard title="People Added" value="07" />
                            </Grid>
                            <Grid item xs={12}>
                                <StatsCard title="Assets Added" value="18" />
                            </Grid>
                            <Grid item xs={12}>
                                <StatsCard title="Assets Distributed" value="12" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                {/* Second Box */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'space-between',
                        bgcolor: theme.palette.secondary.lighter,
                        borderRadius: '30px',
                        minHeight: 460,
                        width: '100%',
                        marginTop: '20px',
                        padding: '25px'
                    }}
                >

                    <Grid item container xs={12} md={5.8} xl={6.8}>
                        <Grid item xs={12} md={12} xl={12}>
                            <Stack>
                                <Typography variant="largeTitle"
                                    color='#00838F'>
                                    Your Legacy,
                                </Typography>
                                <Typography variant="largeTitle" mt={-3}
                                    color='#00838F'>
                                    Our Commitment
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ContainedButton2 sx={{ padding: '10px 16px', mt: '22px' }} variant="contained" onClick={handleEdit} >
                                <Typography variant="bodyText3"> Explore Services</Typography>
                            </ContainedButton2>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={7.2} xl={6.2}>
                        <img
                            src={'assets/landing-page-family-img.png'}
                            alt="Description"
                            style={{
                                width: '795px',
                                height: '425px',
                                borderRadius: '15px',
                            }}
                        />
                    </Grid>

                </Box>

            </Container >
            {/* Third Box */}
            < Grid container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'space-between',
                    bgcolor: 'lightBlue',
                    height: 712,
                    width: '100%',
                    marginTop: '25px',
                    padding: '50px',      // Padding for top, right, bottom, left
                    paddingRight: '0px',
                    backgroundImage: 'url(/assets/landing-page-box-bg-img.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }
                }
            >
                <Grid item xs={12} md={12} xl={12}>
                    <Typography variant="title1" color={theme.palette.secondary.main}> Resource Hub</Typography>
                </Grid>
                <Grid item container xs={12} md={12} xl={12} >
                    <Grid item xs={2} md={2} xl={2} mt={2}>
                        <Grid item>
                            <Typography variant="title2" color={theme.palette.grey[600]}>Videos</Typography>
                        </Grid>
                        <ContainedButton2 sx={{ padding: '8px 20px', mt: '25px' }} variant="contained" onClick={handleEdit} >
                            <Typography variant="bodyText3">Watch All →</Typography>
                        </ContainedButton2>
                    </Grid>
                    <Grid item xs={10} md={10} xl={10} sx={{ overflowX: 'auto', '::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none' }}>
                        <YouTubeVideoSection />
                    </Grid>
                </Grid>

                <Divider
                    sx={{
                        width: '96%',       // Width of the Divider
                        height: '0px',         // Height of the Divider (effectively just a line)
                        border: '1px solid #C5C5C5'  // Border style
                    }}
                />
                <Grid item container xs={12} md={12} xl={12} >
                    <Grid item xs={2} md={2} xl={2} mt={2}>
                        <Grid item>
                            <Typography variant="title2" color={theme.palette.grey[600]}>FAQ’s</Typography>
                        </Grid>
                        <ContainedButton2 sx={{ padding: '8px 20px', mt: '25px' }} variant="contained" onClick={handleEdit} >
                            <Typography variant="bodyText3">Find More →</Typography>
                        </ContainedButton2>
                    </Grid>
                    <Grid item xs={10} md={10} xl={10} sx={{ overflowX: 'auto', '::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none' }}>
                        <FAQSection />
                    </Grid>
                </Grid>

            </Grid >
        </>
    );
};

export default LandingPage;
