import React from 'react';
import { Card, Typography, Box, useTheme } from '@mui/material';

const DistributionCardModal = ({ label, icon: IconComponent, isSelected, onSelect }) => {
    const theme = useTheme();
    return (
        <Card
            onClick={onSelect}
            sx={{
                width: '167px',
                borderRadius: 3,
                paddingLeft: '12px',
                border: `1px solid ${isSelected ? theme.palette.primary.dark : 'transparent'}`,
                backgroundColor: isSelected ? theme.palette.primary.lighter : theme.palette.grey[200],
                cursor: 'pointer',
                boxShadow: isSelected ? `0px 10px 10px -8px ${theme.palette.primary.dark}4D` : 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                    boxShadow: `0px 10px 10px -8px ${theme.palette.common.black}9D`,
                },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 1 }}>
                <Box
                    component="span"
                    sx={{
                        color: isSelected ? theme.palette.primary.main : theme.palette.common.black,
                        marginRight: 1,
                    }}
                >
                    {/* Render SVG Icon */}
                    <IconComponent style={{ fontSize: 30, color: isSelected ? theme.palette.primary.main : theme.palette.common.black }} />
                </Box>
                <Typography
                    variant="bodyText3"
                    sx={{ color: isSelected ? theme.palette.primary.main : theme.palette.common.black, lineHeight: '18.9px !important' }}
                >
                    {label}
                </Typography>
            </Box>
        </Card>
    );
};

export default DistributionCardModal;
