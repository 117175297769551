import { Skeleton } from '@mui/material';
import { noop } from 'lodash-es';
import LottieLib from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

/**
 * Prop Description:
 * name => **required** name of the animation data json file
 * size => this will maintain the 1:1 for the image ie. (height and width will be equals to size).
 * height, width => use this prop, if the the ratio of height and width is not 1:1.
 * style => custom html style object which will be applied on lottieContainer div.
 * className => custom className (this will also apply on the container div).
 * onClick => click handler
 * numberOfLoops => use prop to control number of animation loops
 */
const Lottie = ({
	size,
	width,
	height,
	style,
	className,
	name,
	onClick,
	dataAid,
	numberOfLoops,
	...restProps
}) => {
	const lottieWidth = width || size;
	const lottieHeight = height || size;
	const [animationData, setAnimationData] = useState(null);
	const [loop, setLoop] = useState(true);
	const lottieRef = useRef(null);

	useEffect(() => {
		const loadAnimationData = async () => {
			try {
				const module = await import(`assets/lotties/${name}.json`);
				setAnimationData(module.default);
			} catch (err) {
				console.error('Error loading animation data:', err);
				setAnimationData(null);
			}
		};

		loadAnimationData();
	}, [name]);

	useEffect(() => {
		if (!numberOfLoops || !animationData || !lottieRef.current) {
			return;
		};

		const animationDuration = lottieRef.current.getDuration();
		const animationTimeout = setTimeout(() => {
			setLoop(false);
		}, animationDuration * (numberOfLoops - 1) * 1000);

		// eslint-disable-next-line consistent-return
		return () => {
			clearTimeout(animationTimeout);
		};
	}, [numberOfLoops, animationData]);

	if (!animationData) {
		return (
			<Skeleton
				className={className}
				style={{ borderRadius: '8px', ...style }}
				variant="rectangular"
				width={lottieWidth}
				height={lottieHeight}
			/>
		);
	}

	return (
		<LottieLib
			autoPlay
			loop={loop}
			lottieRef={lottieRef}
			animationData={animationData}
			className={className}
			style={{
				height: lottieHeight,
				width: lottieWidth,
				...style,
			}}
			onClick={onClick}
			data-aid={`iv_${dataAid}`}
			{...restProps}
		/>
	);
};

Lottie.defaultProps = {
	size: 250,
	width: 0,
	height: 0,
	style: {},
	className: '',
	onClick: noop,
	numberOfLoops: 0,
	dataAid: '',
};

Lottie.propTypes = {
	size: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	style: PropTypes.object,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	numberOfLoops: PropTypes.number,
	dataAid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Lottie;
