import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
// import GoogleIcon from '@mui/icons-material/Google';
import { Box, Container, Divider, FormControlLabel, Link, Stack, Typography, useTheme } from '@mui/material';
// components
import Swal from 'sweetalert2';
import { ReactComponent as AppleIcon } from '../../../assets/apple-icon.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/google-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/linkedin-icon.svg';
import { ContainedButton1 } from '../../../components/DesignSystem/Button';
import CheckBox from '../../../components/DesignSystem/CheckBox';
import IconButton from '../../../components/DesignSystem/IconButton';
import InputField from '../../../components/DesignSystem/InputField';
import ReCaptcha from '../../../components/featureComponent/loginFlow/ReCaptcha';
import { useStateWithCallback } from '../../../utils/useStateWithCallback';

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();
    const [userType, setUserType] = useState('customer');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [eotp, setEOTP] = useState('');
    const [accepted, setAccepted] = useState(false);
    const [step, setStep] = useState(0);
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState('');
    const [partnerCode, setPartnerCode] = useState(null);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [isRecaptchaExpired, setIsRecaptchaExpired] = useState(false);

    const theme = useTheme();
    const location = useLocation();

    const handleTermsChange = (event) => {
        setIsTermsChecked(event.target.checked);
    };

    const [emailError, setEmailError] = useState('');

    // Basic email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [passwordError, setPasswordError] = useState('');

    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    // Password validation regex for at least 8 characters, including at least one uppercase letter, one lowercase letter, and one number
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

    const handleLogin = useCallback(() => {
        axios
            .post(step === 0 ? '/auth/login' : '/auth/verify', {
                phone,
                password,
                email,
                otp,
                userType,
            })
            .then(() => {
                navigate('/welcome', { replace: true }); // Navigate to the "Welcome" page
            })
            .catch((e) => {
                console.log('login message', e);
                if (step === 0) {
                    if (e.response.data === 'Unauthorized') {
                        // toastr.error("Login Failed!. Please check the credentials and try again");
                        Swal.fire({
                            position: 'top-center',
                            icon: 'error',
                            color: '#fff',
                            background: '#00838F',
                            toast: true,
                            title: `Sign in Failed!`,
                            text: `Please check the credentials and try again`,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        // toastr.error(e.response.data.message);
                        Swal.fire({
                            position: 'top-center',
                            icon: 'error',
                            color: '#fff',
                            background: '#00838F',
                            toast: true,
                            title: e.response.data.message,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                } else {
                    // toastr.error("Login Failed!. Please check the OTP and try again");
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: `Sign in Failed!. Please check the OTP and try again`,
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            });
    }, [step, phone, password, email, otp, userType]);

    const handleSignUp = () => {
        console.log('in handle signup');
        axios
            .post('/auth/pre-register', { email })
            .then((response) => {
                console.log('API Response:', response);
                navigate('/verifyRegistrationOtp', { state: { email, password, initialTimer: 900 } });
                setStep(1);

                // Ensure any previous interval is cleared
                if (interval.ref !== null) clearInterval(interval.ref);

                setSeconds(900);

                interval.ref = setInterval(() => {
                    setSeconds((s) => s - 1);
                }, 1000);
            })
            .catch((e) => {
                console.log('Response', e);
                if (e?.response?.status === 400) {
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: `Email already in use. Please proceed to login!`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: `Unable to generate OTP! Please check the email`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            });
    };

    const gotoLogin = () => {
        setStep(0);
    };

    const gotoSignup = () => {
        setStep(1);
    };
    const handleForgotPasswordClick = (event) => {
        console.log('in handle Forgot PasswordClick');
        navigate('/forgot-password'); // Navigate to the Forgot Password page
    };

    const interval = useRef(null);
    const [seconds, setSeconds] = useStateWithCallback(900, (sec) => {
        if (sec <= 0) {
            if (interval.ref !== null) clearInterval(interval.ref);
            //   toastr.error('OTPs have expired. Please click resend to generate new OTPs');
            Swal.fire({
                position: 'top-center',
                icon: 'error',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `OTPs have expired. Please click resend to generate new OTPs`,
                showConfirmButton: false,
                timer: 3000,
            });
        }
    });

    const timer = useMemo(() => {
        const date = new Date(seconds * 1000);
        const isoDate = date.toISOString();
        return isoDate.substring(14, 19);
    }, [seconds]);

    const generateOTP = useCallback(() => {
        axios
            .post('/auth/otp', {
                phone,
            })
            .then(() => {
                setStep(1);
                if (interval.ref !== null) clearInterval(interval.ref);
                setSeconds(900);
                interval.ref = setInterval(() => {
                    setSeconds((s) => s - 1);
                }, 1000);
            })
            .catch((e) => {
                console.log('error', e);
                if (e.response.data.type === 'INTERNATIONAL_NUMBER') {
                    // toastr.error("Please login using email and password");
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: `Please login using email and password`,
                        showConfirmButton: false,
                        timer: 3000,
                    });
                } else {
                    // toastr.error("Login Failed!. Unable to send OTP! Please check the mobile number");
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: `Sign in Failed!. Unable to send OTP! Please check the mobile number`,
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            });
    }, [phone]);

    useEffect(() => {
        console.log('location', window.location.search);
        const params = new URLSearchParams(window.location.search);
        console.log('errorparams', params);
        const error = params.get('error');
        if (error) {
            // toastr.error(`${error}`);
            Swal.fire({
                position: 'top-center',
                icon: 'error',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `${error}`,
                showConfirmButton: false,
                timer: 3000,
            });
            // window.history.replaceState({}, document.title, `${window.location.pathname}`);
            navigate({ search: '' });
        }
    }, [location.search]);

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.code?.toLowerCase() === 'enter' && !e.repeat && accepted) {
                e.preventDefault();
                if (step === 0) {
                    if (isRecaptchaVerified && (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? '') && password.length > 7)) {
                        handleSignUp();
                    };
                }
            }
        };

        window.addEventListener('keyup', handleEnter);
        return () => {
            window.removeEventListener('keyup', handleEnter);
        };
    }, [email, password, eotp, step, accepted, seconds]);

    const handleChange = (event) => {
        setUserType(event.target.value);
    };

    const onCaptchaChange = (value) => {
        setIsRecaptchaVerified(value);
    }

    const onCaptchaExpired = () => {
        setIsRecaptchaExpired(true); // Set expired state when captcha is expired
    };

    const checkIsDisabled = () => {
        if ((!isRecaptchaVerified || !isTermsChecked || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "") || password !== confirmPassword || password.length < 8)) {
            return true;
        }

        return false;
    }

    return [
        <Box>
            <Stack direction="column">
                <Typography variant="title1" gutterBottom>
                    Welcome!
                </Typography>
                <Typography
                    variant="bodyText3"
                    color={theme.palette.grey[400]}
                    gutterBottom
                    marginBottom={'16px'}
                    marginTop={'8px'}
                >
                    Please log in to access your Will
                </Typography>
            </Stack>
            <form>
                <InputField
                    placeholder="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email} // Bind the current state value
                    onChange={(e) => {
                        const value = e.target.value;
                        setEmail(value);

                        // Validate email
                        if (!emailRegex.test(value)) {
                            setEmailError('Invalid email ID');
                        } else {
                            setEmailError('');
                        }
                    }}
                    error={!!emailError} // Set error state
                    helperText={emailError} // Show validation message
                />

                <InputField
                    placeholder="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    sx={{ marginBottom: '24px' }}
                    value={password} // Bind the current state value
                    onChange={(e) => {
                        const value = e.target.value;
                        setPassword(value);

                        // Validate password
                        if (!passwordRegex.test(value)) {
                            setPasswordError('Invalid email or password.');
                        } else {
                            setPasswordError('');
                        }
                    }}
                    error={!!passwordError} // Set error state
                    helperText={passwordError} // Show validation message
                />
                <Typography variant="bodyText4">
                    <Link href="#" color={theme.palette.secondary.main} onClick={handleForgotPasswordClick}>
                        Forgot password?
                    </Link>
                </Typography>

                <ReCaptcha
                    sitekey="6LfYHrYoAAAAABadQVuKliDO4491RrZ7ThRkEAL6"
                    onChange={onCaptchaChange}
                    onExpired={onCaptchaExpired}
                    style={{ margin: '30px 0 12px 0' }}
                />

                <ContainedButton1
                    // variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: '12px' }}
                    onClick={handleLogin}
                    disabled={!isRecaptchaVerified}
                >
                    <Typography variant="bodyText4">Login</Typography>
                </ContainedButton1>
            </form>
            <Box marginTop="20px">
                <Typography variant="bodyText3">Or, log in using</Typography>
                <Stack direction="row" spacing={2} mt={2}>
                    <a href={`${process.env.REACT_APP_API_URL}/auth/google`}>
                        <IconButton
                            icon={<GoogleIcon />}
                            sx={{ padding: '11px', background: theme.palette.grey[200], borderRadius: '10px' }}
                        />
                    </a>

                    <a href={`${process.env.REACT_APP_API_URL}/auth/linkedin`}>
                        <IconButton
                            icon={<LinkedinIcon />}
                            sx={{ padding: '11px', background: theme.palette.grey[200], borderRadius: '10px' }}
                        />
                    </a>

                    <a href={`${process.env.REACT_APP_API_URL}/auth/apple`}>
                        <IconButton
                            icon={<AppleIcon />}
                            sx={{ padding: '11px', background: theme.palette.grey[200], borderRadius: '10px' }}
                        />
                    </a>
                </Stack>
                <Divider sx={{ mt: 4, mb: 2 }} />
                <Typography variant="bodyText3">
                    Don’t have an account?{' '}
                    <Link onClick={gotoSignup} color={theme.palette.secondary.main} href="#">
                        Sign up here
                    </Link>
                </Typography>
            </Box>
        </Box>,
        <Box>
            <Stack direction="column">
                <Typography variant="title1" gutterBottom>
                    Get started
                </Typography>
                <Typography
                    variant="bodyText3"
                    color={theme.palette.grey[400]}
                    gutterBottom
                    marginBottom={'16px'}
                    marginTop={'8px'}
                >
                    Please create your account
                </Typography>
            </Stack>
            <form>
                <InputField
                    placeholder="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email} // Bind the current state value
                    onChange={(e) => {
                        const value = e.target.value;
                        setEmail(value);

                        // Validate email
                        if (!emailRegex.test(value)) {
                            setEmailError('Invalid email ID');
                        } else {
                            setEmailError('');
                        }
                    }}
                    error={!!emailError} // Set error state
                    helperText={emailError} // Show validation message
                />
                <InputField
                    placeholder="Create Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => {
                        const value = e.target.value;
                        setPassword(value);

                        // Validate password
                        if (!passwordRegex.test(value)) {
                            setPasswordError(
                                'Password should be at least 8 characters long & must contain at least 1 uppercase, 1 lowercase, 1 number & 1 special case character.'
                            );
                        } else {
                            setPasswordError('');
                        }
                    }}
                    error={!!passwordError}
                    helperText={passwordError}
                />
                <InputField
                    placeholder="Confirm Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={confirmPassword}
                    onChange={(e) => {
                        const value = e.target.value;
                        setConfirmPassword(value);

                        // Validate confirm password to check if it matches the create password
                        if (value !== password) {
                            setConfirmPasswordError('Password does not match');
                        } else {
                            setConfirmPasswordError('');
                        }
                    }}
                    error={!!confirmPasswordError} // Set error state
                    helperText={confirmPasswordError} // Show validation message
                />
                {/* <FormControlLabel
                control={<Checkbox name="robotCheck" />}
                label="I'm not a robot"
            /> */}

                <ReCaptcha
                    sitekey="6LfYHrYoAAAAABadQVuKliDO4491RrZ7ThRkEAL6"
                    onChange={onCaptchaChange}
                    onExpired={onCaptchaExpired}
                    style={{ margin: '30px 0 12px 0' }}
                />

                <FormControlLabel
                    control={<CheckBox name="termsCheck" checked={isTermsChecked} onChange={handleTermsChange} />}
                    label={
                        <Typography variant="bodyText4">
                            I have read and accept the{' '}
                            <Link href="#" color={theme.palette.secondary.main}>
                                Terms and Conditions
                            </Link>
                        </Typography>
                    }
                />
                {/* <CheckBox/> */}
                <ContainedButton1
                    // variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: '12px' }}
                    onClick={handleSignUp}
                    disabled={checkIsDisabled()}
                >
                    <Typography variant="bodyText4">Sign Up</Typography>
                </ContainedButton1>
            </form>
            <Box marginTop="20px">
                <Typography variant="bodyText3">Or, sign up using</Typography>
                <Stack direction="row" spacing={2} mt={2}>
                    <a
                        href={`${process.env.REACT_APP_API_URL}/auth/google${partnerCode !== null ? `?partner_code=${partnerCode}` : ''
                            }`}
                    >
                        <IconButton
                            icon={<GoogleIcon />}
                            sx={{ padding: '11px', background: theme.palette.grey[200], borderRadius: '10px' }}
                        />
                    </a>

                    <a
                        href={`${process.env.REACT_APP_API_URL}/auth/linkedin${partnerCode !== null ? `?partner_code=${partnerCode}` : ''
                            }`}
                    >
                        <IconButton
                            icon={<LinkedinIcon />}
                            sx={{ padding: '11px', background: theme.palette.grey[200], borderRadius: '10px' }}
                        />
                    </a>

                    <a
                        href={`${process.env.REACT_APP_API_URL}/auth/apple${partnerCode !== null ? `?partner_code=${partnerCode}` : ''
                            }`}
                    >
                        <IconButton
                            icon={<AppleIcon />}
                            sx={{ padding: '11px', background: theme.palette.grey[200], borderRadius: '10px' }}
                        />
                    </a>
                </Stack>
                <Divider sx={{ mt: 4, mb: 2 }} />
                <Typography variant="bodyText3">
                    Already have an account?{' '}
                    <Link onClick={gotoLogin} color={theme.palette.secondary.main} href="#">
                        Log in here
                    </Link>
                </Typography>
            </Box>
        </Box>,
    ][step];
}
